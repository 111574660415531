import { useEffect, useState } from "react";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { useGetChats } from "src/scenes/Chat/hooks/index";

export const useActiveChats = (matrixClient) => {
    const [selectedChatListTypeOrLeadershipUuid, setSelectedChatListTypeOrLeadershipUuid] = useState(null);
    const [selectedChatListTypeOrLeadershipUuidLabel, setSelectedChatListTypeOrLeadershipUuidLabel] = useState(null);
    const [businessBackgroundColor, setBusinessBackgroundColor] = useState(null);
    const [leadershipRoles, setLeadershipRoles] = useState(null);
    const [lookingForLeadershipToChat, setLookingForLeadershipToChat] = useState(null);
    
    const {
        populateActiveChatListDetails,
        isFetchingActiveChats,
        //TODO: enable this --> updateActiveList,
        activeChatListDetails,
        setChatList,
        chatList, //TODO: move to new *useGetChatList hook
        setActiveChatListDetails
    } = useGetChats(matrixClient, setLeadershipRoles);

    useEffect(() => {
        populateActiveChatListDetails(false);
    }, []);

    useEffect(() => {
        if (!activeChatListDetails || activeChatListDetails.length === 0) {
            return;
        }

        //onLoad
        const initPreselect = async () => {
            await selectIndexOfChatListDetails(activeChatListDetails[0].label);
        };

        if (!selectedChatListTypeOrLeadershipUuid) {
            initPreselect();
            return;
        }

        let existingChats = [];
        activeChatListDetails.forEach(chat => {
            existingChats = [...existingChats, ...chat.data.chats];
        });
        setChatList(existingChats);
    }, [activeChatListDetails]);

    useEffect(() => { 
        if (!selectedChatListTypeOrLeadershipUuid) {
            return;
        }
        selectIndexOfChatListDetails(selectedChatListTypeOrLeadershipUuid);

    }, [selectedChatListTypeOrLeadershipUuid]);

    const selectIndexOfChatListDetails = async (activeChatListDetailsLabel) => {
        const foundChat = activeChatListDetails.find(chat => chat.label === activeChatListDetailsLabel);
        if (!foundChat) {
            console.warn("Chat List details not found", activeChatListDetails, activeChatListDetailsLabel);
            return;
        }

        setChatList(foundChat.data.chats);
        handleChatListSelectLabelAndUuid(foundChat.label);
        setBusinessBackgroundColor(foundChat.data.businessBackgroundColor);
    };

    /**
     * @var {string} selectedValue - can be employees, clients, or available leadershipRoleUuid
     */
    const handleChatListSelectLabelAndUuid = (selectedValue) => {
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedValue);
        
        if (isNonLeadership) {
            setSelectedChatListTypeOrLeadershipUuid(selectedValue);
            setSelectedChatListTypeOrLeadershipUuidLabel(selectedValue);
            return;
        }

        if (Array.isArray(leadershipRoles)) {
            const selectedLeadershipRoleItem = leadershipRoles.find((item) => item.uuid === selectedValue);
            if (selectedLeadershipRoleItem) {
                const selectedLeadershipRoleName = selectedLeadershipRoleItem.name;
                setSelectedChatListTypeOrLeadershipUuid(selectedValue);
                setSelectedChatListTypeOrLeadershipUuidLabel(selectedLeadershipRoleName);
                return;
            }
        }

        console.warn("Unknown Active Chat List has been selected: ", selectedValue, leadershipRoles);
    };

    const handleChatListSelectChange = (selectedValue) => {
        handleChatListSelectLabelAndUuid(selectedValue);
    };

    const removeRoomInActiveChatListDetails = async (roomId) => {
        const getUpdatedActiveChatlistDetailsForRemovingARoom = (prevActiveChatListDetails) => {
            const activeCopy = prevActiveChatListDetails ?? [];
            
            return activeCopy.map(item => {
                const updatedChats = item.data.chats.filter(chat => chat.matrixRoomId !== roomId);
                
                // Important: Create a *new* object with the updated chats array
                return {
                    ...item, // Copy other properties of the item
                    data: {
                        ...item.data, // Copy other properties of the data object
                        chats: updatedChats // Replace the chats array with the filtered one
                    }
                };
            });
        };

        setActiveChatListDetails((prevActiveChatListDetails) => {
            return getUpdatedActiveChatlistDetailsForRemovingARoom(prevActiveChatListDetails);
        });
    };

    return {
        populateActiveChatListDetails,
        selectedChatListTypeOrLeadershipUuid,
        selectedChatListTypeOrLeadershipUuidLabel,
        activeChatListDetails,
        isFetchingActiveChats,
        businessBackgroundColor,
        handleChatListSelectChange,
        chatList,
        leadershipRoles,
        lookingForLeadershipToChat,
        setLookingForLeadershipToChat,
        removeRoomInActiveChatListDetails
    };
};