import React, { useContext, useEffect, useState } from "react";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { UserContext } from "src/scenes/App/UserContext";
import { Select, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { FlexContainer, Text } from "src/components";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import TypeSelection from "./TypeSelection";
import { UserKeys } from "src/constants/userDetails";
import { MESSAGE_EMPLOYEES, MESSAGE_CLIENTS, isChatActionAllowed } from "src/constants/permissions";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
            background: "none"
        },
    }
}));

const selectStyle = {
    height: "2.5625rem",
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "0.875rem",
    background: "#FFFFFF",
    border: "1px solid #000000",
    color: "#4B5155",
    paddingLeft: "0.5rem",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&:hover": {
        backgroundColor: "transparent",
    },
};

const LeadershipRoleToUseSelection = ({ changeSelectedType, selectedUserListType, checkLeadershipRoleVisibility, ...props }) => {
    const userContext = useContext(UserContext);
    const userDetailsLeadershipRoles = userContext[UserKeys.LEADERSHIP_ROLES];
    const { 
        useActiveChats: ctxUseActiveChats
    } = useContext(ChatContext);
    
    const { 
        selectedChatListTypeOrLeadershipUuid
    } = ctxUseActiveChats;

    const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
    
    const findLeadershipRoleByUuidInUserDetails = (leadershipRoleUuid) => {
        const lrItem = userDetailsLeadershipRoles && userDetailsLeadershipRoles.find((item) => item.uuid === leadershipRoleUuid);
        if (!lrItem) {
            throw new Error("No leadership role found from the selected start chat.");
        }

        return lrItem;
    };

    const findLeadershipRoleByNameInUserDetails = (leadershipRoleName) => {
        const lrItem = userDetailsLeadershipRoles && userDetailsLeadershipRoles.find((item) => item.name === leadershipRoleName);
        if (!lrItem) {
            throw new Error("No leadership role found from the selected start chat.");
        }

        return lrItem;
    };

    const getSelectedLabelForStartChatAsLabel = () => {
        if (isNonLeadership) {
            return userContext.isClient === selectedChatListTypeOrLeadershipUuid ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES;
        }

        const leadershipRole = findLeadershipRoleByUuidInUserDetails(selectedChatListTypeOrLeadershipUuid);
        return leadershipRole.name;
    };
    
    const [selectedLabel, setSelectedLabel] = useState("");
    const [startChatAs, setStartChatAs] = useState({
        canSeeEmployees: false,
        canSeeClients: false,
    });

    const classes = useStyles();

    const isListTypeLeadershipRoleUuid = listType => {
        return listType && listType !== USER_TYPE_EMPLOYEES && listType !== USER_TYPE_CLIENTS;
    };

    useEffect(() => {
        const startChatAsLabel = getSelectedLabelForStartChatAsLabel();
        selectHowYouAppearInChat(startChatAsLabel);
    }, []);

    const handleChangeChatAs = (value) => {
        selectHowYouAppearInChat(value);
    };

    const selectHowYouAppearInChat = async (startChatAsLabel) => {
        setSelectedLabel(startChatAsLabel);
        if (!isListTypeLeadershipRoleUuid(startChatAsLabel)) {
            const permissions = userContext[UserKeys.PERMISSIONS];
            const isEmployeeListAllowable = isChatActionAllowed(MESSAGE_EMPLOYEES, permissions);
            const isClientListAllowable = isChatActionAllowed(MESSAGE_CLIENTS, permissions);

            props.setFormData({ ...props.formData, leadershipRoleUuid: undefined });

            setStartChatAs({
                canSeeEmployees: isEmployeeListAllowable,
                canSeeClients: isClientListAllowable,
            });
        } else {
            const lrItem = findLeadershipRoleByNameInUserDetails(startChatAsLabel);

            const isEmployeeListAllowable = checkLeadershipRoleVisibility(lrItem.uuid, USER_TYPE_EMPLOYEES, userDetailsLeadershipRoles);
            const isClientListAllowable = checkLeadershipRoleVisibility(lrItem.uuid, USER_TYPE_CLIENTS, userDetailsLeadershipRoles);
            
            props.setFormData({ ...props.formData, leadershipRoleUuid: lrItem.uuid });
            setStartChatAs({
                canSeeEmployees: isEmployeeListAllowable,
                canSeeClients: isClientListAllowable,
            });
        }
    };

    const renderSelectionItems = () => {
        if (userDetailsLeadershipRoles.length > 0) {
            return userDetailsLeadershipRoles.map(role =>
                <MenuItem key={role.uuid} value={role.name}
                >
                    Start chat as &quot;{ role.name }&quot;
                </MenuItem>
            );
        }
        return "";
    };

    const chatAsEmployee = !userContext.isClient;
    const chatAsClient = userContext.isClient; 

    return (
        <>
            <FlexContainer>
                <Text color="#4B5155" size="1rem" align="left" margin="0 0 0.8125rem 0" weight="700">
                    Select how you will appear in this chat:
                </Text>
                <Select onChange={(e) => handleChangeChatAs(e.target.value) } value={selectedLabel} variant="outlined" style={selectStyle} classes={{ outlined: classes.outlined }} placeholder={`Start chat as &quot;${selectedLabel}&quot;`}>
                    { chatAsEmployee && <MenuItem value={USER_TYPE_EMPLOYEES}>Start chat as &quot;Employee&quot;</MenuItem> }
                    { chatAsClient && <MenuItem value={USER_TYPE_CLIENTS}>Start chat as &quot;Client&quot;</MenuItem> }
                    { renderSelectionItems() }
                </Select>
            </FlexContainer>
            <TypeSelection 
                showLabel={true} 
                fontSize="0.875rem" fontWeight="500"
                color="#000"
                border="1px solid #000"
                canSeeEmployeeList={startChatAs.canSeeEmployees}
                canSeeClientList={startChatAs.canSeeClients}
                defaultValue={selectedUserListType}
                changeSelectedType={changeSelectedType}
            />
        </>
    );
};

export default LeadershipRoleToUseSelection;