import React from "react";
import { withRouter } from "react-router-dom";
import { PageContainer, FlexContainer, PageHeadingLabel, ExplainParagraphLabel, LottieAnimation } from "src/components";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";
import styled from "styled-components";
const HeaderTwoColumns = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 94.5rem;
    margin: 0 0 1.4rem 0 !important;
`;

import getDigitalMemberCard from "./hooks/getDigitalMemberCard";
import useRenderDigitalMemberCard from "./hooks/useRenderDigitalMemberCard";
import DigitalCompanyCardContext from "./DigitalCompanyCardContext";
import DisplayCompanyCards from "./components/DisplayCompanyCards";
import CustomiseUserTypeCard from "./components/CustomiseUserTypeCard";
import { DIGITAL_MEMBER_CARD_PAGES } from "./utils/enum";

const DigitalMemberCard = ({ user }) => {
    const { 
        renderPage,
        setRenderPage
    } = useRenderDigitalMemberCard();
    const { /* digitalMembershipCards, */ isFetchingDigitalMemberCard } = getDigitalMemberCard(user.token);

    const PageHeader = () => (
        <>
            <PageHeadingLabel>
                Digital Membership Cards
            </PageHeadingLabel>

            <HeaderTwoColumns className="row">
                <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                Create unique digital member cards for your employees and clients, displaying your company 
                logo, background colour, and their personal details. These cards serve as digital IDs, perfect for 
                event access or offering discounts, and can be added to Apple or Google Wallets for easy access.
                </ExplainParagraphLabel>
            </HeaderTwoColumns>
        </>
    );

    const PageBodyContainer = ({ children, isFetchingDigitalMemberCard }) => {
        return (
            <PageContainer>
                <PageHeader />

                <FlexContainer
                    justifyContent={ isFetchingDigitalMemberCard && "center" }
                    alignItems="center"
                    color="#fff"
                    style={{
                        maxWidth: "94.5rem",
                        minHeight: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    { isFetchingDigitalMemberCard && <LoadingIndicator /> }
                    { !isFetchingDigitalMemberCard && children }
                </FlexContainer>
            </PageContainer>
        );
    };

    return (
        <PageBodyContainer isFetchingDigitalMemberCard={isFetchingDigitalMemberCard}>
            <DigitalCompanyCardContext.Provider value={{ 
                renderDisplayCards: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD),
                renderCustomiseEmployee: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_EMPLOYEE_CARD),
                renderCustomiseClient: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_CLIENT_CARD)
            }}>
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD && <DisplayCompanyCards /> }
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_EMPLOYEE_CARD && <CustomiseUserTypeCard isClient={false} /> }
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_CLIENT_CARD && <CustomiseUserTypeCard isClient={true} /> }
            </DigitalCompanyCardContext.Provider>
        </PageBodyContainer>
    );
};

const LoadingIndicator = () => {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
};

export default withRouter(DigitalMemberCard);