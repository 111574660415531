import React, { useEffect, useContext, useState } from "react";
import { API_URL, ENVIRONMENT } from "src/scenes/App";
import axios from "axios";
import * as Airwallex from "airwallex-payment-elements";
import ImgAirwallexDesktopLogo from "../../../img/airwallex@2x.png";

import {
    Toast, LoadingIndicator, FlexContainer, ErrorLabel, Text
} from "src/components";

import { SubscriptionContext } from "../SubscriptionContext";
import { UserKeys } from "src/constants/userDetails";
import { UserContext } from "src/scenes/App/UserContext";
import { appFeatureLevels } from "src/constants/appFeatureLevels";
import { updateFeatureLevel } from "src/utils/appFeatures";

export const AirwallexProduct = {
    SAVE_NEW_PAYMENT_METHOD: "save-new-payment-method",
    INITIAL: "initial-sub",
    ADD_USERS: "add-users",
    RENEWAL: "renewal",
    CLIENT_LICENSES: "client-licenses",
    CLIENT_RENEWAL: "client-renewal"
};

const formContainerId = "cardContainer";

/**
 * DOCUMENTATION
 *
 * Drop-in element guide:
 * https://www.airwallex.com/docs/online-payments__drop-in-element
 *
 * React demo app:
 * https://github.com/airwallex/airwallex-payment-demo/tree/master/integrations/react
 *
 * Test cards:
 * https://www.airwallex.com/docs/online-payments__test-card-numbers
 */
function AirwallexForm(props) {
    /** see docs in /src/scenes/Settings/components/PaymentModal.js */
    const userContext = useContext(UserContext);
    const subscriptionContext = useContext(SubscriptionContext);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [missionAccomplished, setMissionAccomplished] = useState(false);
    const [queuedAirwallexSuccessEvent, setQueuedAirwallexSuccessEvent] = useState(null);

    useEffect(
        () => { prepareForPayment(); },
        [ /* No deps; only run once */ ]
    );

    /**
     * Invoke a callback upon seeing a state update of mission accomplished = true
     */
    useEffect(() => {
        if (missionAccomplished) {
            if (props.productType !== AirwallexProduct.CLIENT_RENEWAL) {
                /** null check, in case this form is loaded into both Modals outside the component tree */
                if (userContext) {
                    userContext.appFns.updateUserContextProperties({
                        [UserKeys.COMPANY_NAME]: props.companyName,
                        [UserKeys.COMPANY_ABN]: props.abn
                    });
                }
            }
            props.nextClick();
        }
    }, [missionAccomplished]);

    /**
     * Handle Airwallex success events queued up. Clear the queued event when it is processed.
     * useEffect is necessary here, and so is checking the "loading" field. Together they prevent this from running
     * twice when the Airwallex onSuccess event fires twice.
     */
    useEffect(() => {
        if (queuedAirwallexSuccessEvent === null || loading) {
            return;
        }

        /** @type {EventDetail} - event emitted by Airwallex; not sure how to hint the type correctly without TS */
        const successEvent = queuedAirwallexSuccessEvent;
        setQueuedAirwallexSuccessEvent(null);

        if (props.productType === AirwallexProduct.CLIENT_RENEWAL) {
            const intentId = successEvent.intent.id;
            proceedWithRenewalPayment(intentId);
        } else {
            const intentId = successEvent.intent.id;
            const consentId = successEvent.intent.payment_consent_id;
            const paymentMethodId = successEvent.intent.latest_payment_attempt.payment_method.id;
            proceedWithConsentedPayment(intentId, consentId, paymentMethodId);
        }
    }, [queuedAirwallexSuccessEvent]);

    /**
     * Steps before a payment can be requested:
     * - Create the PaymentIntent and Customer via our server
     * - Load the Airwallex dropIn element
     */
    const prepareForPayment = async () => {
        setLoading(true);
        try {
            const newIntent = await createPaymentIntent();
            await initialisePaymentForm(
                newIntent.intentId ? newIntent.intentId : null,
                newIntent.clientSecret,
                newIntent.customerId ? newIntent.customerId : null,
                newIntent.currency);
        } catch (e) {
            console.log(e);
            Toast.error("The payment platform could not be initialised");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Steps for processing a payment once we have the customer's payment details
     * - Confirm the payment on our server (requires payment method and consent)
     * @param {string|undefined} intentId
     * @param {string} consentId
     * @param {string} paymentMethodId
     */
    const proceedWithConsentedPayment = async (intentId, consentId, paymentMethodId) => {
        if (!consentId || !paymentMethodId) {
            Toast.error("Cannot proceed - payment information is missing");
            return;
        }
        setLoading(true);
        setErrorMessage("");
        try {
            const finalisePaymentResponse = await finalisePayment(intentId, consentId, paymentMethodId);
            
            if (productType === AirwallexProduct.INITIAL) {
                await updateCompanyWebsite(finalisePaymentResponse);
            } else {
                setMissionAccomplished(true);
            }
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    /**
     * For renewal payment, we don't need to collect payment method or consent.
     * We'll just verify on the server side that a payment against this payment intent did succeed.
     * @param {string} intentId
     */
    const proceedWithRenewalPayment = async intentId => {
        if (!intentId) {
            Toast.error("Cannot proceed - payment information is missing");
            return;
        }
        setLoading(true);
        setErrorMessage("");
        try {
            await finaliseRenewalPayment(intentId);
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Step 1
     * Create a PaymentIntent and Customer (if it doesn't exist) on the server side
     * @return {Promise<object>}
     */
    const createPaymentIntent = async () => {
        const { productType, companyName, abn, numberOfUsers, token, email } = props;

        if (productType === AirwallexProduct.SAVE_NEW_PAYMENT_METHOD) {
            return axios.get(`${API_URL}/company/airwallex/clientSecret`, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => ({
                customerId: response.data.customerId,
                clientSecret: response.data.clientSecret,
                intentId: null,
                currency: response.data.currency
            }));
        }

        if (productType === AirwallexProduct.INITIAL) {
            return axios.post(`${API_URL}/company/airwallex/startInitialSubscriptionTransaction`, {
                companyName: companyName,
                abn: abn,
                numberOfUsers: numberOfUsers,
                email: email
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => response.data);
        }

        if (productType === AirwallexProduct.ADD_USERS) {
            return axios.post(`${API_URL}/company/airwallex/startAdditionalSubscriptionTransaction`, {
                numberOfUsers: numberOfUsers
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => response.data);
        }

        if (productType === AirwallexProduct.RENEWAL) {
            return axios.post(`${API_URL}/company/airwallex/startRenewalSubscription`, {
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => response.data);
        }

        if (productType === AirwallexProduct.CLIENT_LICENSES) {
            return axios.post(`${API_URL}/company/airwallex/startPurchasingLicenses`, {
                licenseCount: numberOfUsers
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => response.data);
        }

        if (productType === AirwallexProduct.CLIENT_RENEWAL) {
            const { referralCode, key } = subscriptionContext;

            return axios.post(`${API_URL}/clientPurchaseLicense/${referralCode}/${key}`, {
                method: "POST",
                mode: "cors",
            }).then(response => response.data);
        }

        return Promise.reject("Invalid product: " + productType);
    };

    /**
     * Step 2
     * Load the Airwallex Drop-In Element, for card payments, given the supplied PaymentIntent
     * @param {string|null} intentId
     * @param {string} clientSecret
     * @param {string|null} customerId
     * @param {string|null} currency
     * @returns {Promise<void>}
     */
    const initialisePaymentForm = async (intentId, clientSecret, customerId, currency) => {
        let environment = "";
        switch (ENVIRONMENT.get()) {
        case ENVIRONMENT.DEV:
        case ENVIRONMENT.STAGING:
            environment = "demo";
            break;
        case ENVIRONMENT.PRODUCTION:
            environment = "prod";
            break;
        default:
            return;
        }

        /*
         * JavaScript SDK documentation is available at:
         * https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#airwallex-payment-elements---documentation
         */

        let mode, recurringOptions;
        switch (props.productType) {
        case AirwallexProduct.INITIAL:
        case AirwallexProduct.SAVE_NEW_PAYMENT_METHOD:
        case AirwallexProduct.CLIENT_LICENSES:
        case AirwallexProduct.ADD_USERS:
        case AirwallexProduct.RENEWAL:
            mode = "recurring";
            recurringOptions = {
                next_triggered_by: "merchant",
                merchant_trigger_reason: "scheduled",
                currency: currency,
                skip_3ds: false
            };
            break;
        case AirwallexProduct.CLIENT_RENEWAL:
            mode = null; // Don't save card details
            recurringOptions = null;
            break;
        default:
            throw new Error("We cannot identify the product you are trying to purchase");
        }

        await Airwallex.loadAirwallex({
            env: environment,
            origin: window.location.origin
        });

        const airwallexElement = Airwallex.createElement("dropIn", {
            intent_id: intentId, // May be null
            client_secret: clientSecret,
            customer_id: customerId,
            mode: mode,
            recurringOptions: recurringOptions,
            currency: currency,
            methods: [
                "card"
            ]
        });
        const element = airwallexElement.mount(formContainerId);

        element.addEventListener("onSuccess", event => onAirwallexSuccessEvent(event));
        element.addEventListener("onError", event => onAirwallexErrorEvent(event));
        element.addEventListener("onBlur", event => onAirwallexBlurEvent(event));
    };

    /**
     * Step 4
     * Confirm the payment succeeded with our server, then sign the company in
     * @param {string|undefined} intentId
     * @param {string} consentId
     * @param {string} paymentMethodId
     */
    const finalisePayment = async (intentId, consentId, paymentMethodId) => {
        const { productType, token } = props;

        if (productType === AirwallexProduct.SAVE_NEW_PAYMENT_METHOD) {
            return axios.post(`${API_URL}/company/airwallex/paymentMethods`, {
                consentId: consentId,
                paymentMethodId: paymentMethodId
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(_response => {
                setMissionAccomplished(true);
            });
        }

        if (productType === AirwallexProduct.INITIAL || productType === AirwallexProduct.ADD_USERS || productType === AirwallexProduct.RENEWAL || productType === AirwallexProduct.CLIENT_LICENSES) {
            if (!intentId) {
                Toast.error("Cannot proceed - payment information is missing");
                return;
            }

            let productTypeRequestUrl = "confirmTransaction";
            if (productType === AirwallexProduct.RENEWAL) {
                productTypeRequestUrl = "confirmRenewalTransaction";
            }

            return axios.post(`${API_URL}/company/airwallex/${productTypeRequestUrl}`, {
                intentId: intentId,
                consentId: consentId,
                paymentMethodId: paymentMethodId
            }, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then(response => {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const featureLevel = appFeatureLevels.FEATURE_LEVEL_3; // used to set what feature level we're currently at
                updateFeatureLevel(featureLevel, timeZone, token);
                return response.data;
            });
        }
    };

    const updateCompanyWebsite = async (finalisePaymentResponse) => {
        setLoading(true);

        const { token, website } = props;

        return await axios.put(`${API_URL}/company/updateWebsite`, {
            website: website,
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((_response) => {
            const updatedPermissions = finalisePaymentResponse.permissionIds;
            localStorage.setItem("permissions", updatedPermissions);
            props.setUserPermissions(updatedPermissions);
            //update companyName for hasCompany() in Settings
            const { companyName } = subscriptionContext;
            userContext.appFns.updateUserContextProperties({
                [UserKeys.COMPANY_NAME]: companyName
            });

            setLoading(false);
            setMissionAccomplished(true);
        });
    };

    /**
     * Verify that a payment was completed, and then grant the privileges.
     * @param {string} intentId
     */
    const finaliseRenewalPayment = async intentId => {
        return axios.post(`${API_URL}/clientPurchaseLicense/confirmTransaction`, {
            intentId: intentId
        }, {
            method: "POST",
            mode: "cors",
        }).then(_response => {
            setMissionAccomplished(true);
        });
    };

    /**
     * Callback from Airwallex
     * @param {ElementEvent} event - event emitted by Airwallex; not sure how to hint the type correctly without TS
     */
    const onAirwallexSuccessEvent = event => {
        if (!event.detail || !event.detail.intent) {
            setErrorMessage("The payment succeeded, but could not be read. This is a developer error.");
            return;
        }
        setQueuedAirwallexSuccessEvent(event.detail);
    };

    /**
     * Callback from Airwallex
     * @param {ElementEvent} event - event emitted by Airwallex; not sure how to hint the type correctly without TS
     */
    const onAirwallexErrorEvent = event => {
        setErrorMessage("");

        const { error } = event.detail;
        if (error !== undefined) {
            setErrorMessage(error.message);
        }
    };

    /**
     * Callback from Airwallex
     * @param {ElementEvent} _event - event emitted by Airwallex; not sure how to hint the type correctly without TS
     */
    const onAirwallexBlurEvent = _event => {};

    const { productType } = props;
    return (
        <FlexContainer>
            <FlexContainer marginTop="1rem" justifyContent="center" flexGrow="1" minHeight="28rem" maxWidth="28rem">
                { loading && <LoadingIndicator /> }
                <FlexContainer display={ loading ? "none" : "flex" } flexGrow="1">
                    <FlexContainer id={ formContainerId } />
                    <ErrorLabel margin="0.75rem 0 0">{ errorMessage }</ErrorLabel>
                </FlexContainer>
            </FlexContainer>

            <Text align="left">
                Hosted by Airwallex
                <picture>
                    <source srcSet={ImgAirwallexDesktopLogo} />
                    <img style={{ height: "0.85rem", marginLeft: "1em" }} src={ImgAirwallexDesktopLogo} alt="Airwallex Logo" />
                </picture>
            </Text>

            { productType !== AirwallexProduct.CLIENT_RENEWAL &&
                <Text align="start" family="Roboto, Helvetica, sans-serif" color="#8E4EB4">
                    * Your payment method will be stored on your account for subscription, renewals and future payments.
                </Text>
            }
        </FlexContainer>
    );
}

export default AirwallexForm;
