
import { ENVIRONMENT } from "src/scenes/App";

const nowhere = () => false;
// const localhostOnly = () => ENVIRONMENT.get() === ENVIRONMENT.DEV;
const notProductionReady = () => ENVIRONMENT.get() !== ENVIRONMENT.PRODUCTION;

export const IsFeatureReady = {
    signUpButton: notProductionReady,
    chat: notProductionReady,
    chatEncryption: nowhere,
    interactionRateStat: notProductionReady,
    noticeboardAttachments: notProductionReady,
    clientQuoteCategorySelection: notProductionReady,
    accountLinking: notProductionReady,
    digitalMemberCard: notProductionReady,
};
