import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import ImgBackground from "src/img/customise/ImgBackground.png";

import ImgPhoneFrame from "src/img/customise/ImgPhoneFrame.png";
import ImgYourLogoHere from "src/img/customise/ImgYourLogoHere.png";

import ImgQuotesOverlay from "src/img/customise/ImgQuotesOverlay.png";
import ImgQuotesPrimary from "src/img/customise/ImgQuotesPrimary.png";
import ImgQuotesSecondary from "src/img/customise/ImgQuotesSecondary.png";

import ImgNoticeboardBackgroundOverlay from "src/img/customise/ImgNoticeboardBackgroundOverlay.png";
import ImgNoticeboardPrimary from "src/img/customise/ImgNoticeboardPrimary.png";

import MuteIcon from "src/img/advertising/mute.svg";
import UnmuteIcon from "src/img/advertising/unmute.svg";
import { ReactComponent as CloseIcon } from "src/img/advertising/close.svg";

import { Screens } from "../../components/Enums";
import { AdFormContext } from "../AdFormContext";
import { Text } from "src/components";

const LayerSizing = {
    FRAME_WIDTH: 302,
    FRAME_HEIGHT: 614,
    SCREEN_WIDTH: 302,
    SCREEN_HEIGHT: 594
};

const WidthContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
`;

const ZeroSizeSvg = styled.svg`
    width: 0px;
    height: 0px;
    position: absolute;
    pointer-events: none;
`;

const PlainImageLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top};
    bottom: ${ p => p.layout.bottom };
    background-image: url(${ p => p.src });
    background-color: ${ p => p.bgColor };
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.layout.borderRadius}px;
    pointer-events: none;
`;

const InteractiveLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top};
    bottom: ${ p => p.layout.bottom };
    background-image: url(${ p => p.src });
    background-color: ${ p => p.bgColor };
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${p => p.layout.borderRadius}px;
`;

const PlainImageTextLayer = styled.div`
    width: ${p => p.layout.width}px;
    height: ${p => p.layout.height}px;
    position: absolute;
    left: ${p => p.layout.left}px;
    top: ${p => p.layout.top}px;
    background-image: url(${ p => p.src });
    background-size: contain;
    background-position: ${p => p.position || "center"};
    background-repeat: no-repeat;
    pointer-events: none;
    &:after {
        position: absolute; 
        top: 0; 
        left: 0; 
        bottom: 0; 
        right: 0;
        margin-top: 40px; 
        z-index: 99999; 
        font-size: 16px; 
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0px 0px 5px black;
        padding: 5px;
    }
`;

const BackgroundLayer = styled(PlainImageLayer)`
    filter: url(#filter-background);
    pointer-events: none;
`;

const PrimaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-primary);
    pointer-events: none;
`;

const SecondaryLayer = styled(PlainImageLayer)`
    filter: url(#filter-secondary);
    pointer-events: none;
`;

const LinkButton = styled.div`
    display: block;
    width: inherit;
    height: 34px;
    align-content: center;
    background-color: black;
    color: white;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: ${p => p.size};
    font-weight: 500;
    text-align: center;
    border-radius: 24px;
    padding: 0px 20px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
`;

const AdBadge = styled.div`
    width: ${p => p.width ?? "43px"};
    height: ${p => p.height ?? "23px"};
    border-radius: 5px;
    border: 1px solid #9B9B9B;
    color: #9B9B9B;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEBEB;
    text-align: center;
    font-size: ${p => p.size};
    font-weight: 700;
    box-shadow: 0px 2px 5px #0000001A;
`;

const AdBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -12px;
    z-index: 10;
    width: 100%;
`;

const FullscreenCloseButton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid ${p => p.bgColor ?? "#9B9B9B"};
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${ p => p.bgColor ?? "#FFFFFF"};
    margin-right: 10px;
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
`;

const FullscreenCloseContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    z-index: 15;
    top: ${p => p.top || "-40px"};
    width: 100%;
`;

const AdBadgeFullscreenContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ${p => p.top || "-38px"};
    z-index: 10;
    width: 100%;
`;

const AdImageContainer = styled.div`
    height: ${p => p.height};
    width: 100%;
    background-color: #e5e5e5;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
`;

const VerticalAdImageContainer = styled.div`
    height: ${p => p.height};
    border-bottom-right-radius: ${p => p.radiusBottomRight};
    border-bottom-left-radius: ${p => p.radiusBottomLeft};
    width: 100%;
    background-color: #e5e5e5;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;
`;

const NoticeboardLinkButton = styled.div`
    display: block;
    width: inherit;
    height: 36px;
    align-content: center;
    background-color: black;
    color: white;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: ${p => p.size};
    font-weight: 500;
    text-align: center;
    border-radius: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
`;

const VideoMuteButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: rgba(255,255,255,0.25);
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 8px;
    cursor: pointer;
    z-index: 1;
`;

const VerticalVideoMuteButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: rgba(255,255,255,0.25);
    display: flex;
    color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 8px;
    cursor: pointer;
`;

const SvgFilter = (props) => {
    const { filterId, rgbValues } = props;
    const matrix = `0 0 0 0 ${rgbValues[0]} 0 0 0 0 ${rgbValues[1]} 0 0 0 0 ${rgbValues[2]} 0 0 0 1 0`;
    return (
        <filter id={filterId} colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix" values={matrix} />
        </filter>
    );
};

const renderImagePreview = (asset, width) => {
    return (
        <img
            src={asset}
            alt="Ad Preview"
            style={{
                width: width,
                height: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "inherit",
                backgroundColor: "#E8E8E8",
                cursor: "pointer",
                objectFit: "cover",
                objectPosition: "center"
            }}
        />
    );
};

const renderVideoPreview = (asset) => {
    const [muted, setMuted] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);
    const videoRef = useRef(null);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const duration = videoRef.current.duration || 0;
            const currentTime = videoRef.current.currentTime || 0;
            setRemainingTime(Math.ceil(duration - currentTime));
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            const duration = videoRef.current.duration || 0;
            setRemainingTime(Math.ceil(duration));
        }
        videoRef.current.pause();
        videoRef.current.load();
    }, [asset]);
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            borderRadius: "inherit"
        }}>
            <VideoMuteButton 
                onClick={() => setMuted(!muted)}
            >
                {
                    muted ?
                        <img src={MuteIcon} width="12px" height="12px" alt="M" style={{ filter: "invert(1)" }}/>
                        : <img src={UnmuteIcon} width="12px" height="12px" alt="UM" style={{ filter: "invert(1)" }} />
                }
            </VideoMuteButton>
            <video
                ref={videoRef}
                controls={false}
                muted={muted}
                autoPlay={true}
                loop={true}
                onTimeUpdate={handleTimeUpdate}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit"
                }}
            >
                <source src={asset} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                fontSize: "10px",
                color: "white",
            }}>
                {remainingTime > 0 ? `${remainingTime} sec` : "0 sec"}
            </div>
        </div>
    );
};

const renderVerticalImagePreview = (asset, width) => {
    return (
        <img
            src={asset}
            alt="Ad Preview"
            style={{
                width: width,
                height: "inherit",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "inherit",
                backgroundColor: "#E8E8E8",
                cursor: "pointer",
                objectFit: "cover",
                objectPosition: "center"
            }}
        />
    );
};

const renderVerticalVideoPreview = (asset) => {
    const [muted, setMuted] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);
    const videoRef = useRef(null);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const duration = videoRef.current.duration || 0;
            const currentTime = videoRef.current.currentTime || 0;
            setRemainingTime(Math.ceil(duration - currentTime));
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            const duration = videoRef.current.duration || 0;
            setRemainingTime(Math.ceil(duration));
        }
        videoRef.current.pause();
        videoRef.current.load();
    }, [asset]);
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            borderRadius: "inherit"
        }}>
            <div style={{ display: "flex", position: "absolute", zIndex: "10", height: "50%", width: "100%", placeItems: "end", justifyContent: "flex-end" }}>
                <div style={{ margin: "0 15px 15px 0" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <VerticalVideoMuteButton 
                            onClick={() => setMuted(!muted)}
                        >
                            {
                                muted ?
                                    <img src={MuteIcon} width="12px" height="12px" alt="M" style={{ filter: "invert(1)" }}/>
                                    : <img src={UnmuteIcon} width="12px" height="12px" alt="UM" style={{ filter: "invert(1)" }} />
                            }
                        </VerticalVideoMuteButton>
                    </div>
                    <div style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        color: "white",
                    }}>
                        {remainingTime > 0 ? `${remainingTime} sec` : "0 sec"}
                    </div>
                </div>
            </div>
            <video
                ref={videoRef}
                controls={false}
                muted={muted}
                autoPlay={true}
                loop={true}
                onTimeUpdate={handleTimeUpdate}
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit"
                }}
            >
                <source src={asset} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

const truncateString = (str, maxLength) => {
    return (str.length == maxLength) ? str : str.substring(0, maxLength - 3) + "...";
};

const FullscreenQuoteContent = (fullscreenQuoteLayout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm,
        companyColors
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={fullscreenQuoteLayout} bgColor="#fff" >
            <AdBadgeFullscreenContainer>
                <AdBadge width="35px" size="12px">
                    AD
                </AdBadge>
            </AdBadgeFullscreenContainer>
            <FullscreenCloseContainer>
                <FullscreenCloseButton size="12px" bgColor={companyColors.primaryColor}>
                    <CloseIcon width="12px" height="12px" color={companyColors.backgroundColor} />
                </FullscreenCloseButton>
            </FullscreenCloseContainer>
            <AdImageContainer height="140px">
                { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                    <>
                        {
                            formPrimaryAssetTypeContext === "image" ?
                                renderImagePreview(formPrimaryAssetContext, "100%") : 
                                renderVideoPreview(formPrimaryAssetContext)
                        }
                    </>
                )}
            </AdImageContainer>
            <div style={{ padding: "0 15px" }}>
                <Text
                    width="224px"
                    color="#000"
                    size="18px"
                    weight="700"
                    padding="0 10px"
                >
                    { headlineContext ? (headlineContext.length > 40 ? truncateString(headlineContext, 40) : headlineContext) : "Heading here" }
                </Text>
                <Text color="#000" size="12px">{ descriptionContext ? (descriptionContext.length > 150 ? truncateString(descriptionContext, 150) : descriptionContext) : "Description text here" }</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", position: "absolute", bottom: 60, width: "100%" }}>
                <LinkButton size="14px" style={{ width: "45%" }}>{buttonTextContext ? (buttonTextContext.length > 20 ? truncateString(buttonTextContext, 20) : buttonTextContext) : "Call to action"}</LinkButton>
            </div>
        </InteractiveLayer>
    );
};

const FullscreenQuoteVerticalContent = (fullscreenQuoteLayout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        isEditForm,
        formVerticalAssetContext,
        formVerticalAssetTypeContext,
        formVerticalHasAssetContext,
        companyColors
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={fullscreenQuoteLayout} bgColor="#fff" >
            <AdBadgeFullscreenContainer top="38px">
                <AdBadge width="40px" size="12px">
                    AD
                </AdBadge>
            </AdBadgeFullscreenContainer>
            <FullscreenCloseContainer top="35px">
                <FullscreenCloseButton size="12px" bgColor={companyColors.primaryColor}>
                    <CloseIcon width="12px" height="12px" color={companyColors.backgroundColor} />
                </FullscreenCloseButton>
            </FullscreenCloseContainer>
            <VerticalAdImageContainer
                height="98%"
                radiusBottomRight="25px"
                radiusBottomLeft="25px"
            >
                { ((formVerticalAssetContext && formVerticalHasAssetContext) || isEditForm) && (
                    <>
                        {
                            formVerticalAssetTypeContext === "image" ?
                                renderVerticalImagePreview(formVerticalAssetContext, "100%") : 
                                renderVerticalVideoPreview(formVerticalAssetContext)
                        }
                    </>
                )}
            </VerticalAdImageContainer>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", position: "absolute", bottom: "15px", width: "100%", backgroundColor: "#fff", height: "285px", borderRadius: "33px 33px 0 0" }}>
                <div style={{ padding: "0 15px", position: "relative", width: "inherit" }}>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "10px" }}>
                        <div style={{ backgroundColor: "#E6E6E6", height: "4px", width: "55px", borderRadius: "20%" }} />
                    </div>
                    <Text color="#000" size="13px" weight="700" padding="0 10px">{ headlineContext ? (headlineContext.length > 50 ? truncateString(headlineContext, 50) : headlineContext) : "Heading here" }</Text>
                    <Text color="#000" size="12px">{ descriptionContext ? (descriptionContext.length > 160 ? truncateString(descriptionContext, 160) : descriptionContext) : "Description text here" }</Text>
                    <div style={{ display: "flex", justifyContent: "center", position: "absolute", bottom: 40, width: "90%" }}>
                        <LinkButton size="12px" style={{ width: "45%" }}>{buttonTextContext ? (buttonTextContext.length > 20 ? truncateString(buttonTextContext, 20) : buttonTextContext) : "Call to action"}</LinkButton>
                    </div>
                </div>
            </div>
        </InteractiveLayer>
    );
};

const QuotesScreenContent = (layout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={layout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "165px", height: "305px", display: "flex", flexDirection: "column", backgroundColor: "#fff", borderRadius: "25px" }}>
                <AdImageContainer height="100px">
                    { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                        <>
                            {
                                formPrimaryAssetTypeContext === "image" ?
                                    renderImagePreview(formPrimaryAssetContext, "100%") :
                                    renderVideoPreview(formPrimaryAssetContext)
                            }
                        </>
                    )}
                    <AdBadgeContainer>
                        <AdBadge size="10px" width="40px" height="18px">
                            AD
                        </AdBadge>
                    </AdBadgeContainer>
                </AdImageContainer>
                <Text
                    color="#000"
                    size="12px"
                    weight="700"
                    margin="20px 5px 5px 5px"
                >
                    { headlineContext ? (headlineContext.length > 40 ? truncateString(headlineContext, 40) : headlineContext) : "Heading here" }
                </Text>
                <Text color="#000" size="11px" height="60px" margin="0 5px">
                    { descriptionContext ? (descriptionContext.length > 100 ? truncateString(descriptionContext, 100) : descriptionContext) : "Description text here" }
                </Text>
                { descriptionContext.length > 50 &&
                    <div style={{ fontWeight: "bold", fontSize: "11px", fontFamily: "Roboto,Helvetica,sans-serif", textAlign: "center", margin: "0 5px" }}>
                        See More
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "inherit", position: "absolute", bottom: "160px" }}>
                    <LinkButton size="10px" style={{ width: "55%" }}>{buttonTextContext ? (buttonTextContext.length > 20 ? truncateString(buttonTextContext, 20) : buttonTextContext) : "Call to action"}</LinkButton>
                </div>
            </div>
        </InteractiveLayer>
    );
};

const NoticeboardScreenContent = (layout) => {
    const {
        headlineContext,
        descriptionContext,
        buttonTextContext,
        formPrimaryAssetContext,
        formPrimaryAssetChangedContext,
        formPrimaryAssetTypeContext,
        isEditForm
    } = useContext(AdFormContext);
    return (
        <InteractiveLayer key="ADS_InteractiveLayer_screen" layout={layout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "240px", height: "300px", display: "flex", flexDirection: "column", backgroundColor: "rgb(255, 255, 255)", borderRadius: "24px", border: "1px solid #E0E0E0", boxShadow: "0px 5px 10px #0000001A" }}>
                <AdImageContainer height="135px">
                    { ((formPrimaryAssetContext && formPrimaryAssetChangedContext) || isEditForm) && (
                        <>
                            {
                                formPrimaryAssetTypeContext === "image" ?
                                    renderImagePreview(formPrimaryAssetContext, "100%") :
                                    renderVideoPreview(formPrimaryAssetContext)
                            }
                        </>
                    )}
                    <AdBadgeContainer>
                        <AdBadge size="12px" height="18px">
                            AD
                        </AdBadge>
                    </AdBadgeContainer>
                </AdImageContainer>
                <div style={{ 
                    height: "100%",
                    position: "relative",
                    margin: "17px 15px 10px 15px",
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div>
                        <Text color="#000" size="14px" align="left" weight="700" margin="0" style={{ maxWidth: "240px" }}>{ headlineContext ? (headlineContext.length > 50 ? truncateString(headlineContext, 50) : headlineContext) : "Heading here" }</Text>
                        <Text color="#000" height="auto" size="13px" align="left" margin="0" style={{ maxWidth: "240px" }}>
                            { descriptionContext ? (descriptionContext.length > 50 ? truncateString(descriptionContext, 60) : descriptionContext) : "Description text here" }
                        </Text>
                        { descriptionContext.length > 50 &&
                            <span style={{ weight: "bold", fontSize: "13px", fontFamily: "Roboto,Helvetica,sans-serif" }}>
                                See More
                            </span>
                        }
                    </div>
                    <NoticeboardLinkButton size="12px">{buttonTextContext ? (buttonTextContext.length > 30 ? truncateString(buttonTextContext, 25) : buttonTextContext) : "Call to action"}</NoticeboardLinkButton>
                </div>
            </div>
        </InteractiveLayer>
    );
};

const FilteredPngStack = (props) => {
    const { currentScreen, colourPalette, logoUrl } = props;
    const { primaryColor, secondaryColor, backgroundColor } = colourPalette;
    const renderLayers = (currentScreen, logoUrl) => {
        const logoAspect = 3.43;
        const logoSize = {
            width: LayerSizing.FRAME_WIDTH / 2,
            height: Math.floor(LayerSizing.FRAME_WIDTH / 2 / logoAspect)
        };
    
        const phoneLayout = {
            width: LayerSizing.FRAME_WIDTH,
            height: LayerSizing.FRAME_HEIGHT,
            left: 0,
            top: 0
        };
        const screenLayout = {
            width: LayerSizing.SCREEN_WIDTH,
            height: LayerSizing.SCREEN_HEIGHT,
            left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
            top: (LayerSizing.FRAME_HEIGHT - LayerSizing.SCREEN_HEIGHT) / 2
        };
        const quotesScreenLayout = {
            width: LayerSizing.SCREEN_WIDTH,
            height: LayerSizing.SCREEN_HEIGHT,
            left: (LayerSizing.FRAME_WIDTH - LayerSizing.SCREEN_WIDTH) / 2,
        };
        const fullscreenQuoteLayout = {
            width: LayerSizing.SCREEN_WIDTH - 30,
            height: LayerSizing.SCREEN_HEIGHT - 65,
            bottom: "0",
        };
    
        const fullscreenVerticalQuoteLayout = {
            width: LayerSizing.SCREEN_WIDTH - 30,
            height: LayerSizing.SCREEN_HEIGHT + 5,
            bottom: "0",
        };
        const logoLayout = {
            width: logoSize.width,
            height: logoSize.height - (logoSize.height / 5),
            left: (currentScreen === Screens.QUOTES) ? 23.6 : ((LayerSizing.FRAME_WIDTH / 12) / 2),
            top: (currentScreen === Screens.QUOTES || currentScreen === Screens.FULLSCREEN_QUOTE ? LayerSizing.FRAME_HEIGHT / 8.5 : LayerSizing.FRAME_HEIGHT / 2.2) - logoSize.height / 2
        };  
        
        const hasCompanyLogo = logoUrl.charAt(0) !== "?";
        const companyLogo = hasCompanyLogo ? logoUrl : ImgYourLogoHere;
        const imageLayers = [];
        switch (currentScreen) {
            case Screens.QUOTES:
                imageLayers.push(<BackgroundLayer key="MAIN_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
                imageLayers.push(<PrimaryLayer key="MAIN_PrimaryLayer" layout={screenLayout} src={ImgQuotesPrimary} />);
                imageLayers.push(<SecondaryLayer key="MAIN_SecondaryLayer" layout={screenLayout} src={ImgQuotesSecondary} />);
                imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_screen" layout={screenLayout} src={ImgQuotesOverlay} />);
                imageLayers.push(QuotesScreenContent(quotesScreenLayout));
                imageLayers.push(<PlainImageTextLayer position="left" key="MAIN_PlainImageTextLayer" layout={logoLayout} src={companyLogo} />);
                imageLayers.push(<PlainImageLayer key="MAIN_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
                break;
            case Screens.FULLSCREEN_QUOTE:
                imageLayers.push(<BackgroundLayer key="ADS_BackgroundLayer" layout={screenLayout} src={ImgBackground} />);
                imageLayers.push(FullscreenQuoteContent(fullscreenQuoteLayout));
                imageLayers.push(<PlainImageLayer key="ADS_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
                break;
            case Screens.FULLSCREEN_VERTICAL_QUOTE:
                imageLayers.push(FullscreenQuoteVerticalContent(fullscreenVerticalQuoteLayout));
                imageLayers.push(<PlainImageLayer key="ADS_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
                break;
            case Screens.NOTICEBOARD:
                imageLayers.push(<BackgroundLayer key="NOTICEBOARD_BackgroundLayer" layout={screenLayout} src={ImgNoticeboardBackgroundOverlay} />);
                imageLayers.push(NoticeboardScreenContent(quotesScreenLayout));
                imageLayers.push(<PrimaryLayer key="NOTICEBOARD_PrimaryLayer" layout={screenLayout} src={ImgNoticeboardPrimary} />);
                imageLayers.push(<PlainImageLayer key="NOTICEBOARD_PlainImageLayer_phone" layout={phoneLayout} src={ImgPhoneFrame} />);
                break;
            default:
                break;
        }
    
        return imageLayers;
    };
    return (
        <WidthContainer>
            <ZeroSizeSvg>
                <SvgFilter filterId="filter-primary" rgbValues={primaryColor} />
                <SvgFilter filterId="filter-secondary" rgbValues={secondaryColor} />
                <SvgFilter filterId="filter-background" rgbValues={backgroundColor} />
            </ZeroSizeSvg>
            {renderLayers(currentScreen, logoUrl)}
        </WidthContainer>
    );
};

export default FilteredPngStack;
