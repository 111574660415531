import { useState, useContext } from "react";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES, GROUP_CHAT, ONE_ON_ONE_CHAT } from "src/constants/chat";
import { Toast } from "src/components";
import {
    useCreateChat
} from "src/scenes/Chat/hooks/index";
import { UserContext } from "src/scenes/App/UserContext";
export const useCreateChatModal = (leadershipRoles, insertChatCreateResponseToMatrixChats, setOpenedMatrixChat, setLookingForLeadershipToChat, handleChatListSelectChange) => {
    const currentUser = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };

    const { createChatHook } = useCreateChat();
    const [isCurrentlyCreatingChat, setIsCurrentlyCreatingChat] = useState(false);
    const [chatToCreate, setChatToCreate] = useState({
        showModal: false,
        employeesToChat: [],
        chatType: "" // group or single/one-on-one
    });
    
    const handleCreateChat = async (formData) => {
        if (isCurrentlyCreatingChat) {
            Toast.error("Creating a new chat in progress.");
            return;
        }

        const isGroupChat = chatToCreate.chatType === GROUP_CHAT;
        if (isGroupChat && formData.employeeUuidsToChat.length < 2) {
            Toast.error("A group chat requires a minimum of 2 users.");
            return;
        }

        setIsCurrentlyCreatingChat(true);
        try {
            const created = await createChatHook(formData, chatToCreate, headers);
            closeCreateChatModal();
            handleCreateChatResponse(created);
        } catch (error) {
            Toast.error(error);
        } finally {
            setIsCurrentlyCreatingChat(false);
        }
    };
    
    const handleCreateChatResponse = (response) => {
        const matrixChat = insertChatCreateResponseToMatrixChats(response);

        const responseLeadershipRoleUuid = response.chat.leadershipRoleUuid;
        const userType = !response.isClient ? USER_TYPE_EMPLOYEES : USER_TYPE_CLIENTS;
        const isLeadership = responseLeadershipRoleUuid !== "";

        /* Active Chat toggle issues: change toggle when new chat created in another list, swap chat interface when the active list is changed */
        handleChatListSelectChange(isLeadership ? responseLeadershipRoleUuid : userType);

        setOpenedMatrixChat(matrixChat); //auto-open newly created chat, see useChatMessage.js
        setLookingForLeadershipToChat(null);
    };
    
    const showCreateChatModalOrShowLeadershipEmployees = (chatParticipantType, selectedChatListTypeOrLeadershipUuid) => {
        setLookingForLeadershipToChat(null);
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
        
        if (isNonLeadership) { //creating a chat that is not leadershiprole related
            setChatToCreate({
                ...chatToCreate,
                chatType: chatParticipantType,
                showModal: true
            });
            return;
        }

        if (!leadershipRoles) {
            console.warn("Leadership roles is empty, unable to do further action.");
            return;
        }

        const leadershipRoleIndex = leadershipRoles.findIndex((item) => item.uuid === selectedChatListTypeOrLeadershipUuid);
        if (leadershipRoleIndex === -1) {
            console.warn("Unable to create chat for the selected Active Chat List: ", chatParticipantType, selectedChatListTypeOrLeadershipUuid, leadershipRoles);
        }

        const activeLeadershipRole = leadershipRoles[leadershipRoleIndex];

        if (activeLeadershipRole.currentUserIsLeader) { //viewing as a leadership leader
            setChatToCreate({
                ...chatToCreate,
                chatType: chatParticipantType,
                showModal: true
            });
            return;
        }

        //viewing as non-leader, show Leadership employee
        setLookingForLeadershipToChat(activeLeadershipRole); //from useActiveChat.js
    };

    const closeCreateChatModal = () => {
        setChatToCreate({
            ...chatToCreate,
            showModal: false
        });
    };

    const createChatWithLeadership = async (formData) => {
        if (isCurrentlyCreatingChat) {
            Toast.error("Creating a new chat in progress.");
            return;
        }

        setIsCurrentlyCreatingChat(true);
        try {
            setChatToCreate({ ...chatToCreate, chatType: ONE_ON_ONE_CHAT });
            const created = await createChatHook(formData, chatToCreate, headers);
            handleCreateChatResponse(created);
        } catch (error) {
            Toast.error(error.message || "Unable to create a new chat with a leadership employee.");
        } finally {
            setIsCurrentlyCreatingChat(false);
        }
    };

    return {
        showCreateChatModalOrShowLeadershipEmployees,
        chatToCreate,
        closeCreateChatModal,
        handleCreateChat,
        handleCreateChatResponse,
        isCurrentlyCreatingChat,
        createChatWithLeadership
    };
};