import React from "react";
import { ErrorList, ErrorStrings, surveyShowInOptions, surveyFrequencyOptions, } from "../../../components/Enums";
import { customStyles, IconOption, SingleValue } from "../DropdownCustomStyles";
import { ErrorText, FlexContainer, Text } from "src/components";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import Select from "react-select";

export const SurveyFrequencyField = (props) => {
    const { inputErrors, selectShowIn, selectShowFrequency, survey, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const showInError = getErrorStrings(ErrorList.EMPTY_SHOW_IN);
    const showFrequencyError = getErrorStrings(ErrorList.EMPTY_SHOW_FREQUENCY);

    return (
        <>
            <Text align="left" size="16px" weight="500">
                Show Survey In:
            </Text>
            <FlexContainer>
                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyShowInOptions[0]}
                        value={ surveyShowInOptions.find(obj => obj.value == survey.showIn) }
                        options={ surveyShowInOptions }
                        onChange={(choice) => selectShowIn(choice)}
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />
                }

                <ErrorText>
                    {showInError}
                </ErrorText>


                <Text align="left" margin="10px 0px" size="16px" weight="500">
                    Select Survey Frequency?
                </Text>

                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={surveyFrequencyOptions[0]}
                        options={ surveyFrequencyOptions }
                        onChange={(choice) => selectShowFrequency(choice)}
                        value={ surveyFrequencyOptions.find(obj => obj.value == survey.showFrequency) }
                        styles={customStyles}
                        components={{ Option: IconOption, SingleValue: SingleValue }}
                    />
                }
                <ErrorText>
                    {showFrequencyError}
                </ErrorText>
            </FlexContainer>
        </>
    );
};