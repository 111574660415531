import React from "react";
import { useRoomTimeline } from "../../hooks";

const ChatRoomTimeline = (props) => {
    const { roomTimeline, currentChat, currentMatrixUserId } = props;
    const { renderTimelineEvent } = useRoomTimeline(roomTimeline, currentChat, currentMatrixUserId);

    const renderRoomTimeline = () => {

        //adding this logic, as we are still using the oldState of roomTimeline even thoughh we changed `currentChat`.
        const cachedRoomTimelineMatchesTheRoom = roomTimeline.filter(matrixEvent => matrixEvent.getRoomId() === currentChat.matrixRoomId);
        if (cachedRoomTimelineMatchesTheRoom.length === 0) {
            console.warn("Skipping displaying the room timeline, we are waiting for the setState to take effect.");
            return;
        }

        try
        {
            const sortedChat = sortByLatestEventTimestamp(roomTimeline);
            return (
                sortedChat.map((matrixEvent, index) => renderTimelineEvent(matrixEvent, index))
            );
        }
        catch (e) {
            console.warn("Unable to render the messages for this room", e);
        }
    };

    const sortByLatestEventTimestamp = (chats) => {
        return chats.sort((a, b) => {
            const timestampA = new Date(a.getTs());
            const timestampB = new Date(b.getTs());
            return timestampB - timestampA;
        });
    };

    return (
        <React.Fragment>
            { roomTimeline && roomTimeline.length > 0 && renderRoomTimeline() }
        </React.Fragment>
    );
};


export default ChatRoomTimeline;