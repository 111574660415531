import { useContext, useState } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";
import { Toast } from "src/components";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";
/**
 * Custom hook for getting chats (employee, clients or leadership role).
 * Also updates a Chat's `latestEvent` once received by timeline listener.
 *
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 *
 * @param {MatrixClient} matrixClient
 * @returns {{
 *      chatList: Array
 *      didFetchChatsFail: boolean
 *      setDidFetchChatsFail: React.SetStateAction
 *      isFetchingChats: boolean
 *      retrieveChats: ((function(string): Promise<void>))
 *      removeChatFromChatlist: ((function(string): void))
 *      setChatList: React.SetStateAction
 *      populateActiveChatListDetails: void    
 *      activeChatListDetails: Array
 *      setActiveChatListDetails: React.SetStateAction
 *      updateActiveList: (function(object): void)
 * 
 * }}
 */
export const useGetChats = (matrixClient, setLeadershipRoles) => {

    const userContext = useContext(UserContext);
    const [chatList, setChatList] = useState([]);
    const [isFetchingChats, setIsFetchingChats] = useState(false);
    const [didFetchChatsFail, setDidFetchChatsFail] = useState(false);
    const [activeChatListDetails, setActiveChatListDetails] = useState([]);
    const [isFetchingActiveChats, setIsFetchingActiveChats] = useState(true);
    
    const [/*isFetchingLeadership*/, setIsFetchingLeadership] = useState(false);
    
    const headers = { headers: { Authorization: "Bearer " + userContext.token } };

    /**
     * Also retrieves company's background color to be used by ChatList
     * @param {string} selectedChatListTypeOrLeadershipUuid
     * @returns {Promise<void>}
     */
    async function fetchData(selectedChatListTypeOrLeadershipUuid) {
        if (!selectedChatListTypeOrLeadershipUuid) {
            console.warn("No selected chat type to fetch in fetchData().", selectedChatListTypeOrLeadershipUuid);
            return;
        }
        
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
        const url = isNonLeadership ? selectedChatListTypeOrLeadershipUuid : `getLeadershipChats/${selectedChatListTypeOrLeadershipUuid}`;
        const latestResponse = await axios.get(`${API_URL}/chats/${url}`, headers);
        return { 
            label: selectedChatListTypeOrLeadershipUuid, 
            url: url, 
            data: latestResponse.data 
        };
    }

    const retrieveChats = async (selectedChatListTypeOrLeadershipUuid) => {
        if (!selectedChatListTypeOrLeadershipUuid) {
            Toast.error("No selected chat type to fetch.");
            return;
        }

        try {
            setIsFetchingChats(true);
            const activeChatDetails = await fetchData(selectedChatListTypeOrLeadershipUuid);
            return activeChatDetails.data;
        } catch (error) {
            setDidFetchChatsFail(true);
            Toast.error("Failed to retrieve chats.");
            console.warn("Failed to retrieve chats", error);
        } finally {
            setIsFetchingChats(false);
        }
    };

    const retrieveLeadershipRoles = async () => {
        setIsFetchingLeadership(true);
        try {
            const response = await axios.get(`${API_URL}/chats/leadership/roles`, headers);
            const leadershipRoles = response.data.leadershipRoles;
            setLeadershipRoles(response.data.leadershipRoles);
            return leadershipRoles;
        } catch (error) {
            Toast.error("Unable to retrieve leadership roles.");
        } finally {
            setIsFetchingLeadership(false);
        }
    };

    const _getActiveChatUserTypeUrls = () => {
        const userTypeUrls = [
            `${USER_TYPE_EMPLOYEES}`,
            `${USER_TYPE_CLIENTS}`,
        ];

        // Merge the arrays using spread operator
        return userTypeUrls;
    };

    const _leadershipRoleUrls = async () => {
        const leadershipRoles = await retrieveLeadershipRoles();
        let leadershipRoleUrls = [];
        if (leadershipRoles.length > 0) {
            leadershipRoleUrls = leadershipRoles.map(role => { return role.uuid; });
        }

        // Merge the arrays using spread operator
        return leadershipRoleUrls;
    };

    const fetchMultipleChatsByUrls = async (chatUrls) => {
        setIsFetchingChats(true);
        try {
            const fetchDataResponses = await Promise.all(chatUrls.map(fetchData));
            setActiveChatListDetails(prevDetails => {
                const updatedDetails = [...prevDetails];
              
                fetchDataResponses.forEach(newDetail => {
                    const index = updatedDetails.findIndex(existingDetail => existingDetail.label === newDetail.label);
              
                    if (index !== -1) {
                        updatedDetails[index] = newDetail; // Update if found
                    } else {
                        updatedDetails.push(newDetail); // Add if not found
                    }
                });
              
                return updatedDetails;
            });
        } catch (error) {
            console.error("Error fetching chats:", error);
        } finally {
            setIsFetchingChats(false);
        }
    };

    const populateActiveChatListDetails = async (isSilentLoading = false) => {

        if (!isSilentLoading) {
            setIsFetchingActiveChats(true);
        }

        try {
            const userTypeUrls = _getActiveChatUserTypeUrls();
            fetchMultipleChatsByUrls(userTypeUrls); /*no await*/ 

            const leadershipRoleUrls = await _leadershipRoleUrls();
            await fetchMultipleChatsByUrls(leadershipRoleUrls);
        } catch (error) {
            console.error("Error populating active chats:", error);
        } finally {
            setIsFetchingActiveChats(false);
        }
    };

    return {
        chatList,
        didFetchChatsFail,
        setDidFetchChatsFail,
        isFetchingChats,
        retrieveChats,
        setChatList,
        populateActiveChatListDetails,
        isFetchingActiveChats,
        activeChatListDetails,
        setActiveChatListDetails
    };
};