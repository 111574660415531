import React, { useContext, useState } from "react";
import { Toast } from "src/components";
import SendIcon from "src/img/new/send.svg";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { useSendMessage } from "src/scenes/Chat/hooks";
import {
    ChatTextBox, ChatInput, SendIconContainer
} from "src/scenes/Chat/components/styled/chatMessagesStyled";
import { MatrixError } from "matrix-js-sdk";

const ChatInputMessages = (props) => {

    const { currentChat } = props;
    const { 
        matrixClient,
        useMatrixChatList: ctxUseMatrixChatList
    } = useContext(ChatContext);

    const { 
        upsertRoomInMatrixChatList
    } = ctxUseMatrixChatList;
    
    //const { abort } = useAbortController();
    const { prepareMessage, newMessage, setNewMessage, queued } = useSendMessage(currentChat, matrixClient);
    const messageChecker = newMessage === "" || (!newMessage.replace(/\s/g, "").length) === true;

    const [isLocalTypingMessage, setIsLocalTypingMessage] = useState(newMessage);
    const [isLocalTypingState, setIsLocalTypingState] = useState(null);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && (!messageChecker)) {
            prepareMessage();
            upsertRoomInMatrixChatList(currentChat.matrixRoomId);
        }
    };

    const setNewMessageAndTriggerTypingEvent = (value) => {
        setNewMessage(value);
        
        if (isLocalTypingMessage !== value) {
            handleSendTypingEvent(true);
        }
    };
    
    const handleSendTypingEvent = async (value) => {
        try {
            if (isLocalTypingState === value) {
                return;
            }

            if (isLocalTypingMessage === newMessage) {
                return;
            }
            
            setIsLocalTypingState(value);
            setIsLocalTypingMessage(newMessage);

            await matrixClient.sendTyping(currentChat.matrixRoomId, value, 100);
            upsertRoomInMatrixChatList(currentChat.matrixRoomId);
        } catch (error) {
            console.error("handleSendTypingEvent encountered an error: ", error);
            const matrixError = new MatrixError(error);
            /**
             * this error means the logged-in user is sending a `typing` event to a room he/she is not a member of;
             * - a deleted chat, or one that they have been removed
             * ChatList should prevent this from happening see `matrixRoomIdToRemove` effect
             * in case that it doesn't, this will make the error somehow friendly :)
             */
            if (matrixError.errcode === "M_FORBIDDEN") {
                Toast.error("You can no longer send messages in this room. Page will reload.");
                setTimeout(() => window.location.reload(), 1500);
            }
        }
    };

    return (
        <>
            <ChatTextBox onKeyDown={(e) => handleKeyPress(e)}>
                <ChatInput
                    placeholder="Enter your message here"
                    onChange={(e) => setNewMessageAndTriggerTypingEvent(e.target.value)}
                    value={newMessage}
                    onFocus={() => handleSendTypingEvent(true)}
                    onBlur={() => handleSendTypingEvent(false)}
                />
                
                <SendIconContainer onClick={() => prepareMessage()}
                    disabled={messageChecker}
                    className={queued !== null && queued.isSending ? "disabled-element" : ""}
                >
                    <img src={SendIcon} alt="Send" style={{ transform: "rotate(-90deg)" }}/>
                </SendIconContainer>
            </ChatTextBox>
        </>
    );
};

export default ChatInputMessages;