import { ClientEvent, EventType, UserEvent, SetPresence } from "matrix-js-sdk";
import { useCallback, useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { UserKeys } from "src/constants/userDetails";

/**
 * as of writing, other `presence` properties have no usage so we will only store the uuids of online users in an array
 * @param {MatrixClient} matrixClient 
 * @param {boolean} isInitialSyncComplete
 * @param {Object} headers
 * @param {Object} userContext
 * @param {(string) => void} retrieveUsersList
 * @param {string} selectedChatListTypeOrLeadershipUuid
 * @returns {{
 *      onlineUsers: string[]
 *      currentUserOnlineStatus: boolean
 * }} - online users' Matrix IDs, and whether the current user is online
 */

export const usePresenceListener = (matrixClient, isInitialSyncComplete, headers, userContext, handleSettingCurrentUserStatus) => {
    const [ onlineUsersMatrixId, setOnlineUsersMatrixId ] = useState([]); // see JSDoc above

    
    /*  This is supposed to handle the current user's online status on page load.
    **  This would basically set own user presence status based on saved value from database.
    **  If the user does not have any presence status set, we'll default to sending "online" to matrix servers
    */
    const initializePresence = async () => {

        const override = userContext[UserKeys.CHAT_PRESENCE_OVERRIDE];
        if (override === SetPresence.Online || override === SetPresence.Offline) {
            await handleSetOwnUserPresence(override);
        } else {
            await handleSetOwnUserPresence(SetPresence.Online);
        }

        listenForUserPresence(matrixClient);
    };

    useEffect(() => {
        if (matrixClient && isInitialSyncComplete) {
            initializePresence();
        }
    }, [matrixClient, isInitialSyncComplete]);

    
    // eslint-disable-next-line no-unused-vars
    const listenForUserPresence = async (client) => {
        /**
         * this listens only to presence events manually sent by a user (ie: opening chat or closing, setting status manually)
         * but this cannot get the presence of other users that are already online when user opens the chat
         * use this if you want to show the user as `offline/online` right after their presence changes
         */
        client.on(UserEvent.Presence, (event) => {
            presenceHandler(event, "setOwnPresence");
        });

        /**
         * This can listen to Presence events regularly sent at fixed intervals (20-30 seconds),
         * allowing us to get the presence of other users that are already online
         */
        client.on(ClientEvent.Event, (event) => {
            presenceHandler(event, "sync");
        });
    };

    /**
     * This function is called by default for handling changes sent from matrix sync statuses
     * this allows us to be able to switch the status dot for the chat list and create chat modal list
     */
    const presenceHandler = (event, listenerType) => {
        if (listenerType === "setOwnPresence" && event.getType() === EventType.Presence) {
            const presenceEvent = event.getEffectiveEvent();
            if (!presenceEvent) return;
            const presence = presenceEvent.content.presence;
            const senderUserId = presenceEvent.sender;
            handleStatus(presence, onlineUsersMatrixId, senderUserId);
        }
        /* this section will make sure we're only monitoring statuses of other users
        ** doing so will prevent unintended overrides in the current user side of things
        */
        if (listenerType === "sync" && event.getType() === EventType.Presence) {
            const presenceEvent = event.getEffectiveEvent();
            if (!presenceEvent) return;
            const presence = presenceEvent.content.presence;
            const senderUserId = presenceEvent.sender;
            if (userContext[UserKeys.MATRIX_USER_ID] !== senderUserId) {
                handleStatus(presence, onlineUsersMatrixId, senderUserId);
            }
        }
    };

    const handleStatus = (status, onlineUsers, matrixUserId) => {
        if (status === SetPresence.Offline) {
            const userIds = handleOfflinePresence(onlineUsers, matrixUserId);
            setOnlineUsersMatrixId(userIds);
        }
        else if (status === SetPresence.Online) {
            const userIds = handleOnlinePresence(onlineUsers, matrixUserId);
            setOnlineUsersMatrixId(userIds);
        }
    };

    /*
    **  setUserPresence is only called for the dropdown found in chatList.js
    **   as a onClick action passed down to UserAvatar.js
    */
    const setUserPresence = useCallback(async (onlineUsers, newPresenceStatus, matrixUserId, handleSettingCurrentUserStatus) => {
        const currentPresenceStatus = userContext[UserKeys.CHAT_PRESENCE_OVERRIDE];

        handleStatus(newPresenceStatus, onlineUsers, matrixUserId);
        
        try {
            if (!matrixUserId) {
                throw new Error("[usePresenceListener] matrixUserId not found.");
            }

            const formData = {
                chatPresenceOverride: newPresenceStatus,
                matrixUserId: matrixUserId
            };
            const savePresenceUrl = `${API_URL}/chats/users/setPresenceOverride`;
            try {
                handleSettingCurrentUserStatus(newPresenceStatus);
                const result = await axios.post(savePresenceUrl, formData, headers);
                handleStatus(newPresenceStatus, onlineUsersMatrixId, matrixUserId);
                if (result.status !== 200) {
                    handleSettingCurrentUserStatus(currentPresenceStatus); //turn back the old status when error
                }
            } catch (error) {
                return console.error("[usePresenceListener] Failed saving user presence.", error);
            }
        } catch (error) {
            return console.error("[usePresenceListener] An error occurred on setting the userPresence", error);
        }
    });

    const handleSetOwnUserPresence = async (status) => {
        setUserPresence(onlineUsersMatrixId, status, matrixClient.getUserId(), handleSettingCurrentUserStatus, userContext);
        if (status === SetPresence.Offline) {
            setClientPresenceToOffline();
        } else {
            setClientPresenceToOnline();
        }
    };

    const setClientPresenceToOffline = () => {

        /* {IPresenceOpts} presenceOpts */
        const presenceOpts = {
            presence: SetPresence.Offline,
            status_msg: ""
        };

        matrixClient.setSyncPresence(SetPresence.Offline);
        matrixClient.setPresence(presenceOpts);
    };

    const setClientPresenceToOnline = () => {
        /* {IPresenceOpts} presenceOpts */
        const presenceOpts = {
            presence: SetPresence.Online,
            status_msg: ""
        };

        matrixClient.setSyncPresence(SetPresence.Online);
        matrixClient.setPresence(presenceOpts);
    };

    return {
        onlineUsersMatrixId,
        handleSetOwnUserPresence
    };
};

const getIndexFromOnlineUsersList = (onlineUsers, senderId) => {
    if (Array.isArray(onlineUsers)) {
        return onlineUsers.findIndex(userId => userId === senderId);
    }
};

const handleOnlinePresence = (matrixUserIds, matrixUserIdToAdd) => {
    if (!matrixUserIds) {
        console.warn("matrixUserIds is null or undefined!");
        return;
    }

    const index = getIndexFromOnlineUsersList(matrixUserIds, matrixUserIdToAdd);
    if (index === -1) {
        const newIds = [...matrixUserIds];
        newIds.push(matrixUserIdToAdd);
        return newIds;
    }
    return matrixUserIds;
};

const handleOfflinePresence = (matrixUserIds, matrixUserIdToRemove) => {
    if (!matrixUserIds) {
        console.warn("matrixUserIds is null or undefined!");
        return;
    }

    const index = getIndexFromOnlineUsersList(matrixUserIds, matrixUserIdToRemove);
    if (index !== -1) {
        const newIds = [...matrixUserIds];
        newIds.splice(index, 1);
        return newIds;
    }
    return matrixUserIds;
};