import React from "react";
import { ErrorList, ErrorStrings } from "../../../components/Enums";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import { AddAnotherQuestionContainer, EditQuestionContainer, QuestionContainer } from "../../../components/Utils";

export const SurveyQuestionsFields = (props) => {
    const { inputQuestion, inputErrors, survey, activeQuestionCount, addQuestion, removeQuestion, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    let surveyQuestionOneError = getErrorStrings(ErrorList.EMPTY_QUESTION_ONE);
    let surveyQuestionTwoError = getErrorStrings(ErrorList.EMPTY_QUESTION_TWO);
    let surveyQuestionThreeError = getErrorStrings(ErrorList.EMPTY_QUESTION_THREE);

    const createSurveyQuestion = () => {
        return (
            <div style={{ width: "90%" }}>
                {activeQuestionCount >= 1 && <QuestionContainer questionNumber={1} questionValue={survey.surveyQuestions.one.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} showRemoveQuestion={false}></QuestionContainer>}
                {activeQuestionCount === 1 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}

                {activeQuestionCount >= 2 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={2} questionValue={survey.surveyQuestions.two.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} removeQuestion={removeQuestion} questionElement={"two"} showRemoveQuestion={activeQuestionCount === 2}></QuestionContainer>
                            </div>
                        </div>
                        {activeQuestionCount === 2 && <AddAnotherQuestionContainer addQuestion={addQuestion} />}
                    </>
                }
                {activeQuestionCount >= 3 && 
                    <>
                        <div style={{ display: "flex", direction: "row", position: "relative" }}>
                            <div style={{ display: "block", width: "100%" }}>
                                <QuestionContainer questionNumber={3} questionValue={survey.surveyQuestions.three.question} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} removeQuestion={removeQuestion} questionElement={"three"} showRemoveQuestion={activeQuestionCount === 3}></QuestionContainer>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    };

    const editSurveyQuestion = () => {
        return (
            <div style={{ width: "90%" }}>
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer isEditForm={true} itemStatus={survey.status} questionNumber={1} questionValue={survey.surveyQuestions.one ? survey.surveyQuestions.one.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionOneError} />
                        </div>
                    </div>
                }
           
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer isEditForm={true} itemStatus={survey.status} questionNumber={2} questionValue={survey.surveyQuestions.two ? survey.surveyQuestions.two.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionTwoError} />
                        </div>
                    </div>
                }
          
                {
                    <div style={{ display: "flex", direction: "row", position: "relative" }}>
                        <div style={{ display: "block", width: "100%" }}>
                            <EditQuestionContainer isEditForm={true} itemStatus={survey.status} questionNumber={3} questionValue={survey.surveyQuestions.three ? survey.surveyQuestions.three.question : ""} inputQuestion={inputQuestion} surveyQuestionError={surveyQuestionThreeError} />
                        </div>
                    </div>
                }
            </div>
        );
    };

    return (
        loading ?
            <div style={{ paddingTop: "1rem" }}>
                <Skeleton animation="wave" width="auto" height="50px" />
            </div>
            : isEditSurveyForm ? editSurveyQuestion() : createSurveyQuestion()
    );
};
