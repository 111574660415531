import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

import AdvertisingTable from "./components/AdvertisingTable";
import DeleteDialog from "./components/DeleteDialog";
import MegaphoneIcon from "src/img/advertising/Megaphone black.svg";

import { Toast, PageContainer, ExplainParagraphLabel, Text } from "src/components";
import AdvertisingForm from "./form/AdvertisingForm";
import { AdContext } from "./AdContext";
import { AnimationColors } from "./components/Enums";
import AdvertisingViewsModal from "./components/AdvertisingViewsModal";
import AdvertisingClicksModal from "./components/AdvertisingClicksModal";

export const AUDIENCE = {
    EMPLOYEE: "Employees",
    CLIENT: "Clients",
    EMPLOYEES_CLIENTS: "Employees %26 Clients" // %26 is & character
};

export const FILTER_BY = {
    ACTIVE_ADS: "Active",
    SCHEDULED_ADS: "Scheduled",
    COMPLETED_ADS: "Complete",
};

const PageParagraph = styled(ExplainParagraphLabel)`
    max-width: 48.25em;
    flex-wrap: auto;
`;

const AdDistributionBanner = styled.div`
    max-width: 577px;
    height: 120px;
    border: 1px solid #8291B2;
    border-radius: 12px;
    background-color: #fff;
    align-items: center;
    padding: 0px 19px 0 22px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: auto;
    @media (max-width: 600px) {
        padding: 16px 22px !important;
        min-height: 120px;
    };
`;

const Advertising = ({ user }) => {
    const [filterOption, setFilterOption] = useState(FILTER_BY.ACTIVE_ADS);
    const [audienceOption, setAudienceOption] = useState(AUDIENCE.EMPLOYEE);
    const [isFetching, setIsFetching] = useState(false);
    const [advertisementsArray, setAdvertisementsArray] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isRequestingDeletion, setIsRequestingDeletion] = useState(false);
    const [checkAllAds, setCheckAllAds] = useState(false);
    const [checkedAdvertisements, setCheckedAdvertisements] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [showAdForm, setShowAdForm] = useState(false);
    const [showAdsTable, setShowAdsTable] = useState(true);
    const [adToEdit, setAdToEdit] = useState(null);
    const [companyColorsReady, setCompanyColorsReady] = useState(false);
    const [companyColors, setCompanyColors] = useState({
        primaryColor: AnimationColors.PRIMARY,
        secondaryColor: AnimationColors.SECONDARY,
        backgroundColor: AnimationColors.BACKGROUND,
    });
    const [pageHeadingLabel, setPageHeadingLabel] = useState("Advertising");
    const [advertismentViewershipModalVisibility, setAdvertisementViewersModalVisibility] = useState(false);
    const [advertismentClicksModalVisibility, setAdvertisementClicksModalVisibility] = useState(false);
    const [advertisementData, setAdvertisementData] = useState(null);

    const controllerRef = useRef(new AbortController());

    useEffect(() => {
        const fetchDataAsync = async () => {
            await fetchCompanyAds(FILTER_BY.ACTIVE_ADS, AUDIENCE.EMPLOYEE);
            fetchCompanyColors();
            setIsInitialized(true);
        };
    
        fetchDataAsync();
    }, [user.token]);

    const fetchCompanyAds = async (filterOption, audienceOption) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        let advertisingApi = `${API_URL}/advertising/companyAds`;

        let queryParams = [];

        if (filterOption) {
            queryParams.push(`filter=${filterOption}`);
        }

        if (audienceOption) {
            queryParams.push(`type=${audienceOption}`);
        }

        if (queryParams.length > 0) {
            advertisingApi += `?${queryParams.join("&")}`;
        }

        setIsFetching(true);
        axios.get(advertisingApi, {
            headers: {
                Authorization: "Bearer " + user.token
            },
            signal: controllerRef.current.signal
        }).then((res) => {
            setAdvertisementsArray(res.data.companyAds);
            setIsFetching(false);
        }).catch((error) => {
            if (error !== "canceled") {
                setIsFetching(false);
                console.error("Request failed:", error);
            }
        });
    };

    const fetchCompanyColors = async () => {
        axios.get(`${API_URL}/advertising/companyColors`, {
            headers: {
                Authorization: "Bearer " + user.token
            },
        }).then(res => {
            const { primaryColor, secondaryColor, backgroundColor } = res.data;
            setCompanyColors({
                primaryColor: primaryColor,
                secondaryColor: secondaryColor,
                backgroundColor: backgroundColor,
            });
        }).catch(error => {
            console.warn(error.message);
        }).finally(res => setCompanyColorsReady(true));
    };

    const handleShowAdForm = () => {
        setShowAdForm(true);
        setShowAdsTable(false);
        setPageHeadingLabel("Create New Ad");
    };

    const handleHideAdForm = () => {
        setShowAdForm(false);
        setShowAdsTable(true);
        setAdToEdit(null);
        setPageHeadingLabel("Advertising");
    };

    useEffect(() => {
        if ((filterOption || audienceOption) && isInitialized) {
            fetchCompanyAds(filterOption, audienceOption);
        }
    }, [filterOption, audienceOption]);

    const handleFilterChange = event => {
        resetListingState();
        setFilterOption(event.target.value);
    };

    const handleAudienceChange = event => {
        resetListingState();
        setAudienceOption(event.target.value);
    };

    const resetListingState = () => {
        setCheckAllAds(false);
        setCheckedAdvertisements([]);
    };

    const handleMultipleDelete = async (advertUuids) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        try {
            setIsRequestingDeletion(true);
            await axios.delete(`${API_URL}/advertising/deleteAdvertisements`, {
                data: { advertUuids },
                headers: {
                    Authorization: "Bearer " + user.token
                },
                signal: controllerRef.current.signal
            });

            Toast.success("Selected advertisements have been deleted.");
        } catch (error) {
            Toast.error("Unable to process deletion of advertisement.");
        } finally {
            setIsRequestingDeletion(false);
            fetchCompanyAds(filterOption, audienceOption);
            setCheckedAdvertisements([]);
            handleCloseDeleteDialog();
        }
    };

    const handleCheckAllAds = (ads) => {
        const toggleState = !checkAllAds;
        setCheckAllAds(toggleState);
        const newCheckedUuids = toggleState ? ads.map(ads => ads.uuid) : [];
        setCheckedAdvertisements(newCheckedUuids);
    };

    const handleCheckboxChange = (adUuid) => {
        let newCheckedUuids = [];
        
        if (!checkedAdvertisements.includes(adUuid)) {
            newCheckedUuids = [...checkedAdvertisements, adUuid];
        } else {
            newCheckedUuids = checkedAdvertisements.filter(uuid => uuid !== adUuid);
        }
        if (advertisementsArray.length === newCheckedUuids.length) {
            setCheckAllAds(true);
        } else {
            setCheckAllAds(false);
        }
        setCheckedAdvertisements(newCheckedUuids);
    };

    const handleDelete = () => {
        handleMultipleDelete(checkedAdvertisements);
    };

    const handleShowDeleteDialog = () => {
        if (checkedAdvertisements.length === 0) {
            return Toast.error("Please select an advertisement to delete.");
        }
        if (isFetching) {
            return Toast.error("No data selected yet");
        }
        setShowDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleEditAd = (ad) => {
        setAdToEdit(ad);
        setPageHeadingLabel("Edit Ad");
        setShowAdsTable(false);
        setShowAdForm(true);
    };

    const handleShowAdvertisementViewershipModal = (ad) => {
        setAdvertisementData(ad);
        setAdvertisementViewersModalVisibility(true);
    };

    const handleCloseAdvertisementViewershipModal = () => {
        setAdvertisementData(null);
        setAdvertisementViewersModalVisibility(false);
    };

    const handleShowAdvertisementClicksModal = (ad) => {
        setAdvertisementData(ad);
        setAdvertisementClicksModalVisibility(true);
    };

    const handleCloseAdvertisementClicksModal = () => {
        setAdvertisementData(null);
        setAdvertisementClicksModalVisibility(false);
    };

    return (
        <PageContainer>
            <AdContext.Provider value={{
                companyColors: companyColors,
                companyColorsReady: companyColorsReady,
                handleShowAdvertisementViewershipModal: handleShowAdvertisementViewershipModal,
                handleShowAdvertisementClicksModal: handleShowAdvertisementClicksModal
            }}>
                <div className="row" style={{ justifyContent: "space-between", maxWidth: "94.5rem", margin: "32px 0 20px 0" }}>
                    <div>
                        <Text weight="700" size="2.5em" color="#11141A" margin="0 0 8px 0" align="left">
                            {pageHeadingLabel}
                        </Text>
                        <div style={{ maxWidth: "94.5rem", justifyContent: "space-between", marginTop: "0" }}>
                            <PageParagraph style={{ fontSize: "0.8750em", marginBottom: "1em" }}>
                            Create ads to display directly within your app. Simply upload an image or video, add a headline, description, and a call-to-action button with a link.
                            Customise how often they&apos;re shown, and set specific start and end times.
                            Engage clients effectively by seamlessly integrating your promotional content into the Me Business app experience.
                            </PageParagraph>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignContent: "flex-end", flexDirection: "column" }}>
                        <AdDistributionBanner>
                            <div>
                                <div style={{ fontSize: "20px", fontFamily: "Roboto, Helvetica, sans-serif", marginBottom: "10px", fontWeight: "700", display: "flex", flexDirection: "row", justifyContent: "left", verticalAlign: "center", height: "24px" }} >
                                    Ad Distribution <img src={MegaphoneIcon} height="24px" width="27px" style={{ marginLeft: "0.5rem" }} />
                                </div>
                                <div style={{ width: "536px", fontSize: "14px", fontFamily: "Roboto, Helvetica, sans-serif", wordBreak: "break-word", fontWeight: "medium", flexWrap: "wrap" }}>
                                    All active ads will rotate evenly across the Quotes feature and Business Noticeboard, visible to employees, clients, or both—based on your selection. This ensures balanced exposure and optimal visibility for your selected audience.
                                </div>
                            </div>
                        </AdDistributionBanner>
                    </div>
                </div>
                {
                    showAdForm &&
                    <AdvertisingForm 
                        adToEdit={adToEdit}
                        fetchCompanyAds={fetchCompanyAds}
                        handleHideAdForm={handleHideAdForm}
                        setFilterOption={setFilterOption}
                        setAudienceOption={setAudienceOption}
                    />
                }

                {
                    showAdsTable &&
                    <AdvertisingTable
                        user={user}
                        advertisementsArray={advertisementsArray}
                        handleShowDeleteDialog={handleShowDeleteDialog}
                        checkedAds={checkedAdvertisements}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckAllAds={handleCheckAllAds}
                        checkAllAds={checkAllAds}
                        isFetching={isFetching}
                        filterByOption={filterOption}
                        audienceOption={audienceOption}
                        handleFilterChange={handleFilterChange}
                        handleAudienceChange={handleAudienceChange}
                        handleShowAdForm={handleShowAdForm}
                        handleEditAd={handleEditAd}
                    />
                }

                { showDeleteDialog && 
                    <DeleteDialog
                        showDeleteDialog={showDeleteDialog}
                        handleCloseDeleteDialog={handleCloseDeleteDialog}
                        handleDelete={handleDelete}
                        isMultipleDelete={checkedAdvertisements.length > 1}
                        isRequestingDeletion={isRequestingDeletion}
                    />
                }

                {
                    advertismentViewershipModalVisibility &&
                    <AdvertisingViewsModal
                        user={user}
                        adData={advertisementData}
                        showAdViewsModal={advertismentViewershipModalVisibility}
                        handleCloseAdViewsModal={handleCloseAdvertisementViewershipModal}
                    />
                }

                {
                    advertismentClicksModalVisibility &&
                    <AdvertisingClicksModal
                        user={user}
                        adData={advertisementData}
                        showAdClicksModal={advertismentClicksModalVisibility}
                        handleCloseAdClicksModal={handleCloseAdvertisementClicksModal}
                    />
                }
            </AdContext.Provider>
        </PageContainer>
    );
};

export default withRouter(Advertising);