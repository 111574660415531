import React, { useContext, useEffect, useState } from "react";
import { Dialog, Box } from "@material-ui/core/";

import { Header, StyledButton } from "./CreateChatModal";
import { ChatContext } from "../ChatContext";
import { LoadingIndicator, Text, FlexContainer } from "src/components";
import { FORM_TYPE_EDIT } from "src/constants/chat";
import Group from "src/scenes/Chat/components/create/Group";
import { useFetchUsersList } from "src/scenes/Chat/hooks";

const EditGroupChatModal = () => {

    const {
        useChatMessage: ctxUseChatMessage,
        useActiveChats: ctxUseActiveChats,
        useEditChat: ctxUseEditChat,
        useDeleteChat: ctxUseDeleteChat
    } = useContext(ChatContext);

    const {
        openedMatrixChat
    } = ctxUseChatMessage;

    const {
        selectedChatListTypeOrLeadershipUuid
    } = ctxUseActiveChats;

    const {
        setShowEditGroupChatModal,
        updateGroupChat,
        isUpdatingChat
    } = ctxUseEditChat;

    const {
        isDeletingChat,
        handleShowDeleteConfirmationModal
    } = ctxUseDeleteChat;

    /** List of all company users */
    const [usersList, setUsersList] = useState([]);

    /** List of users to be displayed in the form */
    const [searchFilteredUserList, setSearchFilteredUserList] = useState([]);

    const [formData, setFormData] = useState({
        employeeUuidsToChat: [],
        isClient: openedMatrixChat && openedMatrixChat.isClient,
        groupChatName: ""
    });

    const { isFetchingUsersList, fetchUsersList } = useFetchUsersList();

    useEffect(() => {
        const retrieveUsersList = async () => {
            const users = await fetchUsersList(selectedChatListTypeOrLeadershipUuid);
            setUsersList(users);
        };
        if (selectedChatListTypeOrLeadershipUuid) {
            retrieveUsersList();
        }
    }, [selectedChatListTypeOrLeadershipUuid]);

    useEffect(() => {
        setSearchFilteredUserList(usersList);
    }, [usersList]);

    const handleSearch = (searchKey) => {
        if (searchKey.length) {
            const filteredUsers = usersList.filter(user => 
                user.firstName.toLowerCase().includes(searchKey.toLowerCase())
                || user.lastName.toLowerCase().includes(searchKey.toLowerCase())    
            );
            setSearchFilteredUserList(filteredUsers);
        }
        else {
            setSearchFilteredUserList(usersList);
        }
    };

    const handleSelectedEmployeesToChat = (data) => setFormData({ ...formData, employeeUuidsToChat: data });

    const handleSetGroupChatName = (name) => setFormData({ ...formData, groupChatName: name });

    const handleSubmit = () => {
        if (!openedMatrixChat) {
            return;
        }
        updateGroupChat(openedMatrixChat.uuid, formData);
    };

    return (
        <React.Fragment>
            <Dialog open={true} onClose={() => setShowEditGroupChatModal(false)}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "95%",
                        maxWidth: "59.375rem",
                        height: "auto",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC",
                    },
                }}
            >
                <Header>
                    <Text color="#4B5155" size="1.125rem" weight="700">Edit Group Chat </Text>
                </Header>
                <Box sx={{ padding: "1.5625rem 3.0625rem 1.5625rem 2.375rem" }}>
                    { !isFetchingUsersList ?
                        <React.Fragment>
                            <Group
                                availableUsersList={usersList}
                                searchFilteredUserList={searchFilteredUserList}
                                formType={FORM_TYPE_EDIT}
                                handleSearch={handleSearch}
                                handleSelectedEmployeesToChat={handleSelectedEmployeesToChat}
                                handleSetGroupChatName={handleSetGroupChatName}
                            />
                            <FlexContainer direction="row" justifyContent="space-between">
                                { isUpdatingChat || isDeletingChat ?
                                    <>
                                        <FlexContainer flexGrow={1}></FlexContainer>
                                        <FlexContainer width="11.5rem" height="3.4375rem">
                                            <LoadingIndicator width="1rem" height="1rem" containerHeight="1rem" />
                                        </FlexContainer>
                                    </>
                                    :
                                    <>
                                        <StyledButton backgroundColor="#FF5353" color="#FFF"
                                            onClick={() => handleShowDeleteConfirmationModal(openedMatrixChat)}
                                        >
                                            Delete Group
                                        </StyledButton>
                                        <FlexContainer direction="row" justifyContent="flex-end">
                                            <StyledButton backgroundColor="#FFF" color="#000"
                                                border="1px solid #DBE5ED" onClick={() => setShowEditGroupChatModal(false)}
                                            >
                                                Cancel
                                            </StyledButton>
                                            <StyledButton backgroundColor="#006CFF" color="#FFF"
                                                marginLeft="1.3125rem"
                                                onClick={() => handleSubmit()}
                                            >
                                                Save Changes
                                            </StyledButton>
                                        </FlexContainer>
                                    </>
                                }
                            </FlexContainer>
                        </React.Fragment>
                        :
                        <LoadingIndicator />
                    }
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default EditGroupChatModal;