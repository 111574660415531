import React, { useContext, useEffect, useState } from "react";
import { Container, ChatBox, Header } from "src/scenes/Chat/components/styled/chatMessagesStyled";
import { Text, FlexContainer, LoadingIndicator } from "src/components";
import styled from "styled-components";
import UserAvatar from "src/scenes/Chat/components/UserAvatar";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { useGetLeadershipRoles } from "src/scenes/Chat/hooks/useGetLeadershipRoles";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import { isListTypeEmployeeOrClient } from "src/utils/helpers";
import NoActiveChats from "src/scenes/Chat/components/children/chatmessages/NoActiveChats";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";

const StyledText = styled(Text)`
    text-align: left;
    font-size: 1rem;
    color: #000000;
    font-weight: ${p => p.fontWeight || ""};
`;

const ContentContainer = styled.div`
    padding: 26px 42px;
    height: 100%;
    overflow: auto;
`;

const EmployeeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
`;

const EmployeeGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-auto-columns: 100%;
    overflow: auto;
    grid-template-columns: repeat(auto-fill, 100px);
`;

const LeadershipEmployees = (props) => {
    // just a friendly neighborhood comment telling you that `selectedChatListTypeOrLeadershipUuid` is a leadership role uuid at this point
    const { isClient } = useContext(UserContext);
    const currentUser = useContext(UserContext);
    
    const {
        useActiveChats: ctxUseActiveChats,
        usePresenceListener: ctxUsePresenceListener,
        useCreateChatModal: ctxUseCreateChatModal
    } = useContext(ChatContext);

    const { 
        selectedChatListTypeOrLeadershipUuid,
        selectedChatListTypeOrLeadershipUuidLabel,
        lookingForLeadershipToChat
    } = ctxUseActiveChats;

    const { 
        onlineUsersMatrixId
    } = ctxUsePresenceListener;

    const { 
        showCreateChatModalOrShowLeadershipEmployees,
        createChatWithLeadership
    } = ctxUseCreateChatModal;

    const [pageLoading, setPageLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [onlineEmployees, setOnlineEmployees] = useState([]);
    const [offlineEmployees, setOfflineEmployees] = useState([]);
    const { isFetchingLeadership, leadershipToShow, retrieveLeadershipEmployees } = useGetLeadershipRoles();
    const isUserLeaderInSelectedLeadershipRole = currentUser[UserKeys.LEADERSHIP_ROLES].some((item) => item.uuid === selectedChatListTypeOrLeadershipUuid);
    const [showOnlineEmployees, setShowOnlineEmployees] = useState(false);

    const [leadershipUuid, setLeadershipUuid] = useState(selectedChatListTypeOrLeadershipUuid);
    const [leadershipLabel, setLeadershipLabel] = useState(selectedChatListTypeOrLeadershipUuidLabel);

    useEffect(() => {
        if (isFetchingLeadership) {
            setPageLoading(true);
        }

        if (leadershipToShow) {
            setEmployees(leadershipToShow.employees);
            setPageLoading(false);
        }
        
    }, [leadershipToShow, isFetchingLeadership]);

    useEffect(() => {
        if (employees && employees.length > 0) {
            setOfflineEmployees(employees);
        }
    }, [employees]);

    useEffect(() => {
        if (lookingForLeadershipToChat !== null && !isListTypeEmployeeOrClient(lookingForLeadershipToChat.uuid)) {
            retrieveLeadershipEmployees(lookingForLeadershipToChat.uuid);
            setLeadershipUuid(lookingForLeadershipToChat.uuid);
            setLeadershipLabel(lookingForLeadershipToChat.name);
        }
    }, [lookingForLeadershipToChat]);

    useEffect(() => {
        if (onlineUsersMatrixId && onlineUsersMatrixId.length > 0) {
            setOnlineEmployees(!employees ? [] : employees.filter((employee) => employee.employeeDetails.matrixUserId.includes(onlineUsersMatrixId)));
            setOfflineEmployees(!employees ? [] : employees.filter((employee) => !employee.employeeDetails.matrixUserId.includes(onlineUsersMatrixId)));
            setShowOnlineEmployees(true);
        } else {
            setOfflineEmployees(employees);
            setShowOnlineEmployees(false);
        }
    }, [onlineUsersMatrixId]);

    const handleEmployeeSelection = (employee) => {
        createChatWithLeadership({
            employeeUuidsToChat: [employee.employeeUuid],
            groupChatName: "",
            isClient: isClient,
            leadershipRoleUuid: leadershipUuid
        });
    };

    const renderOfflineLeadershipEmployees = () => {
        return (
            <EmployeeGrid>
                { offlineEmployees.map(employee => 
                    <div key={employee.uuid}>
                        <UserAvatar width="90px" height="90px" hideInfo={true}
                            photo={employee.employeeDetails.profilePictureUrl}
                            firstName={employee.employeeDetails.firstName}
                            lastName={employee.employeeDetails.lastName}
                            statusBorder="3px solid #fff"
                            withShadow={true}
                            borderPixel="1px"
                            isOnline={false}
                            showPresenceStatus={true}
                            margin="0"
                            onClick={() => handleEmployeeSelection(employee)}
                        />
                        <Text weight="500" width="5.625rem" overflow="hidden" textOverflow="ellipsis" size="1rem" color="#000" margin="11px 0 0 0">{ employee.employeeDetails.firstName }</Text>
                    </div>
                )}
            </EmployeeGrid>
        );
    };

    const renderOnlineLeadershipEmployees = () => {
        return (
            <EmployeeGrid>
                {
                    onlineEmployees.map(employee => 
                        <div key={employee.uuid}>
                            <UserAvatar width="90px" height="90px" hideInfo={true}
                                photo={employee.employeeDetails.profilePictureUrl}
                                firstName={employee.employeeDetails.firstName}
                                lastName={employee.employeeDetails.lastName}
                                statusBorder="3px solid #fff"
                                withShadow={true}
                                borderPixel="1px"
                                margin="0"
                                isOnline={true}
                                showPresenceStatus={true}
                                onClick={() => handleEmployeeSelection(employee)}
                            />
                            <Text weight="500" width="90px" overflow="hidden" textOverflow="ellipsis" size="1rem" color="#000" margin="11px 0 0 0">{ employee.employeeDetails.firstName }</Text>
                        </div>
                    )
                }
            </EmployeeGrid>
        );
    };

    const showCreateChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        if (leadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        }

        if (leadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        }
        return false;
    };
    return (
        <React.Fragment>
            <Container>
                <ChatBox>
                    <Header>
                        <Text color="#4B5155" size="1.125rem" align="left" margin="0" weight="700">
                            { leadershipLabel }
                        </Text>
                    </Header>
                    <ContentContainer>
                        { !pageLoading && isUserLeaderInSelectedLeadershipRole && props.isChatListEmpty && 
                            <FlexContainer height="100%" justifyContent="center" alignItems="center">
                                <NoActiveChats
                                    showCreateChatButton={showCreateChatButton}
                                    permissions={currentUser[UserKeys.PERMISSIONS]}
                                    showCreateChatModalOrShowLeadershipEmployees={showCreateChatModalOrShowLeadershipEmployees }
                                />
                            </FlexContainer>
                        }
                        { pageLoading && <LoadingIndicator />}

                        { !isUserLeaderInSelectedLeadershipRole && !pageLoading ?
                            <>
                                <StyledText>
                                    Start a conversation by selecting an individual below.
                                </StyledText>
                                <StyledText fontWeight="700">
                                    Online
                                </StyledText>
                                <hr style={{ backgroundColor: "#DBE5ED", margin: "0 0 20px 0" }} />
                                { showOnlineEmployees && onlineEmployees.length > 0 ? 
                                    (<EmployeeContainer>
                                        {renderOnlineLeadershipEmployees()}
                                    </EmployeeContainer>) : 
                                    "There is no one online at this moment."
                                }
                                <br />
                                <StyledText fontWeight="700">
                                    Offline
                                </StyledText>
                                <hr style={{ backgroundColor: "#DBE5ED", margin: "0 0 20px 0" }} />
                                { employees.length !== 0 ? 
                                    (<EmployeeContainer>
                                        {renderOfflineLeadershipEmployees()}
                                    </EmployeeContainer>) :
                                    "No employees have been assigned to this leadership role yet."
                                }
                            </>
                            :
                            <></>
                        }
                    </ContentContainer>
                </ChatBox>
            </Container>
        </React.Fragment>
    );
};

export default LeadershipEmployees;