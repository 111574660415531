import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import AdvertisingContent from "./AdvertisingContent";
import PurpleLottieJson from "./../assets/purple-lottie.json";
import { FILTER_BY, AUDIENCE } from "../Advertising";

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    padding: 1.25rem 2.5rem;
`;

const UpperButtons = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 700;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
    margin-right: 0.625em;
`;

const DescriptionLabel = styled.div`
    color: #000000;
    text-align: center;
    font-size: 1rem;
`;

const InputSelector = styled.select`
    height: 2.4375rem !important;
    width: 12.5rem !important;
    font-size: 14px !important;
    border-radius: 7px;
    padding-left: 1em;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    background-color: #fff;
    border: 1px solid #000000;
    appearance: none;
    margin-right: 0.625em;
`;

const AdvertisingTable = ({
    advertisementsArray,
    handleShowDeleteDialog,
    checkedAds,
    handleCheckboxChange,
    handleCheckAllAds,
    checkAllAds,
    isFetching,
    filterByOption,
    audienceOption,
    handleFilterChange,
    handleAudienceChange,
    handleShowAdForm,
    handleEditAd
}) => {
    return (
        <>
            <UpperControls>
                <Grid container width="100%" direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Grid container direction="row" alignContent="center">
                            <Grid item xs={12} sm="auto" style={{ marginRight: "1rem" }} >
                                <Checkbox
                                    key="selectAll"
                                    readOnly={false}
                                    checked={checkAllAds}
                                    onClick={ () => handleCheckAllAds(advertisementsArray) }
                                />
                            </Grid>

                            <Grid item >
                                <InputSelector value={filterByOption} onChange={handleFilterChange}>
                                    <option value={FILTER_BY.ACTIVE_ADS}>Active Ads</option>
                                    <option value={FILTER_BY.SCHEDULED_ADS}>Scheduled Ads</option>
                                    <option value={FILTER_BY.COMPLETED_ADS}>Completed Ads</option>
                                </InputSelector>
                            </Grid>

                            <Grid item >
                                <InputSelector value={audienceOption} onChange={handleAudienceChange}>
                                    <option value={AUDIENCE.EMPLOYEE}>Employees</option>
                                    <option value={AUDIENCE.CLIENT}>Clients</option>
                                    <option value={AUDIENCE.EMPLOYEES_CLIENTS}>Employees & Clients</option>
                                </InputSelector>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                { checkedAds.length > 0 ?
                                    <UpperButtons backgroundColor="#ff7171" padding="0.625rem 0.938rem 0.625rem" onClick={() => handleShowDeleteDialog()}>Delete Selected Ads</UpperButtons>
                                    : <></>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Grid container direction="row" alignContent="center" justifyContent="flex-end">
                            <Grid item >
                                <UpperButtons style={{ width: "13.125rem", marginRight: "0", display: "block", justifyContent: "flex-end" }} onClick={() => handleShowAdForm() }>Create New Ad</UpperButtons>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </UpperControls>

            <div className="scrollable-table" style={{ maxWidth: "94.5rem", minHeight: "924px", padding: "1.5em", border: "1px solid #8291B2", backgroundColor: "#FFF" }}>
                { 
                    isFetching && 
                    <FlexContainer 
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "100%" }}>
                        <PurpleLoadingIndicator/>
                    </FlexContainer>
                }

                { 
                    !isFetching && 
                    <>
                        { advertisementsArray.length === 0 && <NoAdvertisementAvailable/> }
                        { advertisementsArray.length !== 0 && 
                        <FlexContainer direction="column" justifyContent="flex-start" style={{ minWidth: "1000px" }}>
                            <AdvertisingContent 
                                advertisingArray={advertisementsArray} 
                                checkedAds={checkedAds}
                                handleCheckboxChange={handleCheckboxChange}
                                handleEditAd={handleEditAd}
                            /> 
                        </FlexContainer>
                        }                                  
                    </>
                }
            </div>
        </>
    );
};

function NoAdvertisementAvailable() {
    return (
        <FlexContainer 
            direction="column"
            justifyContent="center"
            border="1px solid #8291B2"
            height="225px"
            style={{ borderRadius: "18px", boxShadow: "0px 5px 5px #00000026" }}
        >
            <DescriptionLabel>There are no active ads at the moment. Once you create your first ad, it will</DescriptionLabel>
            <DescriptionLabel>appear here in your active ads list and be displayed across the app&apos;s features.</DescriptionLabel>
        </FlexContainer>
    );
}

function PurpleLoadingIndicator() {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
}
export default AdvertisingTable;
