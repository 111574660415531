import React, { useContext } from "react";
import { FlexContainer, LottieAnimation, Text, Toast } from "src/components";
import { Container, Content, BackButton, ConfirmButton, TextLabel, StyledInput, SubText } from "./styled";
import ResetBackupAnimation from "./assets/reset.json";

import { usePasswordValidator } from "src/scenes/Chat/hooks";
import { UserContext } from "src/scenes/App/UserContext";
import { BackupKeyContext } from "../../BackupKeyContext";

const Reset = (props) => {
    const { firstName, lastName, email } = useContext(UserContext);
    const { closeBackupKeyForm } = useContext(BackupKeyContext);
    const { 
        password, setPassword, 
        confirmPassword, setConfirmPassword,
        handlePasswordValidation,
    } = usePasswordValidator();

    const handleSubmit = () => {
        try {
            handlePasswordValidation(firstName, lastName, email);
            props.handleBackupKeyPasswordCreation(password);
        } catch (error) {
            Toast.error(error.message);
        }  
    };

    return (
        <Container>
            <Content>
                <FlexContainer justifyContent="center" alignItems="center">
                    <LottieAnimation animationJsonData={ResetBackupAnimation} />
                    <Text size="1.25rem" weight="700" margin="0 0 14px 0">Reset Your Chat Backup Password</Text>
                    <Text size="1rem" margin="0 0 41px 0" style={{ maxWidth: "34.5rem" }}>
                    Forgot your password? No worries, you can set a new one. Keep in mind, 
                    setting a new password will secure future chat histories, but previous ones will not be recoverable.
                    </Text>
                    <TextLabel>Create Chat Backup Password</TextLabel>
                    <StyledInput placeholder="Create a new password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextLabel>Create Chat Backup Password</TextLabel>
                    <StyledInput placeholder="Re-enter your new password"
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <SubText>• Minimum 8 characters</SubText>
                    <SubText>• Include a mix of uppercase letters, lowercase letters, numbers, and symbols to enhance security.</SubText>
                </FlexContainer>
            </Content>
            <FlexContainer margin="21px 0 0 0" padding="0 0 55px 0"
                direction="row" tDirection="row" mDirection="row"
                justifyContent="flex-end"
            >
                <BackButton onClick={closeBackupKeyForm}>
                    Back
                </BackButton>
                <ConfirmButton onClick={handleSubmit} disabled={props.disableButtons}>
                    Confirm
                </ConfirmButton>
            </FlexContainer>
        </Container>
    );
};

export default Reset;