import React, { useState } from "react";
import { Dialog, Box, Divider } from "@material-ui/core/";
import styled from "styled-components";
import { Button, Text, LoadingIndicator, Toast, Modal } from "src/components";
import MultipleInvitesForm from "./MultipleInvitesForm";
import SingleInviteForm from "./SingleInviteForm";
import AppInviteMethod from "src/components/AppInviteMethod";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import InviteSuccessModal from "src/components/AppInvite/InviteSuccessModal";

const StyledText = styled(Text)`
    font-family: Roboto, Helvetica, sans-serif;
    text-align: left;
    padding: ${p => p.padding || 0};
    margin: ${p => p.margin || 0};
`;

const SendInviteFormModal = (props) => {
    const { showDialog, handleClose, users, token } = props;
    const [selectedMethod, setSelectedMethod] = useState("email");
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailInviteInProgress, setisEmailInviteInProgress] = useState(false);
    const [isSmsInviteInProgress, setIsSmsInviteInProgress] = useState(false);
    const [isEmailInvite200response, setIsEmailInvite200response] = useState(false);
    const [isSmsInvite200response, setIsSmsInvite200response] = useState(false);
    const [showInviteSuccessModal, setShowInviteSuccessModal] = useState(false);
    const [inviteSuccessType, setInviteSuccessType] = useState("email");
    const handleSwitchMethod = (value) => {
        setSelectedMethod(value);
    };

    const handleFormSubmit = async () => {
        setIsLoading(true);
        const data = {
            method: selectedMethod,
            users: users
           
        };
        await handleSendInvitation(data);
    };
    
    const sendInviteByEmail = (users) => {
        const url = `${API_URL}/company/sendInvites/email`;
        setisEmailInviteInProgress(true);
        setIsEmailInvite200response(false);
        return new Promise((resolve, reject) => {
            axios.post(url, { users }, { headers: { Authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.subject !== null && response.data.subject === "rate-limit") {
                        Modal.open("Email Rate-Limited", response.data.info);
                        reject(response.data.info);
                    } else {
                        setIsEmailInvite200response(true);
                        resolve(true);
                    }
                })
                .catch(error => {
                    reject(error); //re-throw
                })
                .finally(() => {
                    setisEmailInviteInProgress(false);
                });
        });
    };

    const sendInviteBySms = (users) => {
        const url = `${API_URL}/company/sendInvites/sms`;
        setIsSmsInviteInProgress(true);
        setIsSmsInvite200response(false);
        return new Promise((resolve, reject) => {
            axios.post(url, { users }, { headers: { Authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.subject !== null && response.data.subject === "rate-limit") {
                        Modal.open("Sms Rate-Limited", response.data.info);
                        reject(response.data.info);
                    } else {
                        setIsSmsInvite200response(true);
                        resolve(true);
                    }
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    setIsSmsInviteInProgress(false);
                });
        });
    };
    
    const handleSendInviteByEmail = async (stringifiedUsersArray) => {
        return await sendInviteByEmail(stringifiedUsersArray);
    };

    const handleSendInviteBySms = async (stringifiedUsersArray) => {
        return await sendInviteBySms(stringifiedUsersArray);
    };

    const openInviteSuccessModal = (type) => {
        setShowInviteSuccessModal(true);
        setInviteSuccessType(type);
    };

    const handleSendInvitation = async ({ method, users }) => {
        if (isEmailInviteInProgress || isSmsInviteInProgress) {
            return;
        }

        if (!users.length) {
            /** TODO: change `user` to be employee, client or license client */
            return Toast.error("No user found. Create at least one before sending email app invites.");
        }

        const stringifiedUsersArray = JSON.stringify(users);
        try {
            if (["email", "sms"].includes(method)) {
                method === "email" ? await handleSendInviteByEmail(stringifiedUsersArray) 
                    : await handleSendInviteBySms(stringifiedUsersArray);
            } else if (method === "both") {
                await trySendEmailAndFailSilently(stringifiedUsersArray);
                await trySendSmsAndFailSilently(stringifiedUsersArray);
            }

            openInviteSuccessModal(method);
            

        } catch (error) {
            Toast.error(error.response.data.error);
            handleClose(false);
        }
    };

    const trySendEmailAndFailSilently = async (stringifiedUsersArray) => {
        try {
            await handleSendInviteByEmail(stringifiedUsersArray);
        } catch (error) {
            Toast.error(error.response.data.error); //TODO: new mockup

            //scenario to when we needed to throw errors with Toast
            if (error.response.status !== 403) { //When error is not "You can send one email invite every 60 minutes"
                throw error; //re-throw
            }
        }
    };

    const trySendSmsAndFailSilently = async (stringifiedUsersArray) => {
        try {
            await handleSendInviteBySms(stringifiedUsersArray);
        } catch (error) {
            Toast.error(error.response.data.error); //TODO: new mockup

            //scenario to when we needed to throw errors with Toast
            // throw err;
        }
    };

    return (
        <>
            { showInviteSuccessModal ?
                <InviteSuccessModal
                    showDialog={showInviteSuccessModal}
                    handleClose={handleClose}
                    type={inviteSuccessType}
                    isEitherInviteSuccessful={isEmailInvite200response || isSmsInvite200response}
                />
                :
                <InviteFormModal
                    showDialog={showDialog}
                    handleClose={handleClose}
                    isLoading={isLoading}
                    users={users}
                    handleSwitchMethod={handleSwitchMethod}
                    handleFormSubmit={handleFormSubmit}
                />
            }

        </>
    );
};

const InviteFormModal = ({ showDialog, handleClose, isLoading, users, handleSwitchMethod, handleFormSubmit }) => {
    return (
        <Dialog open={showDialog} onClose={() => handleClose(false)}
            maxWidth="lg"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: "72rem",
                    height: "auto",
                    minHeight: "37.5rem",
                    borderRadius: 15,
                    overflow: "auto",
                },
            }}
        >
            { isLoading ? <div style={{ minHeight: "37.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}><LoadingIndicator /></div>
                :
                <React.Fragment>
                    <Box sx={{ margin: "3.4375rem 2.3125rem 1.40625rem 3.5625rem" }}>
                        <StyledText color="#11141A" size="2.5rem" weight="700">Send App Invite</StyledText>
                        <StyledText color="#000000" size="14px" margin="9px 0 0 0">
                            Please choose your preferred method of sending the app invitation to the designated user(s).
                        </StyledText>
                    </Box>
                    <Divider />
                    { users.length > 1 ? <MultipleInvitesForm users={users}/>
                        : <SingleInviteForm user={users[0]}/>
                    }
                    <Divider />

                    <Box sx={{ margin: "1.4375rem 3.5rem" }}>
                        <AppInviteMethod setAppInviteMethod={handleSwitchMethod} />
                    </Box>

                    <Divider />
                    <Box sx={{ 
                        margin: "0.875rem 3.5625rem 2.75rem 3.5625rem", 
                        display: "flex", justifyContent: "space-between", alignItems: "center"
                    }}
                    >
                        <Text color="#808080" size="14px" align="left" family="Roboto, Helvetica, sans-serif">
                            Kindly be advised that email invitations may be directed to the <br />
                            spam folder. It’s recommended to notify users in the event they do <br />
                            not receive the invitation.
                        </Text>
                        <div>
                            
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button 
                                border="1px solid #000"
                                color="#000"
                                marginRight="15px"
                                width="8.75rem"
                                onClick={() => handleClose(false)}
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                className="form-btns disabled"
                            >
                                Cancel
                            </Button>
                            <Button backgroundColor="#006CFF" color="#fff"
                                border="1px solid #006CFF"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none", fontFamily: "Roboto, Helvetica, sans-serif" }}
                                type="submit"
                                onClick={() => handleFormSubmit()}
                                className="form-btns disabled"
                            >
                                Send Invite
                            </Button>
                        </div>
                    </Box>
                </React.Fragment>

            }
            
        </Dialog>
    );
};

export default SendInviteFormModal;