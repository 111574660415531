import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Button, Text } from "src/components";

// Images
import FacebookIcon from "src/img/noticeboard/facebook.svg";
import LinkedInIcon from "src/img/noticeboard/linkedin.svg";
import InstagramIcon from "src/img/noticeboard/instagram.svg";

import AccountsLinked from "./AccountsLinked";
import NoAccountsLinked from "./NoAccountsLinked";

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    padding: 1em;
`;

const CloseButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 39px;
    width: 124px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const SocialMediaButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 51px;
    width: 250px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const AccountLinkingPage = ({
    user,
    toggleShowAccountLinkingPage,
}) => {
    const accountsLinked = true; //only placed temporarily, this will be changed to a useState once FB Integration works
    return (
        <>
            <UpperControls>
                <Grid item xs={6}>
                    <Grid container style={{ paddingLeft: "30px" }} direction="row" justifyContent="flex-start" alignItems="center">
                        <Text size="30px" align="left" weight="700" margin="0">
                            Get Started with Social Account Linking
                        </Text>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <CloseButton backgroundColor="#FFF" border="1px solid #000" color="#000" style={{ marginRight: "1rem", width: "12.5rem", display: "block" }} onClick={() => toggleShowAccountLinkingPage()}>Close</CloseButton>
                    </Grid>
                </Grid>
            </UpperControls>

            <Grid container direction="row" style={{ maxWidth: "94.5rem", minHeight: "50rem", border: "1px solid #8291B2", backgroundColor: "#FFF" }}>
                <Grid item xs={12} sm={6} style={{ borderRight: "1px solid #8291B2" }}> {/* Left Side */}
                    <div style={{ margin: "30px 60px 30px 60px" }}>
                        <Text size="20px" weight="700" margin="0 0 17px 0" align="left">Link Your Social Accounts with Ease</Text>
                        <Text size="16px" margin="0" align="left" width="512px">Connect your Facebook, Instagram, and LinkedIn accounts to seamlessly post to your Me Business Noticeboard and social media platforms at the same time.</Text>

                        <Text size="20px" weight="700" margin="43px 0 17px 0" align="left">Select Accounts to Link:</Text>

                        <SocialMediaButton>
                            <img src={FacebookIcon} alt="facebook-icon" style={{ marginRight: "12px" }}/>
                            Connect Facebook
                        </SocialMediaButton>

                        <Text size="16px" align="left" weight="300" width="512px" margin="12px 0 30px 0">
                            Link your Facebook account to share updates directly to your business page alongside your Me Noticeboard posts.
                        </Text>

                        <SocialMediaButton backgroundColor="#EE1C8E">
                            <img src={InstagramIcon} alt="facebook-icon" style={{ marginRight: "12px" }}/>
                            Connect Instagram
                        </SocialMediaButton>

                        <Text size="16px" align="left" weight="300" width="512px" margin="12px 0 30px 0">
                            Connect your Instagram account to post engaging updates and visuals to your followers while updating your Noticeboard.
                        </Text>

                        <SocialMediaButton backgroundColor="#0A69C5">
                            <img src={LinkedInIcon} alt="facebook-icon" style={{ marginRight: "12px" }}/>
                            Connect Linkedin
                        </SocialMediaButton>

                        <Text size="16px" align="left" weight="300" width="512px" margin="12px 0 30px 0">
                            Link your LinkedIn account to share professional updates seamlessly with your network and Noticeboard audience.
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} > {/* right side */}
                    { accountsLinked && <AccountsLinked />}
                    { !accountsLinked && <NoAccountsLinked />}
                </Grid>
            </Grid>
        </>
    );
};

export default AccountLinkingPage;
