import React from "react";
import styled from "styled-components";
import { Button } from "src/components";


import CorrectPng from "src/img/survey/correct.png";

const Container = styled.div`
    margin: 20px 26px 20px 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SocialMediaButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 51px;
    width: 250px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const CheckIcon = styled.img`
    display: flex;
    height: 34px;
    margin-left: 10px;
    justify-content: center;
    align-content: center;
    filter: hue-rotate(32.2deg) saturate(0.82) brightness(1.04);
`;

const DisconnectButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 39px;
    width: 124px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
    text-align: center;
`;

const SocialMediaRow = (props) => {
    const { businessName, buttonColor, logo } = props;
    return (
        <Container>
            <FlexRow>
                <SocialMediaButton backgroundColor={buttonColor}>
                    <img src={logo} alt="linkedin-icon" style={{ marginRight: "12px" }} />
                    {businessName}
                </SocialMediaButton>
                <CheckIcon src={CorrectPng} alt="check" />
            </FlexRow>
            <DisconnectButton backgroundColor="#fff" color="#000" border="1px solid #000">Disconnect</DisconnectButton>
        </Container>
    );
};

export default SocialMediaRow;
