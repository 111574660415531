import React, { useContext, useEffect, useState } from "react";

/**
 * fix for runtime error caused by crypto
 * see https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
 */
import { Buffer } from "buffer";
window.Buffer = Buffer;

import {
    useMatrixClient,
    useActiveChats,
    useMatrixChatList,
    useChatMessage,
    useCreateChatModal,
    useDeleteChat,
    useEditChat,
    useBackupForm,
    useTypingListener,
    usePresenceListener
} from "src/scenes/Chat/hooks";
import { UserKeys } from "src/constants/userDetails";
import { UserContext } from "src/scenes/App/UserContext";
import { ChatContext } from "./ChatContext";
import { BackupKeyContext } from "src/scenes/Chat/BackupKeyContext";

import { MainContainer, LeftContainer, RightContainer } from "src/scenes/Chat/components/styled/shared";
import ChatList from "./ChatList";
import ChatMessageContainer from "./ChatMessageContainer";
import CreateChatModal from "src/scenes/Chat/components/CreateChatModal";
import DeleteConfirmation from "src/scenes/Chat/components/DeleteConfirmation";
import ProfileSettingsModal from "src/scenes/Chat/components/ProfileSettingsModal";
import BackupKeyModal from "./components/keyBackup/BackupKeyModal";
import ChatEncryptionComingSoonModal from "./components/ChatEncryptionComingSoonModal";

const Chat = (props) => {

    const handleSettingCurrentUserStatus = (status) => {
        props.updateUserPresenceState(status);
    };

    const propagateUpdatedChat = apiResponseData => {
        updateOpenedMatrixChatWithFormData(apiResponseData);
        updateResponseDataInMatrixChatList(apiResponseData);
    };

    const userContext = useContext(UserContext);
    const { appFns } = userContext;
    const headers = { headers: { Authorization: "Bearer " + userContext[UserKeys.TOKEN] } };

    const [currentMatrixUserId, setCurrentMatrixUserUuid] = useState(userContext[UserKeys.MATRIX_USER_ID]);

    const { 
        matrixClient, 
        isInitialSyncComplete,
        runListener
    } = useMatrixClient(headers);

    const { 
        onlineUsersMatrixId,
        handleSetOwnUserPresence
    } = usePresenceListener(matrixClient, isInitialSyncComplete, headers, userContext, handleSettingCurrentUserStatus);
    
    const {
        activeChatListDetails,
        isFetchingActiveChats,
        businessBackgroundColor,
        selectedChatListTypeOrLeadershipUuid,
        handleChatListSelectChange,
        chatList,
        leadershipRoles,
        populateActiveChatListDetails,
        lookingForLeadershipToChat,
        setLookingForLeadershipToChat,
        selectedChatListTypeOrLeadershipUuidLabel,
        removeRoomInActiveChatListDetails
    } = useActiveChats(matrixClient);

    const {
        activeChatList,
        updateResponseDataInMatrixChatList,
        removeRoomInMatrixChatList,
        upsertRoomInMatrixChatList,
        insertChatCreateResponseToMatrixChats,
        placedRoomToIndexZeroOfMatrixChatList
    } = useMatrixChatList(chatList, selectedChatListTypeOrLeadershipUuid, matrixClient, populateActiveChatListDetails, removeRoomInActiveChatListDetails);

    const {
        openedMatrixChat,
        setOpenedMatrixChat,
        updateOpenedMatrixChatWithFormData,
        upsertRoomTimeline,
        isFetchingRoomTimeline,
        roomTimeline,
        toggleDropdown,
        setToggleDropdown,
        displayOtherUserProfileModal,
        displayProfileModal,
        closeProfileModal,
        profileModalIsShown,
        profileModalShowsCurrentUser,
        profileModalUser,
        profileModalUserLeadershipRoles,
        encryptionComingSoonModalIsShown,
        setEncryptionComingSoonModalIsShown
    } = useChatMessage(activeChatList, currentMatrixUserId);

    useEffect(() => {
        if (!isInitialSyncComplete || !matrixClient) {
            return;
        }

        const sessionUserId = matrixClient.getUserId();
        if (currentMatrixUserId !== sessionUserId) {
            setCurrentMatrixUserUuid(sessionUserId);
            appFns.updateUserContextProperties({ [UserKeys.MATRIX_USER_ID]: sessionUserId });
        }

        (async () => {
            await appFns.reinitialiseUser();
            await runBackupKeyRequirementChecks();
            runListener(matrixClient, removeRoomInMatrixChatList, upsertRoomInMatrixChatList, upsertRoomTimeline, placedRoomToIndexZeroOfMatrixChatList, openedMatrixChat, setOpenedMatrixChat);
        })();
    }, [isInitialSyncComplete, matrixClient]);

    const { 
        showCreateChatModalOrShowLeadershipEmployees, 
        chatToCreate,
        closeCreateChatModal,
        handleCreateChat,
        handleCreateChatResponse,
        isCurrentlyCreatingChat,
        createChatWithLeadership
    } = useCreateChatModal(leadershipRoles, insertChatCreateResponseToMatrixChats, setOpenedMatrixChat, setLookingForLeadershipToChat, handleChatListSelectChange);

    const {
        showEditGroupChatModal,
        setShowEditGroupChatModal,
        updateGroupChat,
        isUpdatingChat
    } = useEditChat(propagateUpdatedChat);

    const { 
        isDeletingChat,
        showConfirmationModal, 
        handleChatDeletion, 
        setShowConfirmationModal, 
        handleShowDeleteConfirmationModal 
    } = useDeleteChat(setShowEditGroupChatModal);

    const {
        handleShowBackupKeyModal,
        closeBackupKeyForm,
        runBackupKeyRequirementChecks,
        setUserHasBackupKeys,
        backupKeyChecksAreComplete,
        showBackupKeyForm,
        backupFormToShow
    } = useBackupForm(matrixClient, headers);
    
    const { 
        isTyping, 
        typingMember, 
        typingRoomId 
    } = useTypingListener(matrixClient);

    return (
        <>
            <ChatContext.Provider value={{
                headers: headers,
                currentMatrixUserId: currentMatrixUserId,
                matrixClient: matrixClient, //useMatrixClient
                isInitialSyncComplete: isInitialSyncComplete, //useMatrixClient
                useActiveChats: {
                    activeChatListDetails: activeChatListDetails,
                    isFetchingActiveChats: isFetchingActiveChats,
                    businessBackgroundColor: businessBackgroundColor,
                    selectedChatListTypeOrLeadershipUuid: selectedChatListTypeOrLeadershipUuid,
                    handleChatListSelectChange: handleChatListSelectChange,
                    chatList: chatList,
                    leadershipRoles: leadershipRoles,
                    lookingForLeadershipToChat: lookingForLeadershipToChat,
                    selectedChatListTypeOrLeadershipUuidLabel: selectedChatListTypeOrLeadershipUuidLabel,
                    setLookingForLeadershipToChat: setLookingForLeadershipToChat
                },
                useMatrixChatList: {
                    activeChatList: activeChatList,
                    updateResponseDataInMatrixChatList: updateResponseDataInMatrixChatList,
                    upsertRoomInMatrixChatList: upsertRoomInMatrixChatList
                },
                useChatMessage: {
                    openedMatrixChat: openedMatrixChat,
                    setOpenedMatrixChat: setOpenedMatrixChat,
                    updateOpenedMatrixChatWithFormData: updateOpenedMatrixChatWithFormData,
                    isFetchingRoomTimeline: isFetchingRoomTimeline,
                    roomTimeline: roomTimeline,
                    toggleDropdown: toggleDropdown,
                    setToggleDropdown: setToggleDropdown,
                    displayOtherUserProfileModal: displayOtherUserProfileModal,
                    displayProfileModal: displayProfileModal,
                    setEncryptionComingSoonModalIsShown: setEncryptionComingSoonModalIsShown
                },
                useCreateChatModal: {
                    showCreateChatModalOrShowLeadershipEmployees: showCreateChatModalOrShowLeadershipEmployees,
                    chatToCreate: chatToCreate,
                    closeCreateChatModal: closeCreateChatModal,
                    handleCreateChat: handleCreateChat,
                    handleCreateChatResponse: handleCreateChatResponse,
                    isCurrentlyCreatingChat: isCurrentlyCreatingChat,
                    createChatWithLeadership: createChatWithLeadership
                },
                useDeleteChat: {
                    isDeletingChat: isDeletingChat,
                    handleChatDeletion: handleChatDeletion, 
                    handleShowDeleteConfirmationModal: handleShowDeleteConfirmationModal,
                    showConfirmationModal: showConfirmationModal
                },
                useEditChat: {
                    showEditGroupChatModal,
                    setShowEditGroupChatModal,
                    updateGroupChat,
                    isUpdatingChat
                },
                useTypingListener: {
                    isTyping: isTyping, 
                    typingMember: typingMember, 
                    typingRoomId: typingRoomId
                },
                usePresenceListener: {
                    onlineUsersMatrixId: onlineUsersMatrixId,
                    handleSetOwnUserPresence: handleSetOwnUserPresence
                },
                useBackupForm: {
                    handleShowBackupKeyModal: handleShowBackupKeyModal,
                    closeBackupKeyForm: closeBackupKeyForm,
                    runBackupKeyRequirementChecks: runBackupKeyRequirementChecks,
                    setUserHasBackupKeys: setUserHasBackupKeys,
                    backupKeyChecksAreComplete: backupKeyChecksAreComplete,
                    showBackupKeyForm: showBackupKeyForm,
                    backupFormToShow: backupFormToShow
                }
            }} >
                <MainContainer> 
                    <LeftContainer>
                        { <ChatList /> }
                    </LeftContainer>
                    <RightContainer>
                        { <ChatMessageContainer /> }
                    </RightContainer>
                </MainContainer>
                {chatToCreate.showModal &&
                    <CreateChatModal />
                }
                {showConfirmationModal &&
                    <DeleteConfirmation
                        isDeletingChat={isDeletingChat}
                        showDialog={showConfirmationModal}
                        handleClose={() => setShowConfirmationModal(false)}
                        confirm={handleChatDeletion}
                    />
                }
                {profileModalIsShown &&
                    <ProfileSettingsModal
                        handleClose={closeProfileModal}
                        isCurrentUser={profileModalShowsCurrentUser}
                        userToShow={profileModalUser}
                        profileModalUserLeadershipRoles={profileModalUserLeadershipRoles}
                    />
                }
                { showBackupKeyForm && backupFormToShow &&
                    <BackupKeyContext.Provider value={{
                        backupFormToShow: backupFormToShow,
                        setUserHasBackupKeys: setUserHasBackupKeys,
                        closeBackupKeyForm: closeBackupKeyForm
                    }}>
                        <BackupKeyModal showDialog={showBackupKeyForm} />
                    </BackupKeyContext.Provider>
                }
                { encryptionComingSoonModalIsShown &&
                    <ChatEncryptionComingSoonModal />
                }
            </ChatContext.Provider>
        </>
    );
};

export default Chat;