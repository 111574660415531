import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
    background-color: ${ p => p.backgroundColor };
    box-shadow: 0px 5px 10px #00000033;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    width: 270px;
    text-align: left;
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #FFF;
    padding: 10px;
    height: 24px;
`;

const GoogleCardHeroContainer = styled.div`
    height: 70px;
    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const CardInformation = styled.div`
    padding: 9px 10px 0px 10px;

    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto new lines */

    //direct div container
    > div:nth-child(1) {
        width: 60%;
    }

    > div:nth-child(2) {
        width: 40%;
    }
    
    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const QRCodeContainer = styled.div`
    padding-top: 26px;
    padding-bottom: 26px;
    justify-items: center;

    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const QrCode = styled.div`
    width: 108px;
    height: 108px;
    border: 1px solid #FFF;
    border-radius: 5px;
`;

const DesignationGrid = styled.div`
    margin-bottom: 10px;
`;

const GoogleDesignationGrid = styled(DesignationGrid)`
    text-align: right;
    > div:nth-child(1) {
        margin-right: 10px;
    }
`;

const Designation = styled.div`
    font-size: 0.563rem;
    color: #FFF;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0.2em;
`;

const AssignedValue = styled(Designation)`
    font-weight: 300;
    text-transform: capitalize;
`;

const GoogleDesignation = styled(Designation)`
    font-weight: 400;
`;

const GoogleAssignedValue = styled(AssignedValue)`
    font-size: 0.813rem; //13px
    margin-bottom: 30px;
    font-weight: 500;
`;

const EmployeeGoogleCard = ({ isClient }) => {

    const backgroundColor = "#004165";
    const hasCardHeroImage = true;

    return (
        <CardContainer backgroundColor={backgroundColor}>
            <CardHeader>
                <div>
                    COMPANY LOGO
                </div>
            </CardHeader>
            
            <CardInformation>
                <div>
                    <DesignationGrid>
                        <GoogleDesignation>{ isClient ? "Client #" : "Employee #"}{"12345678"}</GoogleDesignation>
                        <GoogleAssignedValue>Lorem Ipsum</GoogleAssignedValue>
                    </DesignationGrid>
                    <DesignationGrid>
                        <Designation>TITLE</Designation>
                        <AssignedValue>Lorem Ipsum</AssignedValue>
                    </DesignationGrid>
                </div>
                
                <div style={{ alignContent: "end" }}>
                    <GoogleDesignationGrid>
                        <Designation>MEMBER SINCE</Designation>
                        <AssignedValue>31 Dec 9999</AssignedValue>
                    </GoogleDesignationGrid>
                </div>
            </CardInformation>
            <QRCodeContainer>
                <QrCode />
            </QRCodeContainer>
            { hasCardHeroImage && <GoogleCardHeroContainer /> }
        </CardContainer>
    );
};
export default EmployeeGoogleCard;