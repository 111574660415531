import { useState } from "react";
import { DIGITAL_MEMBER_CARD_PAGES } from "src/scenes/DigitalMemberCard/utils/enum";

const useRenderDigitalMemberCard = () => {
    const [renderPage, setRenderPage] = useState(DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD);
   
    return { 
        renderPage,
        setRenderPage
    };
};

export default useRenderDigitalMemberCard;