import React from "react";
import { Dialog } from "@material-ui/core/";
import { Button, FlexContainer, LoadingIndicator, Text } from "src/components";

const DeleteConfirmation = (props) => {
    const { showDialog, handleClose, confirm, isDeletingChat } = props;

    return (
        <Dialog open={showDialog} onClose={handleClose}
            maxWidth={false}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: 12,
                    padding: "2rem 3rem"
                },
            }}
        >
            { isDeletingChat ?
                <FlexContainer justifyContent="center" alignItems="center">
                    <Text>Please wait while we delete your chat...</Text>
                    <LoadingIndicator width="2rem" height="2rem" containerHeight="2rem" />
                </FlexContainer>
                :
                <FlexContainer>
                    Are you sure you want to delete this chat?
                    <FlexContainer mDirection="row" tDirection="row" direction="row" marginTop="2rem" justifyContent="center">
                        <Button backgroundColor="red" color="#FFF" marginRight="10px"
                            borderRadius="7px !important"
                            onClick={() => confirm()}
                        >Delete</Button>
                        <Button border="1px solid #000" color="#000"
                            borderRadius="7px !important"
                            onClick={handleClose}
                        >Cancel</Button>
                    </FlexContainer>
                </FlexContainer>
            }
            
        </Dialog>
    );
};

export default DeleteConfirmation;