import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, List, ListItem } from "@material-ui/core/";
import ActiveChats from "src/scenes/Chat/components/ActiveChats"; 
import UserAvatar from "src/scenes/Chat/components/UserAvatar";
import ListOfChats from "src/scenes/Chat/components/ListOfChats";
import SettingsIcon from "src/img/new/profile-settings.svg";
import { 
    CompanyLogo,
    Container, StyledInput,
    CompanyLogoContainer,
    Icon
} from "src/scenes/Chat/components/styled/chatListStyled";

import { UserContext } from "src/scenes/App/UserContext";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { UserKeys } from "src/constants/userDetails";
import { checkIfUserIsOnline } from "src/utils/helpers";

import {
    useChatListUnread
} from "src/scenes/Chat/hooks";
import { SetPresence } from "matrix-js-sdk";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { IsFeatureReady } from "src/constants/features";

const ChatList = () => {
    const userContext = useContext(UserContext);
    const { 
        useActiveChats: ctxUseActiveChats,
        useMatrixChatList: ctxUseMatrixChatList,
        usePresenceListener: ctxUsePresenceListener
    } = useContext(ChatContext);

    const {
        activeChatListDetails,
        isFetchingActiveChats,
        businessBackgroundColor,
        selectedChatListTypeOrLeadershipUuid,
        handleChatListSelectChange,
        leadershipRoles
    } = ctxUseActiveChats;
    const { 
        activeChatList
    } = ctxUseMatrixChatList;

    const { 
        onlineUsersMatrixId
    } = ctxUsePresenceListener;

    useEffect(() => {
        setUserChatsList(activeChatList);
    }, [activeChatList]);

    useEffect(() => {
        setActiveUsersListForPresence(onlineUsersMatrixId);
    }, [onlineUsersMatrixId]);
    
    const [activeUsersListForPresence, setActiveUsersListForPresence] = useState(null);

    const [userChatsList, setUserChatsList] = useState(null);
    const [searchedChatList, setSearchedChatList] = useState(null);
    const [isChatListSearched, setIsChatListSearched] = useState(false);
    const handleSearch = (e) => {
        const searchKey = e.target.value;
        if (searchKey.length) {
            setIsChatListSearched(true);
            const _userChatsList = userChatsList ?? [];
            const filteredChats = _userChatsList.filter(chat =>
                // searches for chats whose users' firstname or lastname matches the given search key
                chat.users.some(user => 
                    user.employeeDetails.firstName.toLowerCase().includes(searchKey.toLowerCase())
                        || user.employeeDetails.lastName.toLowerCase().includes(searchKey.toLowerCase())
                )    
            );
            setSearchedChatList(filteredChats);
        } else {
            setIsChatListSearched(false);
        }
    };
    
    return (
        <Container>
            <CompanyLogoElement backgroundColor={businessBackgroundColor} logo={userContext[UserKeys.COMPANY_LOGO_URL]} />
            <UserAvatarElement />
            <Box sx={{ margin: "0 0.9375rem" }}>
                <ActiveChatsElement
                    leadershipRoles={leadershipRoles}
                    selectedChatListTypeOrLeadershipUuid={selectedChatListTypeOrLeadershipUuid}
                    activeChatListDetails={activeChatListDetails}
                    isFetchingActiveChats={isFetchingActiveChats}
                    handleChatListSelectChange={handleChatListSelectChange} 
                />

                { !isFetchingActiveChats 
                    ? 
                    <StyledInput placeholder="Search people" onChange={(e) => handleSearch(e)} />
                    :
                    <Skeleton height={"2.5rem"} width={"100%"} style={{ margin: "1.4375rem 0 0.8rem 0" }} />
                }
            </Box>
            
            <Divider />
            <ListOfChatsElement searchedChatList={isChatListSearched ? searchedChatList : userChatsList} activeUsersListForPresence={activeUsersListForPresence} />
        </Container>
    );
};

const CompanyLogoElement = ({ backgroundColor, logo }) => {
    return (
        <CompanyLogoContainer backgroundColor={backgroundColor}>
            { logo && <CompanyLogo src={logo} /> }
        </CompanyLogoContainer>
    );
};

const UserAvatarElement = (props) => {
    const userContext = useContext(UserContext);
    const { 
        matrixClient,
        useChatMessage: ctxUseChatMessage,
        usePresenceListener: ctxUsePresenceListener,
        useBackupForm: ctxUseBackupForm
    } = useContext(ChatContext);

    const { 
        displayProfileModal,
        setEncryptionComingSoonModalIsShown
    } = ctxUseChatMessage;

    const { 
        onlineUsersMatrixId,
        handleSetOwnUserPresence
    } = ctxUsePresenceListener;

    const {
        handleShowBackupKeyModal
    } = ctxUseBackupForm;

    const onSettingsCogClicked = () => {
        if (IsFeatureReady.chatEncryption()) {
            handleShowBackupKeyModal(true);
        } else {
            setEncryptionComingSoonModalIsShown(true);
        }
    };

    const currentUserIsNewAndPresenceIsUnsetOrOverrrideOnline = userContext[UserKeys.CHAT_PRESENCE_OVERRIDE] === "" ? true : userContext[UserKeys.CHAT_PRESENCE_OVERRIDE] === SetPresence.Online;
    const amIOnline = userContext[UserKeys.CHAT_PRESENCE_OVERRIDE] === SetPresence.Offline ? false : currentUserIsNewAndPresenceIsUnsetOrOverrrideOnline || checkIfUserIsOnline(onlineUsersMatrixId, matrixClient.getUserId());
  
    return (
        <div style={{ display: "flex", justifyContent: "space-between", margin: "0.4375rem 0.9375rem 0 0.9375rem" }}>
            { userContext ? <UserAvatar firstName={userContext[UserKeys.FIRST_NAME]} borderPixel="2px"
                lastName={userContext[UserKeys.LAST_NAME]}
                photo={userContext[UserKeys.PROFILE_PICTURE_URL]}
                height="4rem" width="4rem" withShadow={true}
                onClickUserAvatarFunction={() => displayProfileModal(userContext, userContext.leadershipRoles, true)}
                isOnline={amIOnline}
                showPresenceStatus={true}
                handleSetOwnUserPresence={handleSetOwnUserPresence}
            /> : <Skeleton height="4rem" width="4rem" /> 
            }

            <div onClick={onSettingsCogClicked} style={{ width: "1.73rem", height: "1.73rem", textAlign: "right", cursor: "pointer" }}>
                <Icon width="1rem" height="1rem" color="#000"
                    src={SettingsIcon}
                />
            </div>
        </div>
    );
};

const ActiveChatsElement = ({ leadershipRoles, selectedChatListTypeOrLeadershipUuid, activeChatListDetails, isFetchingActiveChats, handleChatListSelectChange }) => {
    const userContext = useContext(UserContext);
    const { 
        matrixClient, 
        useCreateChatModal: ctxUseCreateChatModal
    } = useContext(ChatContext);
    const { 
        showCreateChatModalOrShowLeadershipEmployees
    } = ctxUseCreateChatModal;

    const {
        activeChatsTotalUnread,
    } = useChatListUnread(matrixClient, activeChatListDetails);
    
    return (
        <ActiveChats 
            currentUser={userContext} 
            showCreateChatModalOrShowLeadershipEmployees={showCreateChatModalOrShowLeadershipEmployees} 
            selectedChatListTypeOrLeadershipUuid={selectedChatListTypeOrLeadershipUuid} 
            handleChatListSelectChange={handleChatListSelectChange} 
            activeChatsTotalUnread={activeChatsTotalUnread} 
            leadershipRoles={leadershipRoles}
            activeChatListDetails={activeChatListDetails} 
            isFetchingActiveChats={isFetchingActiveChats}
        />
    );
};

const ListOfChatsElement = ({ searchedChatList, activeUsersListForPresence }) => {
    const { 
        useActiveChats: ctxUseActiveChats,
        useBackupForm: ctxUseBackupForm
    } = useContext(ChatContext);

    const {
        isFetchingActiveChats,
        setLookingForLeadershipToChat
    } = ctxUseActiveChats;

    const {
        backupKeyChecksAreComplete
    } = ctxUseBackupForm;

    return (
        <>
            { !isFetchingActiveChats && searchedChatList && backupKeyChecksAreComplete
                ? <ListOfChats 
                    searchedChatList={searchedChatList} 
                    activeUsersListForPresence={activeUsersListForPresence} 
                    setLookingForLeadershipToChat={setLookingForLeadershipToChat}
                /> 
                : <SkeletonListOfChats numberOfItems={10} />
            }
        </>
    );
};

const SkeletonListItem = () => {
    return (
        <>
            <Skeleton className={"inactive-chat cursor-pointer"} height={50} width={50} variant="circular" style={{ boxSizing: "border-box", padding: "0.625rem", borderRadius: "0.625rem", marginRight: "1rem" }} />
            <div style={{ width: "100%" }}>
                <Skeleton height={24} width={"80%"} />
                <Skeleton height={18} width={"60%"} />
            </div>
        </>
    );
};

const SkeletonListOfChats = ({ numberOfItems }) => {
    return (
        <List>
            {Array.from({ length: numberOfItems }).map((_, index) => (
                <ListItem key={index} style={{ display: "flex" }}>
                    <SkeletonListItem />
                </ListItem>
            ))}
        </List>
    );
};
export default ChatList; 