import { useState, useContext } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { Toast } from "src/components";
import { UserKeys } from "src/constants/userDetails";
import { UserContext } from "src/scenes/App/UserContext";
/**
 * custom hook for getting a list of active users to chat
 * @returns {{
 *      fetchUsersList: Function
 *      isFetchingUsersList: boolean
 * }}
 */
export const useFetchUsersList = () => {
    const userContext = useContext(UserContext);
    const headers = { headers: { "Authorization": `Bearer ${userContext[UserKeys.TOKEN]}` } };
    const [isFetchingUsersList, setIsFetchingUsersList] = useState(false);

    const fetchUsersList = async (selectedChatListTypeOrLeadershipUuid) => {
        setIsFetchingUsersList(true);
        try {
            const path = getPath(selectedChatListTypeOrLeadershipUuid);
            const response = await axios.get(`${API_URL}/chats/users/${path}`, headers);
            return response.data.users;
        } catch (error) {
            return Toast.error("Failed to retrieve list of users.");
        } finally {
            setIsFetchingUsersList(false);
        }
    };

    return {
        isFetchingUsersList,
        fetchUsersList
    };
};

const getPath = (selectedChatListTypeOrLeadershipUuid) => {
    return selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS ? "clientsList"
        : selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES ? "employeesList" : selectedChatListTypeOrLeadershipUuid;
};