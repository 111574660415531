import React from "react";
import { ROOM_ENCRYPTED, ROOM_MESSAGE, ROOM_MEMBER } from "src/constants/matrixEventTypes";
import styled from "styled-components";
import { isWithinTimeInterval } from "src/utils/dates";
import ChatBubble from "../components/ChatBubble";
import { doesObjectPropertyExist } from "src/utils/helpers";
import { UserKeys } from "src/constants/userDetails";
import { EventStatus } from "matrix-js-sdk/lib/models/event-status";

const RoomNotification = styled.div`
    font-size: 12px;
    color: gray;
    width: 100%;
    font-weight: 400;
    text-align: center;
    padding: 3px 0;
`;

/**
 * Custom hook for managing room timeline events. this can still be broken down further for SRP
 * @param {array} roomTimeline 
 * @returns {{
 *      renderTimelineEvent: Function
 *      isAMembershipEvent: Function
 *      isAValidMessageEvent: Function
 *      displayMembershipTimelineEvent: Function
 * }}
 */
export const useRoomTimeline = (roomTimeline, currentChat, currentMatrixUserUuid) => {

    const renderTimelineEvent = (matrixEvent, eventIndex) => {

        if (isAMembershipEvent(matrixEvent)) {
            return (
                <RoomNotification key={matrixEvent.getId()}>{ displayMembershipTimelineEvent(matrixEvent) }</RoomNotification>
            );
        } else if (isAValidMessageEvent(matrixEvent)) {
            return processMessageEvent(matrixEvent, eventIndex);
        }

        return "Unable to display message";
    };

    const fetchUserName = (matrixEvent) => {
        const userMatrixID = matrixEvent.getStateKey();
        const user = currentChat?.users.find((user) => user.matrixUserId === userMatrixID);
        return user !== undefined ? `${user.employeeDetails.firstName} ${user.employeeDetails.lastName}` : false;
    };

    const displayMembershipTimelineEvent = (matrixEvent) => {
        const content = matrixEvent.getContent();
        console.warn(`@${currentChat.ownerUuid}:memotivationapp.com`, `${matrixEvent.getSender()}`, content);
        const checkIfSenderIsChatOwner = `@${currentChat.ownerUuid}:memotivationapp.com` === matrixEvent.getSender() && content?.membership === "join";
        const amITheChatOwner = currentMatrixUserUuid === matrixEvent.getSender() && checkIfSenderIsChatOwner;
        const action = checkIfSenderIsChatOwner ? "created" : content?.membership === "join" ? "joined" : "left";
        const userName = fetchUserName(matrixEvent);
        if (currentChat.isGroupChat && !amITheChatOwner) {
            return userName !== false ? `${userName} has ${action} the chat.` : "";
        } else {
            return "";
        }
    };

    /**
     * @typedef {import('matrix-js-sdk').MatrixEvent} MatrixEvent
     * @param {MatrixEvent} matrixEvent 
     * @returns {React.ReactElement|string|undefined}
     */
    const processMessageEvent = (matrixEvent, eventIndex) => {
        const senderDetails = currentChat.users.find(user => user.matrixUserId === matrixEvent.getSender());
        if (!senderDetails) {
            console.warn("Sender details not found: ", senderDetails, matrixEvent, eventIndex);
            return "";
        }

        /**
         * see https://spec.matrix.org/v1.8/client-server-api/#local-echo for definition
         * used to determine whether we display the timestamp or a sending status */
        const isLocalEcho = [EventStatus.SENDING, EventStatus.ENCRYPTING, EventStatus.QUEUED].includes(matrixEvent.status);
        const message = matrixEvent.getContent().body;
        const isChatByCurrentUser = matrixEvent.getSender() === currentMatrixUserUuid;
        const previousSenderUserId = findPreviousSenderUserId(eventIndex);
        const nextSenderUserId = findNextSenderUserId(eventIndex);

        /**
         * Show timestamp only if previous message of the same user is more than 5 minutes or if it is the last message
         * in the thread sent by the same user (treated as the first of consecutive in the code)
         */
        const previousMessageTimestamp = eventIndex > 0 ? roomTimeline[eventIndex - 1].getTs() : null;
        const isWithin = isWithinTimeInterval(previousMessageTimestamp, matrixEvent.getTs(), 300);
        
        const isFirstOfConsecutive = matrixEvent.getSender() !== previousSenderUserId;
        const isLastOfConsecutive = matrixEvent.getSender() !== nextSenderUserId;

        const showTimeStamp = !isWithin;
        if (currentChat.matrixRoomId === matrixEvent.getRoomId()) {
            
            return (
                <div key={matrixEvent.getId()} data-custom-attribute={matrixEvent.getId()}>
                    <ChatBubble
                        key={matrixEvent.getId()}
                        message={message}
                        timestamp={matrixEvent.getTs()}
                        isChatByCurrentUser={isChatByCurrentUser}
                        photo={senderDetails.employeeDetails[UserKeys.PROFILE_PICTURE_URL]}
                        firstName={senderDetails.employeeDetails[UserKeys.FIRST_NAME]}
                        lastName={senderDetails.employeeDetails[UserKeys.LAST_NAME]}
                        isFirstOfConsecutive={isFirstOfConsecutive}
                        isLastOfConsecutive={isLastOfConsecutive}
                        isSending={isLocalEcho}
                        showTimeStamp={showTimeStamp}
                    />
                </div>
            );
        }
    };

    /**
     * also excludes message events with signs of redaction
     * event messages when redacted, does not change event type. a `redacted_because` property gets added instead
     * @param {object} event 
     * @returns boolean */
    const isAValidMessageEvent = (matrixEvent) => {
        return [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(matrixEvent.getType()) && !doesObjectPropertyExist(matrixEvent, "redacted_because");
    };
    
    const isAMembershipEvent = (matrixEvent) => {
        return matrixEvent.getType() === ROOM_MEMBER;
    };

    /**
     * Go back to the previous, or to the next event 
     * until we can get a valid message (only non-redacted messaging events)
     * and get the sender_id
     */
    const findPreviousSenderUserId = (startingIndex) => {
        if (startingIndex !== 0) {
            for (let i = startingIndex - 1; i >= 0;i--) {
                if (roomTimeline[i].getSender()
                    && !roomTimeline[i].isRedacted()
                    && [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(roomTimeline[i].getType())
                ) {
                    return roomTimeline[i].getSender();
                }
            }
        }
        return null;
    };

    const findNextSenderUserId = (startingIndex) => {
        if (startingIndex !== roomTimeline.length) {
            for (let i = startingIndex + 1; i < roomTimeline.length; i++) {
                if (roomTimeline[i].getSender()
                    && !roomTimeline[i].isRedacted()
                    && [ROOM_ENCRYPTED, ROOM_MESSAGE].includes(roomTimeline[i].getType())
                ) {
                    return roomTimeline[i].getSender();
                }
            }
        }
        return null;
    };

    return {
        renderTimelineEvent,
        isAMembershipEvent,
        isAValidMessageEvent,
        displayMembershipTimelineEvent
    };
};