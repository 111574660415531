import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
    background-color: ${ p => p.backgroundColor };
    box-shadow: 0px 5px 10px #00000033;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    width: 270px;
    text-align: left;
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #FFF;
    padding: 10px;
    height: 24px;
`;

const AppleCardHeroContainer = styled.div`
    height: 105px;
    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const CardInformation = styled.div`
    padding: 10px 10px 0px 10px;

    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto new lines */

    //direct div container
    > div:nth-child(1) {
        width: 60%;
    }

    > div:nth-child(2) {
        width: 40%;
    }

    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: ${ p => p.hasCardHeroImage ? "1px 0px 0px 0px" : "0px" };
`;

const QRCodeContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    justify-items: center;

    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const QrCode = styled.div`
    width: 108px;
    height: 108px;
    border: 1px solid #FFF;
    border-radius: 5px;
`;

const DesignationGrid = styled.div`
    margin-bottom: 10px;
`;

const Designation = styled.div`
    font-size: 0.563rem;
    color: #FFF;
    font-weight: 500;
    text-transform: ${p => p.textTransform || "uppercase" };
    margin-bottom: 0.5em;
`;

const AssignedValue = styled(Designation)`
    font-weight: 300;
    text-transform: capitalize;
`;

const EmployeeAppleCard = ({ isClient }) => {
    
    const backgroundColor = "#004165";
    const hasCardHeroImage = true;

    return (
        <CardContainer backgroundColor={backgroundColor}>
            <CardHeader>
                <div>
                    COMPANY LOGO
                </div>
                <div style={{ textAlign: "right" }}>
                    <Designation>{ isClient ? "CLIENT ID" : "EMPLOYEE ID" }</Designation>
                    <AssignedValue>#123456789</AssignedValue>
                </div>
            </CardHeader>
            { hasCardHeroImage && <AppleCardHeroContainer/> }
            <CardInformation hasCardHeroImage={hasCardHeroImage}>
                <div>
                    <DesignationGrid>
                        <Designation>NAME</Designation>
                        <AssignedValue>Lorem Ipsum</AssignedValue>
                    </DesignationGrid>
                    <DesignationGrid>
                        <Designation textTransform="capitalize">{ isClient ? "client type" : "TITLE" }</Designation>
                        <AssignedValue>{ isClient ? "Client Type value here" : "Title value here"}</AssignedValue>
                    </DesignationGrid>
                </div>
                
                <div>
                    <DesignationGrid>
                        <Designation>MEMBER SINCE</Designation>
                        <AssignedValue>31 Dec 9999</AssignedValue>
                    </DesignationGrid>
                </div>
            </CardInformation>
            <QRCodeContainer>
                <QrCode />
            </QRCodeContainer>
        </CardContainer>
    );
};
export default EmployeeAppleCard;