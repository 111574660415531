import React from "react";
import styled from "styled-components";
import { ListItemText, makeStyles } from "@material-ui/core";
import { StyledText } from "src/scenes/Chat/components/styled/shared";
import ListDate from "../ListDate";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    listItemSecondary: {
        color: "#000"
    }
}));

const ChatListText = styled(StyledText)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Content = styled.span`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: ${p => p.italic || ""}
`;

const ListText = (props) => {
    const { chatName, latestEvent, recentMessage, showTimestamp, matrixRoomLoading } = props;
    const classes = useStyles();
    const fontStyle = recentMessage === "No messages yet" ? "italic" : "";
    return (
        <ListItemText
            primary={
                <React.Fragment>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", textWrap: "nowrap" }}>
                        <ChatListText size="1rem" weight="700" flexShrink="1">
                            { chatName }
                        </ChatListText>
                         
                        {showTimestamp ? <ListDate timestamp={latestEvent && latestEvent.getTs()} align="right" /> : <></> }
                    </div>
                </React.Fragment>
            }
            secondary={ !matrixRoomLoading ? <Content italic={fontStyle}>{ recentMessage }</Content> : <Skeleton height={18} width={"60%"} /> }
            classes={{ secondary: classes.listItemSecondary }}
        />
    );
};

export default ListText;