import React from "react";
import styled from "styled-components";
import { Button, FlexContainer, Text } from "src/components";
import AccountLinkingIcons from "src/img/noticeboard/social media.svg";

const TextDescription = styled.div`
    text-align: left;
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: medium;
    color: #000000;
    width: 255px;
`;

const Card = styled.div`
    max-width: 544px;
    padding: 16px;
    max-height: 110px; 
    overflow-y: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8291B2;
    border-radius: 12px;
    object-fit: contain;
`;

const UpperButtons = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 2.4375rem;
    width: 160px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 700;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

const NoticeboardAccountLinkingCard = (props) => {
    const { toggleShowAccountLinkingPage } = props;
    return (
        <Card>
            <FlexContainer direction="row" tDirection="row" mDirection="row" overflowX="none" wrap="no-wrap">
                <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                    <img src={AccountLinkingIcons} alt="account-linking-banner" />
                </div>
                <div style={{ marginRight: "10px" }}>
                    <Text weight="700" size="20px" align="left" style={{ margin: "0 0 10px 0" }}>
                        Social Account Linking
                    </Text>
                    <TextDescription>
                        Link your social accounts to post on Me Business and social platforms at once.
                    </TextDescription>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <UpperButtons backgroundColor="#000" onClick={() => toggleShowAccountLinkingPage()}>
                        Linked Accounts
                    </UpperButtons>
                </div>
            </FlexContainer>
        </Card>
    );
};
export default NoticeboardAccountLinkingCard;