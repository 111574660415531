import { Button, ButtonWithIcon, FlexContainer } from "src/components";
import styled from "styled-components";

export const Container = styled.div`
    position: ${p => p.position || "relative"};
    overflow: hidden;
    height: calc(100vh - 135px);
    width: 100%;
    min-width: 470px;
    padding: 0 1rem .2rem 0;
`;

export const ChatBox = styled.div`
    position: relative;
    height: 100%;
    background: #F9FAFC;
    display: flex;
    flex-direction: column;
`;

export const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
    border-bottom: 1px solid #DBE5ED;
    border-radius: 1.25rem 1.25rem 0 0;
`;

export const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    overflow-y: auto;
    margin: 0.5rem;
    padding: 0 1.3125rem 0 1.3125rem;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        background: #F9FAFC;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #F9FAFC;
        border-radius: 0.375rem;
    }
`;

export const ChatInputContainer = styled(FlexContainer)`
    height: 5.0625rem;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #DBE5ED;
    padding: 0.625rem;
`;

export const ChatTextBox = styled.div`
    position: relative;
    height: 3.75rem;
    width: 100%;
    background: #fff;
    border-radius: 1.875rem;
    border: 1px solid #DBE5ED;
    padding: 0 0.6875rem 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.6875rem;
    box-sizing: border-box;
`;

export const ChatInput = styled.input`
    position: relative;
    height: 100%;
    font-family: Roboto, Helvetica, sans-serif;
    background: transparent;
    color: #7B8793;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    border: none;
    flex-grow: 1;
    box-sizing: border-box;

    :focus {
        outline: none;
    }

    ::placeholder {
        text-align: left;
        color: #7B8793;
    }
`;

export const SendIconContainer = styled.div`
    height: 2.875rem;
    width: 2.875rem;
    background: ${p => p.disabled ? "#AFBBC6" : "#001191"};
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const StartChatButton = styled(ButtonWithIcon)`
    width: 90%;
    max-width: 13.6875rem;
    height: 2.5rem;
    border-radius: 0.5rem !important;
    background: ${p => p.isAllowed ? "#006CFF" : "#DBE5ED"};
    color: #FFF;
    margin: ${p => p.margin ?? "0.5rem 0"};
    font-weight: 500;
    font-size: 0.875rem;
`;

export const EditChatButton = styled(Button)`
    color: ${p => p.enabled ? "#fff" : "#AFBBC6"};
    border: 0.0625rem solid #DBE5ED;
    border-radius: 0.625rem !important;
    background: ${p => p.enabled ? "#006CFF" : "#FFFFFF"};
    font-weight: 700;
    font-size: 1rem;
    max-width: 11.4375rem;
    height: 3.4375rem;
    box-sizing: border-box;
`;

export const Dropdown = styled.div`
    position: relative;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: 50%;
    border: 1px solid #DBE5ED;
    background: #FFF;
    margin-left: 1.125rem;
    cursor: pointer;
`;

export const Dot = styled.div`
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 50%;
    background: #000;
    margin: 0 2px;
`;

export const DropdownBox = styled.div`
    width: 12.9375rem;
    height: min-content;
    margin: 0 1rem;
    background: #FFF;
    border-radius: 0.5rem;
    border: 1px solid #E9EBEF;
    box-shadow: 0rem 0.3125rem 0.3125rem #00000026;
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    right: 1.5rem;
    top: 4rem;
`;