import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { findDiffInUnitsRoundingUp, getCompanySubscriptionEnd } from "src/utils/dates";
import Information from "./components/Information";
import PurchaseLicense from "./components/PurchaseLicense";
import "./css/ClientLicenseStyling.css";
import ClientLicenseModal from "./components/ClientLicenseModal";
import SendInviteFormModal from "src/components/AppInvite/SendInviteFormModal";

import SendRenewalForm from "./forms/SendRenewalForm";
import { ENVIRONMENT } from "../App/App";
import ClientLicenseTable from "./components/ClientLicenseTable";
import GetQRModal from "./components/GetQrModal";
import ClientDetailsForm from "./forms/ClientDetailsForm";
import UserFormSuccessModal from "../EmployeeList/components/UserFormSuccessModal";
import BankDetailsForm from "./forms/ClientLicenseBankDetailsForm";
import SalesAndPromotionHeader from "./components/SalesAndPromotionHeader";
import {
    Toast, FlexContainer, LoadingIndicator, Text, PageContainer
} from "src/components";

class ClientLicense extends Component {
    state = {
        fetching: 0,
        isSubscriptionExpired: false,
        unusedLicensesAvailable: 0, /** int | string = "Unlimited" */
        shortTermDurationUnit: "day",
        clientLicenseArray: [],
        
        enabledClientLicense: false,
        showPurchaseLicense: false,

        isShowEditForm: false,
        selectedLicenseEmployee: null,
        showSuccessModal: false,
        showClientForm: false,
        isClientFormEdit: false,
        clientFormData: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            clientType: "",
            licenseDuration: ""
        },
        isBankAccountDetailsEmpty: undefined,
        bankAccountDetails: {
            country: "",
            accountHolderName: "",
            bankName: "",
            accountNumber: "",
            swiftCode: "",
            bsbOrIban: "",
            invoiceOrStatementEmail: "",
            streetAddress: "",
            streetAddress2: "",
            city: "",
            state: "",
            addressCountry: "",
            postcodeOrZip: "",
        },
        showGetQrModal: false,
        isFreeTrialLicense: false,
        companyQrCode: null,
        earningPercent: 0,
        showSendInviteFormModal: false,
        inviteFormData: {
            users: []
        }
    };

    componentDidMount() {
        this.setState(state => ({
            fetching: state.fetching - 1
        }), () => {
            this.getCompanySubscriptionStatus();
            this.safeToInit();
        });
    }

    safeToInit = () => {
        this.setState(state => ({ 
            fetching: state.fetching + 1
        }));
        this.getCompanyClientLicense();
        this.populateBankAccountDetailsForm();
    };

    populateBankAccountDetailsForm = () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        axios.get(`${API_URL}/company/getBankAccountDetails`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            const bankAccount = res.data.bankAccountDetails;

            if (Array.isArray(bankAccount) && bankAccount.length === 0) {
                /* No record found */
                
                this.setState(({
                    isBankAccountDetailsEmpty: true
                }));
            }
            else { /* bankAccount - as Object */
                this.setState(prevState => ({
                    bankAccountDetails: {
                        country: bankAccount.countryCode,
                        accountHolderName: bankAccount.accountHolderName,
                        bankName: bankAccount.bankName,
                        accountNumber: bankAccount.accountNumber,
                        swiftCode: bankAccount.swiftCode,
                        bsbOrIban: bankAccount.bsbOrIban,
                        invoiceOrStatementEmail: bankAccount.email,
                        streetAddress: bankAccount.street,
                        streetAddress2: bankAccount.street2,
                        city: bankAccount.city,
                        state: bankAccount.state,
                        addressCountry: bankAccount.countryCodeAddress,
                        postcodeOrZip: bankAccount.postcodeOrZip,
                    },
                    isBankAccountDetailsEmpty: false
                }));
            }
        }).catch(error => {
            if (!error.response)
                return Toast.error(error.message);
        }).finally(error => {
            this.setState(state => ({
                fetching: state.fetching - 1
            }));
        });
    };

    getCompanySubscriptionStatus = () => {
        axios.get(`${API_URL}/company`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            let expiredDate = getCompanySubscriptionEnd(res.data.subscriptionStart, res.data.subscriptionPeriod, true);
            const calculationUnit = ENVIRONMENT.get() === ENVIRONMENT.PRODUCTION ? "day" : "hour";
            const remainingUnits = findDiffInUnitsRoundingUp(new Date(), expiredDate, calculationUnit);

            this.setState({ isSubscriptionExpired: remainingUnits < 1 });
        })
            .catch(err => {
                if (err.response) {
                    if (err.response.data.error.messages) {
                        return Toast.error(err.response.data.error.messages.toString());
                    }
                    return Toast.error(err.response.data.error.toString());
                } else {
                    return Toast.error(err.message);
                }
            });
    };

    getCompanyClientLicense = async () => {
        this.setState(state => ({
            fetching: state.fetching + 1
        }));

        return await axios.get(`${API_URL}/company/clientLicense`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        }).then(res => {
            this.props.setEnabledClientLicenseFlag(true);
            this.setState(state => ({ 
                enabledClientLicense: this.props.user.enabledClientLicense,
            }));
            
            const unusedLicensesAvailable = res.data.unusedLicensesAvailable;
            const shortTermDurationUnit = res.data.shortTermDurationUnit;
            const isFreeTrialLicense = res.data.isFreeTrialLicense;
            const companyQrCode = res.data.companyQrCode;
            const earningPercent = res.data.earningPercent;

            const allClients = res.data.clients;

            allClients && allClients.forEach(element => {
                if (element.startDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "2-digit", day: "2-digit" });
                    const date = new Date(element.startDate);                        
                    element.startDate = formatter.format(date);
                }

                if (element.endDate) {
                    const formatter = Intl.DateTimeFormat("default", { year: "numeric", month: "long", day: "2-digit" });
                    const date = new Date(element.endDate);         
                    const parts = formatter.formatToParts(date);
                    
                    const day = parts.find(part => part.type === "day").value;
                    const month = parts.find(part => part.type === "month").value;
                    const year = parts.find(part => part.type === "year").value;
                    
                    const formattedDate = `${day} ${month} ${year}`;
                    element.endDate = formattedDate;
                }
            });

            this.setState(state => ({
                unusedLicensesAvailable: isFreeTrialLicense ? "Unlimited" : unusedLicensesAvailable,
                shortTermDurationUnit: shortTermDurationUnit,
                clientLicenseArray: allClients,
                isFreeTrialLicense: isFreeTrialLicense,
                companyQrCode: companyQrCode,
                earningPercent: earningPercent
            }));
        }).catch(error => { /** Check axios.interceptors in App.js */
            this.props.setEnabledClientLicenseFlag(false);
            this.setState(state => ({ 
                enabledClientLicense: this.props.user.enabledClientLicense,
            }));

            if (error.response) {
                if (error.response && error.response.status !== 403) //Don't show that API error message on this screen
                    return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        }).finally(() => {
            this.setState(state => ({
                fetching: state.fetching - 1,
            }));
        });
    };
    
    showPurchaseLicense = (bool) => {
        if (!this.state.isSubscriptionExpired) {
            this.setState({
                showPurchaseLicense: bool,
                enabledClientLicense: !bool
            });
        }
        else {
            return Toast.error("Please renew your subscription before purchasing more licenses.");
        }
    };
    
    sendInvite = (licenseEmployee) => {
        this.setState(prevState => ({
            selectedLicenseEmployee: licenseEmployee  
        }), () => this.openSendInviteForm(licenseEmployee));
    };

    openSendInviteForm = (data) => {
        //from LicenseEmployee to Employee
        const employeesObject = data.map((user) => {
            return {
                uuid: user.employeeUuid,
                firstName: user.firstName,
                lastName: user.lastName,
            };
        });

        this.setState({
            inviteFormData: {
                users: employeesObject
            },
            showSendInviteFormModal: true
        });
    };

    handleCloseInviteFormModal = reload => {
        this.setState({ showSendInviteFormModal: false });
        if (reload) {
            this.getCompanyClientLicense();
        }
    };

    sendRenewalLink = (licenseEmployee) => {
        this.setState(prevState => ({
            selectedLicenseEmployee: licenseEmployee  
        }), () => this.sendRenewalModal());
    };

    sendRenewalModal = () => {
        const modalHeader = <>Send License Renewal Link</>;
        const sendRenewalForm = <>
            <SendRenewalForm user={this.props.user} selectedLicenseEmployee={this.state.selectedLicenseEmployee} getCompanyClientLicense={this.getCompanyClientLicense} />
        </>;
        return ClientLicenseModal.open(
            modalHeader,
            sendRenewalForm,
            false
        );
    };

    handleShowGetQrModal = () => {
        if (!this.state.companyQrCode || this.state.companyQrCode.length === 0) {
            return alert("QR Code is not yet implemented to this company");
        }

        this.setState({ 
            showGetQrModal: true,
        });
    };

    handleCloseGetQrModal = () => {
        this.setState({ 
            showGetQrModal: false,
        });
    };

    addClient = () => {
        this.setState({ 
            showClientForm: true,
            isClientFormEdit: false,
            clientFormData: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                clientType: "",
                licenseDuration: ""
            }
        });
    };

    updateClient = (licenseEmployee) => {
        this.setState((prevState) => ({
            clientFormData: {
                ...prevState.clientFormData,
                uuid: licenseEmployee.employeeUuid,
                firstName: licenseEmployee.firstName,
                lastName: licenseEmployee.lastName,
                email: licenseEmployee.email,
                phoneNumber: licenseEmployee.phoneNumber,
                clientType: licenseEmployee.clientType,
                licenseDuration: licenseEmployee.licenseType
            },
            showClientForm: true,
            isClientFormEdit: true
        }));
    };

    handleCloseSuccessModal = () => {
        this.setState({
            enabledClientLicense: false,
            showSuccessModal: false,
            clientFormData: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                clientType: "",
                licenseDuration: ""
            }
        }, () => this.getCompanyClientLicense());
    };

    enableSuccessModal = (data) => {
        this.setState({ 
            showSuccessModal: true,
            showClientForm: false,
            clientFormData: data
        });
    };

    refreshTableOnClientDelete = () => {
        this.setState({
            enabledClientLicense: false,
            showClientForm: false
        }, () => this.getCompanyClientLicense());
    };

    renderModalTitle = () => {
        return <div style={{ display: "flex", justifyContent: "center", margin: "0rem 0 2rem 0" }}>
            <Text color="#11141A" weight="700" size="2.5rem" align="center"
                width="100%" maxWidth="22.875rem"
            >
                { this.state.isClientFormEdit ? "Trial Client Updated Successfully!" : "New Trial Client Added Successfully!" }
            </Text>
        </div>;
    };

    render() {
        const { 
            fetching,
            clientLicenseArray,
            enabledClientLicense,
            showPurchaseLicense,
            unusedLicensesAvailable,
            shortTermDurationUnit,
            showClientForm,
            clientFormData,
            showSuccessModal,
            isClientFormEdit,
            isBankAccountDetailsEmpty,
            bankAccountDetails,
            showGetQrModal,
            isFreeTrialLicense,
            companyQrCode,
            earningPercent,
            showSendInviteFormModal,
            inviteFormData
        } = this.state;

        const { user } = this.props;
        
        if (fetching > 0) {
            return (
                <FlexContainer flexGrow="1" alignItems="center" justifyContent="center">
                    <LoadingIndicator />
                </FlexContainer>
            );
        }

        return (
            <>
                {showPurchaseLicense && (
                    <PurchaseLicense
                        user={user}
                        showPurchaseLicense={this.showPurchaseLicense}
                        shortTermDurationUnit={shortTermDurationUnit}
                        getCompanyClientLicense={this.getCompanyClientLicense}
                    />
                )}

                {enabledClientLicense && (                
                    <BankDetailsAndSalesAndPromotionsTable 
                        user={user}
                        addClient={this.addClient}
                        updateClient={this.updateClient}
                        unusedLicensesAvailable={unusedLicensesAvailable}
                        clientLicenseArray={clientLicenseArray}
                        showPurchaseLicense={this.showPurchaseLicense}
                        sendInvite={this.sendInvite}
                        editLicense={this.editLicense}
                        sendRenewalLink={this.sendRenewalLink}
                        isBankAccountDetailsEmpty={isBankAccountDetailsEmpty}
                        isRenewalEarningPercentGreaterThanZero={earningPercent > 0}
                        bankAccountDetails={bankAccountDetails}
                        handleShowGetQrModal={this.handleShowGetQrModal}
                        isFreeTrialLicense={isFreeTrialLicense}
                    />
                )}
                {showClientForm && (
                    <ClientDetailsForm
                        enableSuccessModal={(data) => this.enableSuccessModal(data)}
                        refreshTableOnClientDelete={this.refreshTableOnClientDelete}
                        client={clientFormData}
                        showDialog={showClientForm}
                        shortTermDurationUnit={shortTermDurationUnit}
                        handleClose={() => this.setState({ showClientForm: false })}
                        isClientFormEdit={isClientFormEdit}
                        token={user.token}
                        countryCode={user.countryCode}
                    />
                )}
                {showSuccessModal && (
                    <UserFormSuccessModal
                        showDialog={showSuccessModal}
                        handleClose={() => this.handleCloseSuccessModal()}
                        user={clientFormData}
                        title={this.renderModalTitle()}
                        isUserNewlyCreated={!isClientFormEdit}
                        openSendInviteForm={this.openSendInviteForm}

                    />
                )}
                {showGetQrModal && (
                    <GetQRModal
                        showGetQrModal={showGetQrModal}
                        handleCloseGetQrModal={this.handleCloseGetQrModal}
                        companyQrCode={companyQrCode}
                    />
                )}

                {showSendInviteFormModal && <>
                    <SendInviteFormModal
                        showDialog={showSendInviteFormModal}
                        handleClose={reload => this.handleCloseInviteFormModal(reload)}
                        users={inviteFormData.users}
                        token={user.token}
                    />
                </>}

                {!fetching && !showPurchaseLicense && !enabledClientLicense && !showClientForm && (
                    <Information />
                )}

            </>
        );
    }
}

const BankDetailsAndSalesAndPromotionsTable = (props) => {
    const [isShowGetStarted, setIsShowGetStarted] = useState(props.isBankAccountDetailsEmpty && props.isRenewalEarningPercentGreaterThanZero);
    
    return (
        <PageContainer>
            
            <SalesAndPromotionHeader 
                unusedLicensesAvailable={props.unusedLicensesAvailable}
                handleShowGetQrModal={props.handleShowGetQrModal}
            />

            {isShowGetStarted && <BankDetailsContainer 
                user={props.user}
                bankAccountDetails={props.bankAccountDetails}
                setIsShowGetStarted={setIsShowGetStarted}
            />
            } 
            {!isShowGetStarted && <ClientLicenseTable 
                user={props.user}
                addClient={props.addClient}
                updateClient={props.updateClient}
                    
                clientLicenseArray={props.clientLicenseArray}
                showPurchaseLicense={props.showPurchaseLicense}
                sendInvite={props.sendInvite}
                editLicense={props.editLicense}
                sendRenewalLink={props.sendRenewalLink}
                isBankAccountDetailsEmpty={props.isBankAccountDetailsEmpty}
                bankAccountDetails={props.bankAccountDetails}
                isFreeTrialLicense={props.isFreeTrialLicense}
            />
            
            }
        </PageContainer>
    );
};

function BankDetailsContainer(props) {
    const { user, bankAccountDetails, setIsShowGetStarted } = props;
    return (
        <FlexContainer style={{ backgroundColor: "#FFF", borderRadius: "12px", padding: "20px 40px 40px", width: "100%", maxWidth: "98.625rem" }}>
            <BankDetailsForm token={user.token} bankAccountDetails={bankAccountDetails} setIsShowGetStarted={setIsShowGetStarted} />
        </FlexContainer>
    );
}

export default withRouter(ClientLicense);