import React from "react";
import styled from "styled-components";
import CloseIcon from "src/img/CloseButton@2x.png";
import VisibilityModal from "./VisibilityModal";
import { ErrorText } from "src/components";

const AddPeopleButton = styled.div`
    width: ${p => p.buttonWidth ?? "340px"};
    height: ${p => p.buttonHeight ?? "43px"};
    text-align: center;
    font-size: 16px;
    font-weight: 200;
    font: normal normal medium 16px/21px Roboto;
    letter-spacing: 0px;
    color: white;
    font-family: Roboto, Helvetica, sans-serif;
    border-radius: 15px;
    box-shadow: 0px 5px 5px #0000001A;
    background: #000000 0% 0% no-repeat padding-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VisibilitySection = ({
    togglePollVisibilityModal,
    showPollVisibilityModal,
    handleSave,
    pollRespondentsUuids,
    clientsInPoll,
    employeesInPoll,
    employeesCountInPoll,
    clientsCountInPoll,
    modalTitle,
    pollVisibilityError,
    label,
    buttonWidth,
    buttonHeight,
    showButton,
    formType,
    userGroupType
}) => {
    return (
        <>
            { showButton &&
                <AddPeopleButton 
                    onClick={() => togglePollVisibilityModal()} 
                    buttonWidth={buttonWidth}
                    buttonHeight={buttonHeight}
                >
                    { label }
                    <img
                        src={CloseIcon}
                        alt="plusIcon"
                        style={{
                            transform: "rotate(45deg)",
                            height: "22px",
                            width: "22px",
                            marginLeft: "12px",
                        }}
                    />
                </AddPeopleButton>
            }
            { pollVisibilityError.length > 0 && <ErrorText>{pollVisibilityError}</ErrorText> }
            <VisibilityModal
                togglePollVisibilityModal={togglePollVisibilityModal}
                showPollVisibilityModal={showPollVisibilityModal}
                handleSave={handleSave}
                pollParticipantUuids={pollRespondentsUuids}
                clientsInPoll={clientsInPoll}
                employeesInPoll={employeesInPoll}
                employeesCountInPoll={employeesCountInPoll}
                clientsCountInPoll={clientsCountInPoll}
                modalTitle={modalTitle}
                formType={formType}
                userGroupType={userGroupType}
            />
        </>
    );
};

export default VisibilitySection;
