// assets
import IconRating from "src/img/survey/rating.png";
import IconSatisfaction from "src/img/survey/emotion.png";
import IconFeedBack from "src/img/survey/comment.png";

export const EnumSurveyType = {
    RATING: "Rating",
    SATISFACTION: "Satisfaction",
    YESNO: "Yes/No",
    FEEDBACK: "Feedback",
};

export const EnumUserGroup = {
    EMPLOYEES: "Employees",
    CLIENTS: "Clients",
    PROMOTION_USERS: "Promotion Users",
    ALL_USERS: "All Users",
    SELECTED_USERS: "Selected Users",
};

export const EnumSurveyShowFrequency = {
    EVERYTIME: "Everytime",
    HOURS24: "24 hours",
    HOURS48: "48 hours"
};

export const EnumSurveyShownIn = {
    QUOTE_SCREEN: "Quotes Screen",
    NOTICEBOARD_SCREEN: "Noticeboard Screen",
    GOAL_TRACK_SCREEN: "Goal Track Screen",
    ARTICLES_SCREEN: "Articles Screen",
    PLAYLISTS_SCREEN: "Playlists Screen",
    APP_SETTINGS_SCREEN: "App Settings Screen",
};

/** **/

export const FormStep = {
    PAGE_SURVEY_NAME: 1,
    PAGE_TYPE_OF_SURVEY: 2,
    PAGE_SURVEY_DURATION: 3,
    PAGE_USER_GROUP: 4,
    PAGE_SURVEY_QUESTIONS: 5,
    PAGE_SURVEY_FREQUENCY: 6,
    PAGE_OVERVIEW: 7,
    PAGE_CONGRATULATIONS: 8
};

export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_SURVEY_NAME: 1,
    EMPTY_SURVEY_TYPE: 2,
    EMPTY_USER_GROUP: 3,
    EMPTY_START_DATE: 4,
    EMPTY_FINISH_DATE: 5,
    EMPTY_QUESTION_ONE: 6,
    EMPTY_QUESTION_TWO: 7,
    EMPTY_QUESTION_THREE: 8,
    EMPTY_SHOW_IN: 9,
    EMPTY_SHOW_FREQUENCY: 10,

    DATE_START_DATE_INVALID: 11,
    DATE_FINISH_DATE_INVALID: 12,

    DATE_START_FORMAT_INVALID: 13,
    DATE_FINISH_FORMAT_INVALID: 14,
    
    DATE_RANGE_INVALID: 15,
    EMPTY_POLL_QUESTION: 16,
    EMPTY_POLL_OPTIONS: 17,
    EMPTY_POLL_PARTICIPANTS: 18,

    EMPTY_SELECTED_USERS: 16
};

export const returnErrorMessages = {
    [ErrorList.DATE_START_DATE_INVALID]: "Please provide a day in the future for start date",
    [ErrorList.DATE_FINISH_DATE_INVALID]: "Please provide a day in the future for finish date",
    [ErrorList.DATE_RANGE_INVALID]: "Please provide that start date is not greater finish date"
};

export const ErrorStrings = {
    
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_SURVEY_NAME]: "* Please enter a survey name ",
    [ErrorList.EMPTY_SURVEY_TYPE]: "* Please select a survey type ",
    [ErrorList.EMPTY_USER_GROUP]: "* Please select a user group ",
    [ErrorList.EMPTY_START_DATE]: "* Please enter a start date ",
    [ErrorList.EMPTY_FINISH_DATE]: "* Please enter a finish date ",
    [ErrorList.EMPTY_QUESTION_ONE]: "* Please enter a question for Question 1",
    [ErrorList.EMPTY_QUESTION_TWO]: "* Please enter a question for Question 2",
    [ErrorList.EMPTY_QUESTION_THREE]: "* Please enter a question for Question 3",
    [ErrorList.EMPTY_SHOW_IN]: "* Please select a screen to show the survey",
    [ErrorList.EMPTY_SHOW_FREQUENCY]: "* Please select how often to show the survey",

    [ErrorList.DATE_START_DATE_INVALID]: "* " + returnErrorMessages[ErrorList.DATE_START_DATE_INVALID],
    [ErrorList.DATE_FINISH_DATE_INVALID]: "* " + returnErrorMessages[ErrorList.DATE_FINISH_DATE_INVALID],
    [ErrorList.DATE_RANGE_INVALID]: "* Please provide that finish date is ahead from start date",
    [ErrorList.EMPTY_POLL_QUESTION]: "* Please provide a poll question",
    [ErrorList.EMPTY_POLL_OPTIONS]: "* Please provide at least two options",
    [ErrorList.EMPTY_POLL_PARTICIPANTS]: "* Please select at least 1 participant for this poll",
    [ErrorList.EMPTY_SELECTED_USERS]: "* Please select at least a single user from the Users List"
};

/** How It Works*/
export const HowItWorksStep = {
    PAGE_IN_APP: 1,
    PAGE_SURVEY_INSIGHT: 2,
};

export const surveyTypeOptions = [
    { value: EnumSurveyType.RATING, label: "User Rating Survey", icon: IconRating },
    { value: EnumSurveyType.SATISFACTION, label: "User Satisfaction Survey", icon: IconSatisfaction },
    { value: EnumSurveyType.YESNO, label: "User Yes/No Survey" },
    { value: EnumSurveyType.FEEDBACK, label: "User Feedback Survey", icon: IconFeedBack },
];

export const surveyUserGroupOptions = [
    { value: EnumUserGroup.ALL_USERS, label: "All Users" },
    { value: EnumUserGroup.CLIENTS, label: "Clients" },
    { value: EnumUserGroup.EMPLOYEES, label: "Employees" },
    { value: EnumUserGroup.SELECTED_USERS, label: "Selected Users" },
];
export const surveyShowInOptions = [
    { value: EnumSurveyShownIn.QUOTE_SCREEN, label: "Quotes Screen (Main Screen)" },
    { value: EnumSurveyShownIn.NOTICEBOARD_SCREEN, label: "Noticeboard Screen" },
    { value: EnumSurveyShownIn.GOAL_TRACK_SCREEN, label: "Goal Track Screen" },
    { value: EnumSurveyShownIn.ARTICLES_SCREEN, label: "Articles Screen" },
    { value: EnumSurveyShownIn.PLAYLISTS_SCREEN, label: "Playlists Screen" },
    { value: EnumSurveyShownIn.APP_SETTINGS_SCREEN, label: "App Settings Screen" },
];

export const surveyFrequencyOptions = [
    { value: EnumSurveyShowFrequency.EVERYTIME, label: "Every time the app is launched" },
    { value: EnumSurveyShowFrequency.HOURS24, label: "24 after user has seen the survey" },
    { value: EnumSurveyShowFrequency.HOURS48, label: "48 after user has seen the survey" },
];

export const surveyRatingRotation = {
    Five: 175,
    One: 5, 
};

export const FormType = {
    SURVEYS: "Surveys",
    POLLS: "Polls"
};

export const PollVisibilityType = {
    EMPLOYEES: "All Employees",
    CLIENTS: "All Clients"
};