import React from "react";
import { ErrorList, ErrorStrings } from "../../../components/Enums";
import { FormField, Text } from "src/components";
import Skeleton from "src/scenes/Statistics/components/Skeleton";

export const SurveyNameField = (props) => {
    const { survey, inputSurveyName, inputErrors, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const surveyNameError = getErrorStrings(ErrorList.EMPTY_SURVEY_NAME);

    return (
        <>
            <Text size="16px" align="left" weight="bold">
                Enter Survey Name
            </Text>
            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                    placeholderColor="#808080" border="1px solid #E9EBEF"
                    placeholderAlignment="left"
                    width="100%"
                    type="text"
                    style={{ textAlign: "left", margin: "0" }}
                    placeholder="Enter name here"
                    onChange={(event) => { inputSurveyName(event); }}
                    errors={surveyNameError}
                    maxLength="100"
                    value={survey.name}
                    showErrors={surveyNameError.length > 0}
                />
            }
        </>
    );
};
