import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Text } from "src/components";
import styled from "styled-components";
import { validImageTypes, validVideoTypes } from "../../components/Enums";

const ClickableDivBox = styled.div`
    width: 205px;
    height: 364px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E9EBEF;
    border-radius: 15px;
    background-color: #F8F8F8;
    cursor: pointer;
`;

const BrowseFileButton = styled.div`
    width: 140px;
    height: 37.5px;
    background-color: #000;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    color: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", sans-serif;
`;

const ChangeVideoAssetButton = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: black;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    width: 166px;
    height: 38px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
`;

const ImageBoxParent = styled.div`
    display: flex;
    position: relative;
    align-items: end;
    justify-content: center;
`;

const ImageBox = styled.img`
    width: 205px;
    height: 364px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    background-color: #E8E8E8;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
`;

const VideoBox = styled.video`
    width: 205px;
    height: 364px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    background-color: #E8E8E8;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
`;

const ChangeAsset = styled.div`
    margin-bottom: 20px;
    background-color: black;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    width: 166px;
    height: 38px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    z-index: 1;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
`;


const renderAssetFieldPreview = (verticalAsset, assetType) => {
    if (assetType === "image" || validImageTypes.includes(assetType)) {
        return (
            <ImageBoxParent>
                <ImageBox
                    src={verticalAsset}
                    alt="Preview"
                />
                <ChangeAsset>
                    Change Image/Video
                </ChangeAsset>
            </ImageBoxParent> 
        );
    }

    if (assetType === "video" || validVideoTypes.includes(assetType)) {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <ImageBoxParent>
                    <VideoBox
                        src={verticalAsset}
                        alt="Preview"
                        controls={true}
                        muted={true}
                    />
                </ImageBoxParent>
            </div>
        );
    }

    return (<>Unsupported Asset Type</>);
};


const AdVerticalImageUpload = (props) => {
    const { setFormVerticalAsset, setImageChanged, asset, setFormVerticalAssetType, assetType } = props;
    const [verticalAsset, setVerticalAsset] = useState(asset || null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (asset) {
            setVerticalAsset(asset);
        }
    }, [asset]);


    const handleFiles = (files) => {
        const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
        const targetAspectRatio = 9 / 16;

        if (verticalAsset) {
            setVerticalAsset(null);
            setFormVerticalAsset(null);
        }

        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 20MB. Please select a smaller file.");
                return;
            }

            const validateAspectRatio = (width, height) => {
                const actualAspectRatio = width / height;
                return Math.abs(actualAspectRatio - targetAspectRatio) < 0.01; // Allow slight tolerance
            };

            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    const img = new Image();
                    img.onload = () => {
                        if (!validateAspectRatio(img.width, img.height)) {
                            alert("Invalid aspect ratio. Media should have a 9:16 aspect ratio.");
                            return;
                        }
                        setVerticalAsset(reader.result);
                        setFormVerticalAsset(reader.result);
                        setFormVerticalAssetType("image");
                        setImageChanged(true);
                    };
                    img.src = reader.result;
                } else if (validVideoTypes.includes(file.type)) {
                    const video = document.createElement("video");
                    video.className = "videoPreview";
                    video.src = URL.createObjectURL(file);
                    video.crossOrigin = "anonymous";
                    video.muted = true;
    
                    video.addEventListener("loadeddata", () => {
                        if (!validateAspectRatio(video.videoWidth, video.videoHeight)) {
                            alert("Invalid aspect ratio. Media should have a 9:16 aspect ratio.");
                            return;
                        }
                        setFormVerticalAssetType("video");
                        setVerticalAsset(video.src);
                        setFormVerticalAsset(reader.result);
                        setImageChanged(true);
                    }, { once: true });
                } else {
                    alert("Invalid file type. Please select an image or video.");
                }
            };
            reader.readAsDataURL(file);
        } else {
            setImageChanged(false); // marking this as false makes it known that there is no image selected
        }
    };

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleInputChange = (event) => {
        handleFiles(event.target.files);
    };

    return (
        <div>
            <Text size="16px" align="left" weight="700">
                Upload Vertical Asset (Optional)
            </Text>
            <Text size="14px" color="#612684" align="left">
                Click browse file to upload your image or video.
            </Text>
            <Text size="14px" color="#808080" align="left" style={{ wrap: "auto", maxHeight: "48px", maxWidth: "290px" }}>
                Supported formats: JPG, PNG, MP4. Max size: 20MB. Aspect Ratio 9:16 - 1080px x 1920px
            </Text>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <ClickableDivBox
                        onClick={handleDropzoneClick}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg, .mp4"
                            ref={fileInputRef}
                            onChange={handleInputChange}
                            style={{ display: "none" }}
                        />
                        {verticalAsset ? (
                            renderAssetFieldPreview(verticalAsset, assetType)
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <BrowseFileButton>
                                    Browse File
                                </BrowseFileButton>
                            </div>
                        )}
                    </ClickableDivBox>
                    {
                        assetType === "video" &&
                        <ChangeVideoAssetButton onClick={() => handleDropzoneClick()}>
                            Change Image/Video
                        </ChangeVideoAssetButton>
                    }
                </div>
            </div>
        </div>
    );
};

export default AdVerticalImageUpload;
