import React from "react";
import styled from "styled-components";
import { Text } from "src/components";

// Images
import FacebookIcon from "src/img/noticeboard/facebook.svg";
import LinkedInIcon from "src/img/noticeboard/linkedin.svg";
import InstagramIcon from "src/img/noticeboard/instagram.svg";
import SocialMediaRow from "./AccountLinkingSocialMediaRow";

const SocialMediaDivider = styled.div`
    width: 100%;
    height: 30px;
    background-color: #F4F5F8;
    border: 1px solid #8291B2;
    text-align: left;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const AccountsLinked = () => {
    return (
        <div style={{ margin: "30px 0px 0px 0px" }}>
            <Text size="20px" weight="700" align="left" margin="0 0 20px 75px">Connected Accounts:</Text>
            <SocialMediaDivider>
                <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px">Facebook Accounts</Text>
                <Text size="15px" weight="500" align="right" margin="auto 26px auto 0">2/4</Text>
            </SocialMediaDivider>
            <SocialMediaRow logo={FacebookIcon} buttonColor="#1877F2" businessName="Profile/Business Name" status="Disconnect" />
            
            <SocialMediaDivider>
                <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px">Instagram Accounts</Text>
                <Text size="15px" weight="500" align="right" margin="auto 26px auto 0">1/4</Text>
            </SocialMediaDivider>
            <SocialMediaRow logo={InstagramIcon} buttonColor="#EE1C8E" businessName="Profile/Business Name" status="Disconnect" />
            
            <SocialMediaDivider>
                <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px">Linkedin  Accounts</Text>
                <Text size="15px" weight="500" align="right" margin="auto 26px auto 0">1/4</Text>
            </SocialMediaDivider>
            <SocialMediaRow logo={LinkedInIcon} buttonColor="#0A69C5" businessName="Profile/Business Name" status="Disconnect" />
            
        </div>
    );
};

export default AccountsLinked;

