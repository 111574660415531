import React, { Component } from "react";
import {
    FlexContainer,
    Text,
    Button,
    TextLabel,
    BackgroundContainer
} from "src/components";
import ImageSlider from "../components/ImageSlider";
import ColorPicker from "../components/ColorPicker";
import { CustomiseContext } from "../CustomiseContext";
import {
    Container,
    ColorButtonsContainer
} from "src/scenes/Customise/forms/SelectColor";
import { PhoneContainer } from "src/scenes/Customise/forms/UploadLogo";
import GridBackground from "src/img/GridBackground.png";
import UploadIconImg from "src/img/UploadIcon.png";
import styled from "styled-components";
import { AppScreens } from "./StartScreen";

const StartScreenContainer = styled.div`
    width: 280px;
    height: 50px;
    border: 1px solid #8291B2;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #0000001A;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 0.875em;
`;

class Finish extends Component {
    static contextType = CustomiseContext;

    state = { imageHash: Date.now() };

    render() {
        const {
            backGroundColor,
            secondaryColor,
            primaryColor,
            companyName,
            colourPalette,
            currentScreen,
            currentForm,
            screenIds,
            leftScreen,
            rightScreen,
            assetLogoUrl,
            assetIconUrl,
            iconStatus,
            employeeStartScreen,
            clientStartScreen
        } = this.context;

        return (
            <Container>
                <div>
                    <TextLabel
                        weight="700"
                        size="1.875em"
                        color="#2A2B2A"
                        margin="0 0 0.5rem"
                        flexGrow="1"
                    >
                        Customise Overview
                    </TextLabel>

                    <FlexContainer style={{ flexShrink: 0 }}>
                        <Text
                            size="1em"
                            align="left"
                            margin="0"
                        >
                            Here is an overview of your custom Me Business app. Refer to the <br />
                            device preview on the right for a visual representation of your custom <br />
                            app.
                        </Text>

                        <FlexContainer
                            direction="row"
                            gap="2.5em"
                        >
                            <BusinessLogoContainer assetLogoUrl={assetLogoUrl} />
                            <CustomAppIconContainer assetIconUrl={assetIconUrl} iconStatus={iconStatus} />
                        </FlexContainer>

                        <SelectAppColours
                            backGroundColor={backGroundColor}
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                        />

                        <StartingScreens
                            employeeStartScreen={employeeStartScreen}
                            clientStartScreen={clientStartScreen}
                        />

                        <hr style={{
                            width: "100%",
                            marginTop: "40px",
                            marginBottom: "15px",
                            backgroundColor: "#E9EBEF"
                        }}/>

                        <Button
                            width="112px"
                            height="39px"
                            backgroundColor="#000000"
                            color="white"
                            size="14px"
                            weight="500"
                            borderRadius="10px !important"
                            onClick={() => this.context.goToStep1()}
                        >
                            Edit
                        </Button>
                    </FlexContainer>
                </div>
                <PhoneContainer>
                    <ImageSlider
                        companyName={companyName}
                        logoTopOffset={45}
                        phoneFrameTopOffset={30}
                        currentScreen={currentScreen}
                        currentForm={currentForm}
                        screenIds={screenIds}
                        leftScreen={leftScreen}
                        rightScreen={rightScreen}
                        colourPalette={colourPalette}
                        assetLogoUrl={assetLogoUrl}
                    />
                </PhoneContainer>
            </Container>
        );
    }
}

class BusinessLogoContainer extends Component {
    render() {
        const timestamp = new Date().getTime();
        const { assetLogoUrl } = this.props;

        return (
            <div>
                <Text
                    size="1em"
                    align="left"
                    weight="700"
                    margin="26px 0 13px"
                >
                    Business Logo:
                </Text>

                <FlexContainer
                    width="213px"
                    height="64px"
                    alignItems="center"
                >
                    <BackgroundContainer
                        width="213px"
                        height="64px"
                        image={GridBackground}
                        style={{ border: assetLogoUrl.length > 0 ? "1px solid #8291B2" : "1px dashed #8291B2" }}
                    >
                        <img
                            style={{ maxWidth: "213px", maxHeight: "64px" }}
                            src={`${assetLogoUrl + "?" + timestamp}`}
                            alt={assetLogoUrl}
                        />
                    </BackgroundContainer>
                </FlexContainer>
            </div>
        );
    }
}

class CustomAppIconContainer extends Component {
    getIconStatusFormat(iconStatus) {
        if (iconStatus === "pending") {
            return <span style={{ color: "#006CFF" }}>Pending</span>;
        } else if (iconStatus === "active") {
            return <span style={{ color: "#36BE24" }}>Active</span>;
        } else {
            return <span >None</span>;
        }
    }

    render() {
        const { assetIconUrl, iconStatus } = this.props;
        const hasCompanyIcon = assetIconUrl && assetIconUrl.charAt(0) !== "?";
        const companyIcon = hasCompanyIcon ? assetIconUrl : UploadIconImg;
        const iconStatusFormat = this.getIconStatusFormat(iconStatus);
        const timestamp = new Date().getTime();

        return (
            <div>
                <Text
                    size="1em"
                    align="left"
                    weight="700"
                    margin="26px 0 13px"
                >
                    Custom App Icon: {iconStatusFormat}
                </Text>

                <FlexContainer
                    alignItems="center"
                    width="67px"
                    height="65px"
                    justifyContent="start"
                >
                    <BackgroundContainer
                        height="100%"
                        style={{
                            border: hasCompanyIcon ? "1px solid #8291B2" : "",
                            borderRadius: "10px",
                            overflow: "hidden"
                        }}
                    >
                        <img
                            style={{ maxWidth: "67px", maxHeight: "64px" }}
                            src={`${companyIcon + "?" + timestamp}`}
                            alt={"Finish Company Icon"}
                        />
                    </BackgroundContainer>
                </FlexContainer>
            </div>
        );
    }
}

class SelectAppColours extends Component {
    render() {
        const {
            backGroundColor,
            primaryColor,
            secondaryColor
        } = this.props;

        return (
            <>
                <Text
                    size="1em"
                    color="#2A2B2A"
                    align="left"
                    weight="700"
                    margin="26px 0 13px"
                >
                    Colour Selection:
                </Text>

                <ColorButtonsContainer>
                    <div style={{ marginRight: "20px" }}>
                        <ColorPicker
                            isPreview={true}
                            color={backGroundColor}
                            handleColorChange={this.handleBackgroundChange}
                            height="57px"
                            width="57px"
                        />
                    </div>

                    <div style={{ marginRight: "20px" }}>
                        <ColorPicker
                            isPreview={true}
                            color={primaryColor}
                            handleColorChange={this.handlePrimaryColorChange}
                            height="57px"
                            width="57px"
                        />
                    </div>

                    <ColorPicker
                        isPreview={true}
                        color={secondaryColor}
                        handleColorChange={this.handleSecondaryColor}
                        height="57px"
                        width="57px"
                    />
                </ColorButtonsContainer>
            </>
        );
    }
}

class StartingScreens extends Component {
    render() {
        const {
            employeeStartScreen,
            clientStartScreen
        } = this.props;

        return (
            <>
                <Text
                    size="1em"
                    color="#2A2B2A"
                    align="left"
                    weight="700"
                    margin="26px 0 13px"
                >
                    App Starting Screens:
                </Text>

                <StartScreenContainer style={{ marginBottom: "8px" }}>
                    <b>Employees:</b>&nbsp;<span style={{ fontWeight: "normal" }}>{employeeStartScreen === AppScreens.QUOTES ? `${employeeStartScreen} (Default)` : employeeStartScreen}</span>
                </StartScreenContainer>

                <StartScreenContainer>
                    <b>Clients:</b>&nbsp;<span style={{ fontWeight: "normal" }}>{clientStartScreen === AppScreens.QUOTES ? `${clientStartScreen} (Default)` : clientStartScreen}</span>
                </StartScreenContainer>
            </>
        );
    }
}

export default Finish;