import React from "react";
import { ErrorList, ErrorStrings } from "../../../components/Enums";
import { ErrorText, FlexContainer, Text, ValidatedDateInput } from "src/components";
import Skeleton from "src/scenes/Statistics/components/Skeleton";

export const SurveyDurationFields = (props) => {
    const { survey, inputStartDate, inputFinishDate, inputErrors, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
    const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);

    const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
    const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

    const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
    const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

    const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

    const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

    return (
        <>
            <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", gap: "1.5rem" }}>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                    <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                        Start Date
                    </Text>
                    {loading ?
                        <Skeleton height="50px" width="100%" animation="wave" />
                        :
                        <ValidatedDateInput
                            placeholder="Select date"
                            onChange={(event) => {inputStartDate(event);} }
                            errors={surveyStartDateError}
                            value={survey.startDate}
                            disabled={isEditSurveyForm && survey.status !== "Scheduled"}
                            showErrors={surveyStartDateError.length > 0}
                        />
                    }
                </div>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1 45%" }}>
                    <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                        Finish Date
                    </Text>
                    {loading ?
                        <Skeleton height="50px" width="100%" animation="wave" />
                        :
                        <ValidatedDateInput
                            placeholder="Select date"
                            onChange={(event) => {inputFinishDate(event);} }
                            errors={surveyFinishDateError}
                            value={survey.finishDate}
                            disabled={false}
                            showErrors={surveyFinishDateError.length > 0}
                        />
                    }
                </div>
            </FlexContainer>
            
            { hasAnySurveyDurationErrors &&
                <ErrorText errorMargin="0px">
                    {startDateInvalid.length > 0 ? startDateInvalid : null}
                    {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                    {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                    {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                    {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                </ErrorText>
            }
        </>
    );
};