import React, { useContext } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core/";
import { Text, Button } from "src/components";
import shield from "src/img/shield.png";
import { ChatContext } from "src/scenes/Chat/ChatContext";

const Icon = styled.img`
    height: ${p => p.height};
    width: ${p => p.width};
    color: ${p => p.color};
    cursor: pointer;
`;

const ChatEncryptionComingSoonModal = () => {

    const {
        useChatMessage: ctxUseChatMessage
    } = useContext(ChatContext);

    const {
        setEncryptionComingSoonModalIsShown
    } = ctxUseChatMessage;

    const dismissModal = () => {
        setEncryptionComingSoonModalIsShown(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={dismissModal}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: "20rem",
                        height: "auto",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC",
                        padding: "4rem"
                    },
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <Icon width="auto" height="8rem" src={shield} />
                    <Text color="#4B5155" size="1.125rem" weight="700" margin="2rem 0">
                        Encrypted Conversations Coming Soon!
                    </Text>
                    <Text margin="0" weight="400">
                        End-to-end encryption is on its way!
                    </Text>
                    <Text margin="1rem 0" weight="400">
                        Me Business takes your privacy and security seriously, and our encryption will meet the high
                        standards set by the USA Bureau of Industry and Security. Stay tuned for this important update!
                    </Text>
                    <Button
                        backgroundColor="#006CFF"
                        color="#FFF"
                        marginTop="2rem"
                        width="8.75rem"
                        borderRadius="0.4375rem !important"
                        onClick={dismissModal}
                    >
                        Done
                    </Button>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default ChatEncryptionComingSoonModal;