import React, { useState } from "react";
import styled, { css } from "styled-components";
import LeftArrow from "src/img/left-arrow.png";
import RightArrow from "src/img/right-arrow.png";
import FilteredPngStack from "./FilteredPngStack";
import { Text, Button } from "src/components";
import { Screens } from "../../components/Enums";

const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${p => p.direction || "row"};
    align-items: center;
    @media (max-width: 600px) {
        width: 100%;
        overflow-x: auto;
    }
`;

const WidthContainer = styled.div`
    box-sizing: ${p => p.boxSizing};
    display: flex;
    height: ${p => p.height || "100%"};
    width: ${p => p.width};
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: ${p => p.direction};
    margin-top: ${p => p.marginTop};
    min-height: ${p => p.minHeight};
    padding: ${p => p.padding};
    position: ${p => p.position};
`;

const Dot = styled.span`
    cursor: pointer;
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    ${p => p.isHighlighted && css`
        height: 10px;
        width: 10px;
    `}
`;

/**
 * @param {int} currentScreen
 * @param {int[]} screenIds
 * @return {Dot[]}
 */
const renderDotsForSlides = (currentScreen, screenIds) => {
    return screenIds.map((id, index) =>
        <Dot key={index} isHighlighted={id === currentScreen} />
    );
};

const ImageSlider = (props) => {
    const { imageWidth, companyColors, tempLogoUrl, assetLogoUrl, getLabelForPreview } = props;
    const [currentScreen, setCurrentScreen] = useState(Screens.QUOTES);
    const screenIds = [ Screens.QUOTES, Screens.FULLSCREEN_QUOTE, Screens.FULLSCREEN_VERTICAL_QUOTE, Screens.NOTICEBOARD ];
    const dots = renderDotsForSlides(currentScreen, screenIds);

    const getSlidesForScreen = () => {
        return [Screens.QUOTES, Screens.FULLSCREEN_QUOTES, Screens.FULLSCREEN_VERTICAL_QUOTE, Screens.NOTICEBOARD];
    };

    const leftScreen = () => {
        const screenIds = getSlidesForScreen(currentScreen);
        const lastScreenIndex = screenIds.length - 1;

        if (currentScreen <= 0) {
            setCurrentScreen(lastScreenIndex);
        } else {
            setCurrentScreen(currentScreen - 1);
        }
    };

    const rightScreen = () => {
        const screenIds = getSlidesForScreen(currentScreen);
        const lastScreenIndex = screenIds.length - 1;

        if (currentScreen < lastScreenIndex) {
            setCurrentScreen(currentScreen + 1);
        } else {
            setCurrentScreen(0);
        }
    };

    const hexToRgbaPercent = (hex) => {
        if (typeof hex !== "string" || hex.charAt(0) !== "#" || (hex.length !== 4 && hex.length < 7)) {
            return hex;
        }

        hex = hex.replace("#", "");
        let r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        let g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        let b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        return [ r / 255, g / 255, b / 255, 1];
    };

    const buildColourPalette = () => {
        return {
            primaryColor: hexToRgbaPercent(companyColors.primaryColor),
            secondaryColor: hexToRgbaPercent(companyColors.secondaryColor),
            backgroundColor: hexToRgbaPercent(companyColors.backgroundColor)
        };
    };

    return (
        <FlexContainer>
            <WidthContainer>
                <Button onClick={leftScreen} padding="0 10px 0 0">
                    <img src={LeftArrow} alt={LeftArrow} width="48px" />
                </Button>
            </WidthContainer>

            <div>
                <Text weight="700" color="black" size="16px">
                    {getLabelForPreview(currentScreen)}
                </Text>
                {/* Images container */}
                <WidthContainer width={ imageWidth || "100%" } direction="column">
                    <WidthContainer width={"302px"} height={"614px"} direction="column" padding="40px"
                        boxSizing="border-box" position="relative" minHeight="500px">

                        <FilteredPngStack
                            margin={"auto"}
                            colourPalette={buildColourPalette()}
                            currentScreen={currentScreen}
                            logoUrl={tempLogoUrl ? tempLogoUrl : assetLogoUrl}
                        />
                    </WidthContainer>
                    {/* Displayed dots below images*/}
                    <WidthContainer width="100%" height="auto" boxSizing="border-box" padding="8px">
                        <WidthContainer direction="row" width="100%">
                            {dots}
                        </WidthContainer>
                    </WidthContainer>
                </WidthContainer>
            </div>

            <WidthContainer>
                <Button onClick={rightScreen} padding="0 0 0 10px">
                    <img src={RightArrow} alt={RightArrow} width="48px" />
                </Button>
            </WidthContainer>
        </FlexContainer>
    );
};

export default ImageSlider;