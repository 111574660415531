import { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const getDigitalMemberCard = (token) => {
    const [digitalMembershipCards, setDigitalMembershipCards] = useState([]);
    const [isFetchingDigitalMemberCard, setIsFetchingDigitalMemberCard] = useState(false);
    
    const fetch = async () => {
        setIsFetchingDigitalMemberCard(true);
        try {
            const response = await axios.get(`${API_URL}/membershipCard/company/settings`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            setDigitalMembershipCards(response.data);
        } catch (error) {
            Toast.error("Unable to fetch digital membership cards. Please try again later or contact support if the issue persists.");
        } finally {
            setIsFetchingDigitalMemberCard(false);
        }
    };

    useEffect(() => {
        fetch();
    }, [token]);

    return { 
        digitalMembershipCards, 
        isFetchingDigitalMemberCard, 
        fetchDigitalMemberCard: fetch 
    };
};

export default getDigitalMemberCard;