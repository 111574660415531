import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import styled, { css } from "styled-components";
import { UserContext } from "src/scenes/App/UserContext";
import IconBusiness from "src/img/new/business_dashboard.svg";
import IconMyMeApp from "src/img/new/my_me_app.svg";
import IconMyUserLists from "src/img/new/my_user_lists.svg";
import IconSalesAndPromotions from "src/img/new/sales_and_promotions.svg";
import IconNoticeBoard from "src/img/new/noticeboard.svg";
import IconChat from "src/img/new/chat.svg";
import IconDirectory from "src/img/new/directory.svg";
import IconSurveys from "src/img/new/surveys.svg";
import IconPerks from "src/img/new/star.svg";
import IconDonations from "src/img/new/heart.svg";
import IconStatistics from "src/img/new/statistics.svg";
import IconAccountSettings from "src/img/new/account_settings.svg";
import IconLogout from "src/img/new/logout.svg";
import IconHelpCenter from "src/img/new/help_centre.svg";
import IconAdvertising from "src/img/advertising/Megaphone_white.svg";
import IconDigitalMemberCard from "src/img/new/member_card.svg";

import { Initials } from "src/components";

import "src/scenes/App/components/leftnav.css";
import * as PERMISSIONS from "src/constants/permissions";
import { capitalizeString, generateNameInitials, trimStringWithEllipsis } from "src/utils/helpers";
import { IsFeatureReady } from "src/constants/features";

const smallDevice = (...args) => css`
    @media (max-width: 1280px) {
        ${css(...args)}
    }
`;

const SideBar = styled.div`
    z-index: 999;
`;

const LoggedInLink = styled(Link)`
    font-family: Roboto, Helvetica, sans-serif;
    position: relative;
    display: ${ p => p.display };
    align-items: center;
    padding: ${ p => p.padding || "0 0 0 27px" };
    height: 55px;
    width: 243px;
    color: white;
    margin-top: ${p => p.margintop || "30px"};
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    ${p => Boolean(p.ishighlighted) && `
        background: #121EB1;
    `};
    ${p => p.isclickable === "true" || `
        pointer-events: none;
        cursor: default;
        color: #bdbdbd;
        opacity: 0.4;
    `};
`;

const LinkIcon = styled.img`
    height: 18px;
    width: 18px;
    padding-right: 12px;
    color: white;
    
`;

const HideTextOnSmall = styled.div`
    ${smallDevice`
        opacity: 0;
        position: absolute;
        left: -9999px;
        transition: all 0.3s ease-in-out;

        ${SideBar}:hover & {
            position: relative;
            left: 0;
            transform: translateX(0);
            opacity: 1;
        }
    `}

`;

function LeftNavigation(props) {
    const { location } = props;
    /**
     * destructuring with default values to prevent TypeError when UserContext becomes null or empty (ie logging out)
    */
    const {
        permissions = [],
        firstName = "",
        lastName = "",
        isCustomizeComplete = false,
        companyName = null
    } = useContext(UserContext) || {};

    /**
     * Using straight booleans as attribute values in components causes errors to be logged. A simple workaround is to
     * use integer values that will evaluate to truthy/falsy later. Discussed on a Stack Overflow thread:
     * https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
     * @param {boolean} boolValue
     * @returns {number}
     */
    const asInteger = (boolValue) => boolValue ? 1 : 0;

    const currentPageIs = (path) => {
        const SETTINGS_SUB_MENU = ["/setting", "/renewal", "/setting/business", "/setting/security", "/setting/billingHistory", "/setting/subscriptions", "/setting/payment", "/setting/renewalsAndEarnings"];
        
        if (path === "/setting") {
            return asInteger(SETTINGS_SUB_MENU.includes(location.pathname));
        } else {
            return asInteger(location.pathname === path);
        }
    };
    
    const greeting = (() => {
        let now = moment().format("HH");
        let greeting = "Good ";
        let splitAfternoon = 12;
        let splitEvening = 17;
        if (now >= splitAfternoon && now < splitEvening) {
            greeting += "afternoon";
        } else if (now >= splitEvening) {
            greeting += "evening";
        } else {
            greeting += "morning";
        }
        return greeting;
    });

    const logout = (() => {
        props.logout();
    });

    const openHelpCentre = () => {
        window.open("https://www.memotivationapp.com/help-centre", "_blank");
    };

    const displayLink = (allowed) => {
        // will show all links to account owner (some will be greyed out if company has not finished payment and set up)
        const userPermissions = permissions && permissions instanceof Array ? permissions : [];
        const isAccountOwner = userPermissions.includes(PERMISSIONS.ACCOUNT_OWNER_PERMISSION);
        return isAccountOwner || allowed.every((val) => userPermissions.includes(val));
    };

    const links = [];
    links.push({
        name: "Business Dashboard", icon: IconBusiness, route: "/active",
        isClickable: true, allowed: [PERMISSIONS.WEB_DASHBOARD_ACCESS]
    });
    links.push({
        name: "My Me App", icon: IconMyMeApp, route: "/customise",
        isClickable: Boolean(companyName !== null), allowed: [PERMISSIONS.MY_ME_APP]
    });
    links.push({
        name: "My User Lists", icon: IconMyUserLists, route: "/employList",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.USER_LISTS]
    });
    links.push({
        name: "Sales & Promotions", icon: IconSalesAndPromotions, route: "/clientLicense",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.SALES_AND_PROMOTIONS]
    });
    links.push({
        name: "Noticeboard", icon: IconNoticeBoard, route: "/noticeboard", 
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.NOTICEBOARD]
    });
    if (IsFeatureReady.chat()) {
        links.push({
            name: "Chat", icon: IconChat, route: "/chats",
            isClickable: Boolean(isCustomizeComplete),
            allowed: [PERMISSIONS.WEB_DASHBOARD_ACCESS]
        });
    }
    links.push({
        name: "Directory", icon: IconDirectory, route: "/directory",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.DIRECTORY]
    });
    links.push({
        name: "Surveys & Polls", icon: IconSurveys, route: "/survey",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.SURVEYS]
    });
    links.push({
        name: "Advertising", icon: IconAdvertising, route: "/advertising",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.ADVERTISING]
    });
    links.push({
        name: "Perks", icon: IconPerks, route: "/perks",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.PERKS_AND_DONATIONS]
    });
    links.push({
        name: "Donations", icon: IconDonations, route: "/donations",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.PERKS_AND_DONATIONS]
    });

    if (IsFeatureReady.digitalMemberCard()) {
        links.push({
            name: "Digital Member Cards", icon: IconDigitalMemberCard, route: "/digitalMemberCards",
            isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.MY_ME_APP] //TODO: confirmed permission for Digital Member Cards
        });
    }
    
    links.push({
        name: "Statistics", icon: IconStatistics, route: "/statistics",
        isClickable: Boolean(isCustomizeComplete), allowed: [PERMISSIONS.STATISTICS]
    });
    links.push({
        name: "Account Settings", icon: IconAccountSettings, route: "/setting",
        isClickable: true, allowed: [PERMISSIONS.WEB_DASHBOARD_ACCESS]
    });

    const navItems = () => {
        return links.map((link, index) => {   
            return (
                <LoggedInLink to={link.route} margintop="0" key={index}
                    ishighlighted={currentPageIs(link.route)}
                    isclickable={link.isClickable.toString()}
                    display={displayLink(link.allowed) ? "flex" : "none"}
                >
                    <LinkIcon src={link.icon}></LinkIcon>
                    <HideTextOnSmall>{ link.name }</HideTextOnSmall>
                </LoggedInLink>
            );
        });
    };

    const firstNameLastName = capitalizeString(firstName) + " " + (lastName ? capitalizeString(lastName) : "");
    
    return (
        <SideBar className="sidebar">
            <div className="container-test">
                <div className="greetings">
                    <Initials style={{ margin: "0 20px" }}>
                        <div style={{ margin: "0.75rem", display: "block" }}>{ generateNameInitials(firstName, lastName) }</div>
                    </Initials>
                    <HideTextOnSmall className="text">
                        {greeting()}, <br/> { trimStringWithEllipsis(firstNameLastName) }
                    </HideTextOnSmall>
                </div>
                <div className="nav-menu-text-wrapper">
                    <HideTextOnSmall className="nav-menu-text">Main Menu</HideTextOnSmall>
                </div>

                <div className="custom-scroll" style={{ flexGrow: 1 }}>
                    { navItems() }

                    <LoggedInLink to="?open=help_centre"
                        isclickable="true"
                        display="flex"
                        onClick={openHelpCentre}
                        margintop="0" 
                    >   
                        <LinkIcon src={IconHelpCenter}></LinkIcon>
                        <HideTextOnSmall>Help Centre</HideTextOnSmall>
                    </LoggedInLink>
                </div>

                <div style={{ flexGrow: 0 }}>

                    <LoggedInLink to="/login"
                        ishighlighted={0}
                        isclickable="true"
                        style={{ marginBottom: "60px" }}
                        onClick={logout}
                        display="flex"
                    >
                        <LinkIcon src={IconLogout}></LinkIcon>
                        <HideTextOnSmall>Log Out</HideTextOnSmall>
                    </LoggedInLink>
                    <HideTextOnSmall className="footer-text">
                        <a href="https://www.memotivationapp.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                        <a href="https://www.memotivationapp.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <a href="https://www.memotivationapp.com/privacy-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                    </HideTextOnSmall>
                    
                </div>
            </div>
        </SideBar>
    );
}

export default withRouter(LeftNavigation);