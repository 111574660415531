import React, { useState } from "react";
import styled from "styled-components";
import { Typography, MenuItem, ClickAwayListener } from "@material-ui/core/";
import Chevron from "src/img/new/arrow-presence.svg";
import { generateNameInitials, trimStringWithEllipsis } from "src/utils/helpers";
import { SetPresence } from "matrix-js-sdk";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledUserAvatar = styled.div`
    position: relative;
    border-radius: 50%;
    margin: ${p => p.margin || "0 0.6875rem 0 0"};
    border: ${p => p.borderPixel || "1px"} solid #FFFFFF;
    color: #6F6F6F;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 1rem;
    background: #D6D6D6;
`;

const StatusDot = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1rem;
    width: 1rem;
    border: ${ p => p.border || "1px solid #fff" };
    background: ${p => p.background || "#76C00D" };
    border-radius: 50%;
    transform: translate(10%, 15%);
    box-sizing: border-box;
`;

const UserAvatar = (props) => {
    const { 
        height, width, 
        borderPixel, 
        margin, 
        onClickUserAvatarFunction, 
        isOnline, 
        showPresenceStatus,
        handleSetOwnUserPresence,
        ...styleProps
    } = props;
    const [ showUserStatusDropdown, setShowUserStatusDropdown ] = useState(false);

    const PRESENCE_STATUS = { ONLINE: SetPresence.Online, OFFLINE: SetPresence.Offline };

    const setUserStatus = (status) => {
        handleSetOwnUserPresence(status);
        setShowUserStatusDropdown(false);
    };

    return (
        <Wrapper {...styleProps}>
            <StyledUserAvatar borderPixel={borderPixel} margin={margin}
                style={{ 
                    height, width,
                    boxShadow: props.withShadow ? "0px 3px 6px #00000029" : "none",
                    cursor: onClickUserAvatarFunction ? "pointer" : "default",
                }}
                onClick={onClickUserAvatarFunction}
            >
                { props.photo ? 
                    <img src={props.photo} alt="profile"
                        style={{ height, width, borderRadius: "50%", border: `${borderPixel} solid #FFF`,
                            boxSizing: "border-box",
                            boxShadow: props.withShadow ? "0px 3px 6px #00000029" : "none",
                            objectFit: "cover"
                        }}
                    /> 
                    : generateNameInitials(props.firstName, props.lastName) }
                { showPresenceStatus && <StatusDot background={isOnline ? "#76C00D" : "#AFBBC6"}></StatusDot>}
            </StyledUserAvatar>
            <div>
                { !props.hideInfo &&
                    <React.Fragment>
                        <Typography variant="subtitle1" 
                            style={{ fontWeight: 700, fontSize: "1.13rem", textTransform: "capitalize" }}
                        >
                            { trimStringWithEllipsis(props.firstName + " " + props.lastName) }
                        </Typography>
                        <Typography variant="subtitle2"
                            style={{ color: (isOnline ? "#76C00D" : "#AFBBC6"), fontWeight: 700, fontSize: "0.8125rem", marginTop: "-0.5rem" }}
                            onClick={() => setShowUserStatusDropdown(!showUserStatusDropdown)}
                        >
                            {isOnline ? "Online" : "Offline"}
                            <span style={{ color: "#4B5155", marginLeft: 5, cursor: "pointer", fontSize: "0.56rem" }}>
                                <img src={Chevron} alt="Profile" style={{ width: "0.5rem", height: "0.5rem" }}/>
                            </span>
                        </Typography>
                        { showUserStatusDropdown &&
                            <ClickAwayListener onClickAway={() => setShowUserStatusDropdown(false)}>
                                <div style={{ position: "absolute", zIndex: "10", backgroundColor: "#fff", borderRadius: "7px", boxShadow: "0px 3px 6px #00000029" }}>
                                    <MenuItem onClick={() => setUserStatus(PRESENCE_STATUS.ONLINE)}> Online</MenuItem>
                                    <MenuItem onClick={() => setUserStatus(PRESENCE_STATUS.OFFLINE)}> Offline</MenuItem>
                                </div>
                            </ClickAwayListener>
                        }
                    </React.Fragment>
                }
            </div>
        </Wrapper>
    );
};

export default UserAvatar;