import React, { useContext, useState, useEffect } from "react";
import { Text } from "src/components";
import { StartChatButton } from "src/scenes/Chat/components/styled/chatMessagesStyled";
import GroupChatIcon from "src/img/new/new-group-chat.svg";
import SingleChatIcon from "src/img/new/new-1-on-1-chat.svg";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS, GROUP_CHAT, ONE_ON_ONE_CHAT } from "src/constants/chat";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed, CREATE_GROUP_CHAT } from "src/constants/permissions";

const NoActiveChats = (props) => {
    const userContext = useContext(UserContext);
    const { selectedChatListTypeOrLeadershipUuid, showCreateChatModalOrShowLeadershipEmployees, permissions } = props;
    const [isAllowedToShowCreateChatButtons, setIsAllowedToShowCreateChatButtons] = useState(false);
    /**
     * 
     * @param {string | undefined} selectedChatListTypeOrLeadershipUuid 
     * @returns boolean
     */
    const isCreateChatButtonShowable = (selectedChatListTypeOrLeadershipUuid) => {
        const userPermissions = userContext[UserKeys.PERMISSIONS];
        const userLeadershipRoles = userContext[UserKeys.LEADERSHIP_ROLES];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            //leadershipRole

            // Check if selectedChatListTypeOrLeadershipUuid exists in userLeadershipRoles
            return userLeadershipRoles.some(userLeadershipRole =>
                selectedChatListTypeOrLeadershipUuid === userLeadershipRole.uuid
            );
        }
    };

    useEffect(() => {
        setIsAllowedToShowCreateChatButtons(isCreateChatButtonShowable(selectedChatListTypeOrLeadershipUuid));
    }, [selectedChatListTypeOrLeadershipUuid]);

    return (
        <>
            <Text color="#4B5155" size="1.125rem" weight="700" margin="12rem 0 0 0" >No Active Chats</Text>
            <StartChatButton icon={SingleChatIcon} text="Start 1-on-1 Chat" 
                iconWidth="1.5rem" iconHeight="1.3rem !important"
                iconMargin="0 7px"
                margin="10rem 0 0.5rem 0"
                isAllowed={isAllowedToShowCreateChatButtons}
                onClick={() => showCreateChatModalOrShowLeadershipEmployees(ONE_ON_ONE_CHAT, selectedChatListTypeOrLeadershipUuid)}
            />
            <StartChatButton icon={GroupChatIcon} text="Start Group Chat" 
                iconWidth="1.5rem" iconHeight="1.3rem !important"
                iconMargin="0 7px"
                isAllowed={isChatActionAllowed(CREATE_GROUP_CHAT, permissions) && isAllowedToShowCreateChatButtons}
                onClick={() => showCreateChatModalOrShowLeadershipEmployees(GROUP_CHAT, selectedChatListTypeOrLeadershipUuid)}
            />
        </>
    );
};

export default NoActiveChats;