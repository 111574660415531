import React, { useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios"; 
import {
    Text, Form, PageHeadingLabel,
    PageContainer, Toast
} from "src/components";

import { CancelButton, SaveButton, StyledButton, FormBackground,
    FormSectionHeader, LoadingIndicatorButton, LoadingIndicatorIcon
} from "../components/Utils";
import { EnumSurveyShowFrequency, EnumSurveyShownIn, ErrorList,
    FormStep, EnumUserGroup
} from "../components/Enums";

import { SurveyNameField } from "./components/SurveysComponents/SurveyNameField";
import { UserGroupField } from "./components/SurveysComponents/UserGroupFIeld";
import { SurveyQuestionsFields } from "./components/SurveysComponents/SurveyQuestionsFields";
import { SurveySubmissionAnonymousField } from "./components/SurveysComponents/SurveySubmissionAnonymousField";
import { SurveyFrequencyField } from "./components/SurveysComponents/SurveyFrequencyField";
import { SurveyDurationFields } from "./components/SurveysComponents/SurveyDurationFields";
import { TypeOfSurveyField } from "./components/SurveysComponents/TypeOfSurveyField";

const SurveyForm = (props) => {
    const { user, surveyToEdit, showSurveyForm, getSurveys, selectedSurveyOrPollStatusType } = props;
    const [survey, setSurvey] = useState({
        name: "",
        surveyType: "",
        startDate: "",
        finishDate: "",
        userGroup: "",
        surveyQuestions: {
            one: { uuid: "", question: "" },
            two: {},
            three: {}
        },
        showIn: EnumSurveyShownIn.QUOTE_SCREEN,
        showFrequency: EnumSurveyShowFrequency.EVERYTIME,
        isAnonymous: false,
        status: ""
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [formStep, setFormStep] = useState(FormStep.PAGE_SURVEY_NAME);
    const [setInputTypeAs] = useState("text");
    const [activeQuestionCount, setActiveQuestionCount] = useState(1);
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const [isEditSurveyForm, setIsEditSurveyForm] = useState(false);
    const [showPollVisibilityModal, setShowPollVisibilityModal] = useState(false);
    const [allEmployeesInSurvey, setAllEmployeesInSurvey] = useState(false);
    const [allClientsInSurvey, setAllClientsInSurvey] = useState(false);
    const [employeesCountInPoll, setEmployeesCountInPoll] = useState(0);
    const [clientsCountInPoll, setClientsCountInPoll] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isShowInitialUserGroupAddButton, setIsShowInitialUserGroupAddButton] = useState(true);

    useEffect(() => {
        if (surveyToEdit) {
            setSurveyToEdit(surveyToEdit);
            setIsShowInitialUserGroupAddButton(false);
            setIsEditSurveyForm(true);
        } else {
            setIsEditSurveyForm(false);
        }
    }, [surveyToEdit]);

    const setSurveyToEdit = (surveyToEdit) => {
        setSurvey({
            name: surveyToEdit.name || "",
            surveyType: surveyToEdit.surveyType || "",
            startDate: surveyToEdit.startDate || "",
            finishDate: surveyToEdit.finishDate || "",
            userGroup: surveyToEdit.userGroup || "",
            surveyQuestions: {
                one: surveyToEdit.surveyQuestions[0] || { uuid: "", question: "" },
                two: surveyToEdit.surveyQuestions[1] || { uuid: "", question: "" },
                three: surveyToEdit.surveyQuestions[2] || { uuid: "", question: "" },
            },
            showIn: surveyToEdit.showIn || EnumSurveyShownIn.QUOTE_SCREEN,
            showFrequency: surveyToEdit.showFrequency || EnumSurveyShowFrequency.EVERYTIME,
            isAnonymous: surveyToEdit.isAnonymous || false,
            status: surveyToEdit.status || "",
            clientCount: surveyToEdit.clientCount,
            employeeCount: surveyToEdit.employeeCount,
            totalEmployeeCount: surveyToEdit.totalEmployeeCount,
            totalClientCount: surveyToEdit.totalClientCount,
            usersSelectedUuid: surveyToEdit.usersSelectedUuid
        });
    };

    const updateSurvey = (key, value) => {
        setSurvey(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const inputSurveyName = (event) => {
        updateSurvey("name", event.target.value);
    };

    const inputStartDate = (selectedDate) => {
        updateSurvey("startDate", selectedDate);
    };

    const inputFinishDate = (selectedDate) => {
        updateSurvey("finishDate", selectedDate);
    };

    const selectSurveyType = (event) => {
        updateSurvey("surveyType", event.value);
    };

    const inputQuestion = (questionNumber, event) => {
        const questionKey = ["", "one", "two", "three"][questionNumber];
        const questionString = event.target.value;
        setSurvey(prev => ({
            ...prev,
            surveyQuestions: {
                ...prev.surveyQuestions,
                [questionKey]: { uuid: "", question: questionString, questionOrder: questionNumber }
            }
        }));
    };

    const selectUserGroup = (event) => {
        updateSurvey("userGroup", event.value);
        setInputErrors([]); // reset errors
    };

    const removeQuestion = (questionKey) => {
        setSurvey(prev => ({
            ...prev,
            surveyQuestions: {
                ...prev.surveyQuestions,
                [questionKey]: {}
            }
        }));
        setActiveQuestionCount(prev => prev - 1);
    };

    const addQuestion = () => {
        setActiveQuestionCount(prev => prev + 1);
    };

    const selectShowIn = (event) => {
        updateSurvey("showIn", event.value);
    };

    const setSurveyAnonymous = (event) => {
        updateSurvey("isAnonymous", event);
    };

    const selectShowFrequency = (event) => {
        updateSurvey("showFrequency", event.value);
    };
    
    const validateSurvey = (survey) => {
        const errors = [];
    
        if (!survey.name) {
            errors.push(ErrorList.EMPTY_SURVEY_NAME);
        }
        if (!survey.surveyType) {
            errors.push(ErrorList.EMPTY_SURVEY_TYPE);
        }
        if (!survey.userGroup) {
            errors.push(ErrorList.EMPTY_USER_GROUP);
        }
        if (survey.startDate && survey.finishDate && survey.startDate > survey.finishDate) {
            errors.push(ErrorList.DATE_RANGE_INVALID);
        }
        if (!survey.startDate) {
            errors.push(ErrorList.EMPTY_START_DATE);
        } else {
            if (isNaN(new Date(survey.startDate))) {
                errors.push(ErrorList.DATE_START_DATE_INVALID);
            }
        }
        if (!survey.finishDate) {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        } else {
            if (isNaN(new Date(survey.finishDate))) {
                errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
            }
        }
        if (survey.userGroup === EnumUserGroup.SELECTED_USERS && selectedUsers.length === 0) {
            errors.push(ErrorList.EMPTY_SELECTED_USERS);
        }

        if (survey.surveyQuestions.one.question === "") {
            errors.push(ErrorList.EMPTY_QUESTION_ONE);
        }

        if (Object.keys(survey.surveyQuestions.two).length === 0 && activeQuestionCount >= 2) {
            errors.push(ErrorList.EMPTY_QUESTION_TWO);
        }

        if (Object.keys(survey.surveyQuestions.three).length === 0 && activeQuestionCount === 3) {
            errors.push(ErrorList.EMPTY_QUESTION_THREE);
        }
    
        return errors;
    };
    
    const createFormSubmission = () => {
        setFormSubmitLoading(true);
    
        const errors = validateSurvey(survey);
        if (errors.length > 0) {
            setInputErrors(errors);
            setFormSubmitLoading(false);
            return;
        }
    
        // Format dates for the request
        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = formatter.format(new Date(survey.startDate)) + " 00:00:00";
        const formattedFinishDate = survey.finishDate ? formatter.format(new Date(survey.finishDate)) + " 23:59:59" : null;
    
        const surveyQuestions = Object.fromEntries(
            Object.entries(survey.surveyQuestions)
                .filter(([_, question]) => question.question)
        );
        let bodyObject = {};
        if (survey.userGroup === EnumUserGroup.SELECTED_USERS) {
            bodyObject = {
                ...survey,
                startDate: formattedStartDate,
                finishDate: formattedFinishDate,
                surveyQuestions,
                selectedUsers: selectedUsers
            };
        } else {
            bodyObject = {
                ...survey,
                startDate: formattedStartDate,
                finishDate: formattedFinishDate,
                surveyQuestions,
            };
        }
    
        // Axios request
        axios.post(`${API_URL}/survey/company`, bodyObject, {
            headers: { Authorization: `Bearer ${user.token}` }
        }).then(() => {
            setFormStep(FormStep.PAGE_CONGRATULATIONS);
        }).catch(error => {
            const serverErrors = [];
            error.fields?.forEach(fieldKey => {
                switch (fieldKey) {
                    case "startDateInvalid":
                        serverErrors.push(ErrorList.DATE_START_DATE_INVALID);
                        break;
                    case "finishDateInvalid":
                        serverErrors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                        break;
                    case "startDateGreaterThanFinishDate":
                        serverErrors.push(ErrorList.DATE_RANGE_INVALID);
                        break;
                    case "name":
                        serverErrors.push(ErrorList.EMPTY_SURVEY_NAME);
                        break;
                    case "surveyType":
                        serverErrors.push(ErrorList.EMPTY_SURVEY_TYPE);
                        break;
                    case "userGroup":
                        serverErrors.push(ErrorList.EMPTY_USER_GROUP);
                        break;
                    case "startDateEmpty":
                        serverErrors.push(ErrorList.EMPTY_START_DATE);
                        break;
                    case "finishDateEmpty":
                        serverErrors.push(ErrorList.EMPTY_FINISH_DATE);
                        break;
                    default:
                        break;
                }
            });
            setInputErrors(serverErrors);
        }).finally(() => setFormSubmitLoading(false));
    };

    const updateFormSubmission = (surveyToEdit) => {
        setFormSubmitLoading(true);
        let errors = validateSurvey(survey);
        
        if (errors.length > 0) {
            setFormSubmitLoading(false);
            setInputErrors(errors);
            return Toast.error("Please make sure your entries are correct");
        }

        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = survey.startDate ? formatter.format(new Date(survey.startDate)) : "";
        const formattedFinishDate = survey.finishDate ? formatter.format(new Date(survey.finishDate)) : "";

        const surveyQuestions = Object.fromEntries(
            Object.entries(survey.surveyQuestions)
                .filter(([_, question]) => question.question)
        );
        let formatedPayload = {
            name: survey.name,
            surveyType: survey.surveyType,
            startDate: survey.startDate ? formattedStartDate + " 00:00:00" : null,
            finishDate: survey.finishDate ? formattedFinishDate + " 00:00:00" : null,
            userGroup: survey.userGroup,
            surveyQuestions: surveyQuestions,
            showIn: survey.showIn,
            showFrequency: survey.showFrequency,
            isAnonymous: survey.isAnonymous
        };

        if (survey.userGroup === EnumUserGroup.SELECTED_USERS) {
            formatedPayload = {
                ...formatedPayload,
                selectedUsers: selectedUsers
            };
        }

        axios.put(`${API_URL}/survey/company/${surveyToEdit.uuid}`, formatedPayload,
            {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            }).then(res => {
            const surveyResponse = res.data.survey;
            setSurvey(surveyResponse);
        }).then(response => {
            getSurveys(selectedSurveyOrPollStatusType);
            showSurveyForm(false);
            return Toast.info("Survey updated successfully");
        }).catch(error => {
            if (!error.response) {
                return Toast.error(error.message);
            }

            let errors = [];
            let dataErrorFields = error.response.data.error.fields;
            
            dataErrorFields.forEach(function(fieldKey) {
                switch (fieldKey) {
                case "startDateInvalid":
                    errors.push(ErrorList.DATE_START_DATE_INVALID);
                    return;
   
                case "finishDateInvalid":
                    errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
                    return;

                case "startDateGreaterThanFinishDate":
                    errors.push(ErrorList.DATE_RANGE_INVALID);
                    return;

                case "surveyQuestionEmpty":
                    errors.push(ErrorList.EMPTY_QUESTION_ONE);
                    return;
                        
                default:
                    //NO ERROR TO CATCH
                    return;
                }
            });

            setInputErrors(errors);

            return Toast.error("Please make sure your entries are correct");
        }).finally(() => {
            setFormSubmitLoading(false);
        });
    };

    const togglePollVisibilityModal = () => {
        setShowPollVisibilityModal(!showPollVisibilityModal);
    };

    const handleSaveSelectedUsers = (
        allEmployeesSelected,
        allClientsSelected,
        selectedUsers,
        employeesCountSelected,
        clientsCountSelected) => {
        setAllEmployeesInSurvey(allEmployeesSelected);
        setEmployeesCountInPoll(employeesCountSelected);

        setAllClientsInSurvey(allClientsSelected);
        setClientsCountInPoll(clientsCountSelected);

        if (allEmployeesSelected && allClientsSelected) {
            updateSurvey("userGroup", EnumUserGroup.ALL_USERS);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (allEmployeesSelected && (!allClientsSelected && clientsCountSelected === 0)) {
            updateSurvey("userGroup", EnumUserGroup.EMPLOYEES);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (allClientsSelected && (!allEmployeesSelected && employeesCountSelected === 0)) {
            updateSurvey("userGroup", EnumUserGroup.CLIENTS);
            setSelectedUsers([]);
            setIsShowInitialUserGroupAddButton(false);
        }
        if (!allEmployeesSelected && !allClientsSelected) {
            if (selectedUsers.length > 0) {
                setSelectedUsers(selectedUsers);
                updateSurvey("userGroup", EnumUserGroup.SELECTED_USERS);
                setIsShowInitialUserGroupAddButton(false);
            } else {
                setSelectedUsers([]);
                setIsShowInitialUserGroupAddButton(true);
            }
        } 

        if ((allEmployeesSelected && clientsCountSelected !== 0) || (allClientsSelected && employeesCountSelected !== 0)) {
            setSelectedUsers(selectedUsers);
            updateSurvey("userGroup", EnumUserGroup.SELECTED_USERS);
            setIsShowInitialUserGroupAddButton(false);
        }
        setShowPollVisibilityModal(false);
    };

    if (formStep === FormStep.PAGE_CONGRATULATIONS) {
        return (
            <PageContainer>
                <PageCongratulations showSurveyForm={showSurveyForm} getSurveys={getSurveys} selectedSurveyOrPollStatusType={selectedSurveyOrPollStatusType} />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageHeadingLabel size="2.5rem">
                { isEditSurveyForm ? "Edit Survey" : "Create New Survey"}
            </PageHeadingLabel>
            <div className="row" style={{ maxWidth: "94.5rem" }}>
                <div className="description" style={{ marginBottom: "1em" }}>
                    Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                    create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                    employees, clients, or both, ensuring you reach the right audience every time.
                </div>
            </div>

            <FormBackground>
                <Form width="inherit" onSubmit={(e) => { e.preventDefault(); }} style={{ padding: "2.5rem 0" }} >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                        <div style={{ width: "45%" }}>
                            <FormSectionHeader style={{ fontWeight: "600" }}>
                                Survey Information
                            </FormSectionHeader>
                            <SurveyNameField
                                survey={survey}
                                inputSurveyName={inputSurveyName}
                                inputErrors={inputErrors}
                                loading={false}
                            />
                            <TypeOfSurveyField 
                                showSurveyForm={true}
                                survey={survey}
                                selectSurveyType={selectSurveyType}
                                inputErrors={inputErrors}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={false}
                            />
                            <SurveyDurationFields
                                showSurveyForm={showSurveyForm}
                                survey={survey}
                                inputStartDate={inputStartDate}
                                inputFinishDate={inputFinishDate}
                                inputErrors={inputErrors}
                                setInputTypeAs={setInputTypeAs}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={false}
                            />
                            <UserGroupField
                                showSurveyForm={true}
                                survey={survey}
                                selectUserGroup={selectUserGroup}
                                inputErrors={inputErrors}
                                isEditSurveyForm={isEditSurveyForm}
                                loading={false}
                                togglePollVisibilityModal={togglePollVisibilityModal}
                                showPollVisibilityModal={showPollVisibilityModal}
                                handleSaveSelectedUsers={handleSaveSelectedUsers}
                                selectedUsers={selectedUsers}
                                clientsInPoll={allClientsInSurvey}
                                employeesInPoll={allEmployeesInSurvey}
                                employeesCountInPoll={employeesCountInPoll}
                                clientsCountInPoll={clientsCountInPoll}
                                isEditForm={isEditSurveyForm}
                                isShowInitialUserGroupAddButton={isShowInitialUserGroupAddButton}
                            />
                            <SurveyFrequencyField
                                showSurveyForm={true}
                                survey={survey}
                                inputErrors={inputErrors}
                                selectShowIn={selectShowIn}
                                selectShowFrequency={selectShowFrequency}
                                loading={false}
                            />
                        </div>
                        <div style={{ width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div>
                                <FormSectionHeader style={{ fontWeight: "600" }}>
                                    Survey Questions
                                </FormSectionHeader>
                                <SurveyQuestionsFields
                                    survey={survey}
                                    inputQuestion={inputQuestion}
                                    activeQuestionCount={activeQuestionCount}
                                    addQuestion={addQuestion}
                                    removeQuestion={removeQuestion}
                                    inputErrors={inputErrors}
                                    isEditSurveyForm={isEditSurveyForm}
                                    loading={false}
                                />
                            </div>
                            <div style={{ margin: "0 0 2.5rem 0" }}>
                                <SurveySubmissionAnonymousField
                                    setSurveyAnonymous={setSurveyAnonymous}
                                    surveyIsAnonymous={survey.isAnonymous}
                                    surveyStatus={survey.status}
                                    isEditSurveyForm={isEditSurveyForm}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "50px 3.75rem 22px 3.75rem" }}>
                        <hr/>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                        {
                            formSubmitLoading ?
                                <LoadingIndicatorButton height="38px">
                                    <LoadingIndicatorIcon height="20px" width="20px" margin="5px"/>
                                </LoadingIndicatorButton>
                                :
                                <>
                                    <CancelButton borderRadius="7px" onClick={(e) => { showSurveyForm(false); }}>Cancel</CancelButton>
                                    <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => { isEditSurveyForm ? updateFormSubmission(surveyToEdit) : createFormSubmission(); }}>Save</SaveButton>
                                </>
                        }
                    </div>
                </Form>
            </FormBackground>
        </PageContainer>
    );
};


const PageCongratulations = (props) => {
    const hideFormAndReloadList = () => {
        const { showSurveyForm, getSurveys, selectedSurveyOrPollStatusType } = props;

        showSurveyForm(false);
        getSurveys(selectedSurveyOrPollStatusType);
    };

    return (
        <>
            <PageHeadingLabel size="2.5rem">Create New Survey</PageHeadingLabel>
            <div className="row" style={{ maxWidth: "94.5rem" }}>
                <div className="description" style={{ marginBottom: "1em" }}>
                    Choose from four tailored survey types – Satisfaction, Rating, Yes/No, or Written Feedback. With the ability to <br/>
                    create up to three survey questions, you can target the feedback you truly desire. Direct your survey towards <br/>
                    employees, clients, or both, ensuring you reach the right audience every time.
                </div>
            </div>

            <FormBackground style={{ margin: "0 0 3rem 0",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                maxHeight: "60rem",
                flexGrow: "1",
            }}>
                <div style={{ margin: "auto" }}>
                    <Text color="#000000" size="40px" style={{ marginBlockEnd: "0px" }}>
                        Congratulations!
                    </Text>
                    <Text color="#000000" size="40px" style={{ marginBlockStart: "0px" }}>
                        Your Survey Has Been Created.
                    </Text>
                    <Text color="black" size="16px" width="650px" lineHeight="25px" margin="0">
                        You can always make edits to your scheduled and active surveys via the <br/>
                        &rsquo;Surveys&rsquo; main screen and view insights once your survey has gone live.
                    </Text>

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
                        <StyledButton backgroundColor="#FFF" color="#000" border="1px solid #000" style={{ width: "100px", height: "40px", marginTop: "65px" }}
                            onClick={() => { hideFormAndReloadList(); }} >
                            Close
                        </StyledButton>
                    </div>
                </div>
            </FormBackground>
        </>
    );
};

export default SurveyForm;