import axios from "axios";
import { useState, useContext } from "react";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";

/**
 * Custom hook for deleting a chat
 * @returns {{
 *      isDeletingChat: boolean
 *      deleteChat: Function
 *      showConfirmationModal: boolean
 *      setShowConfirmationModal: Function
 * }}
 */
export const useDeleteChat = (setShowEditGroupChatModal) => {
    const currentUser = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };

    const [matrixChatToDelete, setMatrixChatToDelete] = useState(null);
    const [isDeletingChat, setIsDeletingChat] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const deleteChat = async (currentChatUuid, headers) => {
        setIsDeletingChat(true);
        try {
            const url = `${API_URL}/chats/${currentChatUuid}`;
            return await axios.delete(url, headers);
        } catch (error) {
            const message = typeof(error) === "string" ? error : "Cannot delete chat.";
            Toast.error(message); 
            throw error;
        } finally {
            setShowConfirmationModal(false);
            setIsDeletingChat(false);
            setShowEditGroupChatModal(false);
        }
    };

    /** partnered with self::handleShowDeleteConfirmationModal() */
    const handleChatDeletion = async () => {
        try {
            await matrixChatToDelete.deleteMatrixRoom(() => deleteChatBeta(matrixChatToDelete.uuid, headers));
        } catch (error) {
            //403 - Forbidden, "Only the chat owner is allowed to do that" 
            Toast.error(error);
        } finally {
            setShowConfirmationModal(false);
            setMatrixChatToDelete(null);
        }
    };

    const deleteChatBeta = async (currentChatUuid, headers) => {
        setIsDeletingChat(true);
        try {
            const url = `${API_URL}/chats/${currentChatUuid}`;
            return await axios.delete(url, headers);
        } catch (error) {
            console.warn("Request for Chat deletion generated an error: ", error);
            throw error;
        } finally {
            setIsDeletingChat(false);
        }
    };

    const handleShowDeleteConfirmationModal = (matrixChat) => {
        setMatrixChatToDelete(matrixChat);
        setShowConfirmationModal(true);
    };

    return {
        isDeletingChat,
        deleteChat,
        showConfirmationModal,
        setShowConfirmationModal,
        handleChatDeletion,
        handleShowDeleteConfirmationModal
    };
};