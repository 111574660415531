import React, { useContext } from "react";
import styled from "styled-components";
import { Button, FlexContainer } from "src/components";
import DigitalCompanyCardContext from "src/scenes/DigitalMemberCard/DigitalCompanyCardContext";

const CustomizeButton = styled(Button)`
    padding: 8px 0 !important;
    border-radius: 10px !important;
    background-color: ${p => p.backgroundColor || "#FFF" };
    color: ${p => p.color || "#000" };
    font-size: 0.875rem;
    text-align: center;
    border: 2px solid #000000;
    width: 112px;
`;

const CustomiseUserTypeCard = ({ isClient }) => {
    
    const pageLabel = !isClient ? "Customise Your Employee Card" : "Customise Your Client Card";

    const { 
        renderDisplayCards
    } = useContext(DigitalCompanyCardContext);

    return (
        <>
            {pageLabel}
            <FlexContainer mDirection="row" tDirection="row" direction="row" width="100%" justifyContent="center" gap="10px">
                <CustomizeButton onClick={renderDisplayCards}>Cancel</CustomizeButton>
                <CustomizeButton color="#fff" backgroundColor="#000" onClick={() => alert("Saving of Employee Card not yet working.") }>Save</CustomizeButton>
            </FlexContainer>
        </>
        
    );
}; 

export default CustomiseUserTypeCard;