import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
    FlexContainer, Button, CardHeadingLabel, ExplainParagraphLabel
} from "src/components";

import { SettingContext } from "../SettingContext";
import { Password, Email, AccessCode } from "../components/ChangeEmailFields";

import ChangeEmailForm from "../components/ChangeEmailForm";
import ChangePasswordForm from "../components/ChangePasswordForm";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";

const EditButton = styled(Button)`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px !important;
    width: 135px;
    height: 2.4rem;
    margin: 5px 0;
    padding: 0 !important;
    margin-right: 1em;
    margin-top: 3em;
`;

const ContainerGrid = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    column-gap: 6%;
`;

function Security() {
    const userContext = useContext(UserContext);
    const { isAccountOwner } = useContext(SettingContext);
    const [showUserDetailsFormModal, setShowUserDetailsFormModal] = useState(false);
    const [showChangePasswordFormModal, setShowChangePasswordFormModal] = useState(false);
    
    const [securityData, setSecurityData] = useState({
        password: "********",
        email: userContext.email,
        accessCode: userContext.accessCode,
    });

    const setEmail = (param) => {
        setSecurityData({ ...securityData, email: param });

        userContext.appFns.updateUserContextProperties({ [UserKeys.EMAIL]: param });
    };

    return (
        <>
            <FlexContainer>
                <CardHeadingLabel>
                    Security
                </CardHeadingLabel>
                <ExplainParagraphLabel>
                    View and update your account details, profile and more.
                </ExplainParagraphLabel>
                
                <ContainerGrid>
                    <Email placeholderDisabled={true} isEditable={false} formData={securityData} inputErrors={[]}/>
                    <EditButton onClick={() => setShowUserDetailsFormModal(true)}>Change</EditButton>
                    
                    <Password placeholderDisabled={true} isEditable={false} formData={securityData} inputErrors={[]} />
                    <EditButton onClick={() => setShowChangePasswordFormModal(true)}>Change</EditButton>
                    
                    {userContext.accessCode && <AccessCode placeholderDisabled={true} isEditable={false} formData={securityData} inputErrors={[]} />}
                </ContainerGrid>
            </FlexContainer>
            {showUserDetailsFormModal && <>
                <ChangeEmailForm
                    setEmail={setEmail}
                    showDialog={showUserDetailsFormModal}
                    handleClose={setShowUserDetailsFormModal}
                    token={userContext.token}
                    isAccountOwner={isAccountOwner}
                />
            </>}

            {showChangePasswordFormModal && <>
                <ChangePasswordForm
                    showDialog={showChangePasswordFormModal}
                    handleClose={setShowChangePasswordFormModal}
                    token={userContext.token}
                    isAccountOwner={isAccountOwner}
                />
            </>}
                
        </>
    );
}

export default Security;