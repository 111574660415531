import axios from "axios";
import { useState } from "react";
import { API_URL } from "src/scenes/App";
import { IsFeatureReady } from "src/constants/features";

export const BACKUP_KEY_FORMS = {
    CREATE: "CREATE",
    RESET: "RESET",
    RESTORE: "RESTORE"
};

export const BACKUP_STORAGE_KEYS = {
    BACKUP_MODAL_ACTIONED: "createBackupPasswordRefused",
    USER_KEY_RESTORED: "userKeyRestored"
};

export const useBackupForm = (matrixClient, headers) => {

    const [backupKeyChecksAreComplete, setBackupKeyChecksAreComplete] = useState(false);
    const [showBackupKeyForm, setShowBackupKeyForm] = useState(false);
    const [backupFormToShow, setBackupFormToShow] = useState("");
    const [userHasBackupKeys, setUserHasBackupKeys] = useState(false);

    const handleShowBackupKeyModal = () => {
        const form = userHasBackupKeys ? BACKUP_KEY_FORMS.RESTORE : BACKUP_KEY_FORMS.CREATE;
        setBackupFormToShow(form);
        setShowBackupKeyForm(true);
    };

    const closeBackupKeyForm = () => {
        localStorage.setItem(BACKUP_STORAGE_KEYS.BACKUP_MODAL_ACTIONED, "1");
        setShowBackupKeyForm(false);
    };

    const checkIfUserAlreadyRefusedToCreateBackupPassword = () => {
        return localStorage.getItem(BACKUP_STORAGE_KEYS.BACKUP_MODAL_ACTIONED);
    };

    const clientHasCryptoEnabled = () => {
        return matrixClient && !!matrixClient.getCrypto();
    };

    /**
     * Check if the user has an existing backup version
     * @returns {Promise<boolean | null>}
     */
    const checkIfUserHasExistingBackupKeys = async () => {
        const url = `${API_URL}/_matrix/client/v3/room_keys/version`;
        try {
            const response = await axios.get(url, headers);
            return !!response.data.auth_data;
        } catch (error) {
            // these means that logged in user is most likely a new user that is yet to create back up keys
            setUserHasBackupKeys(false);
            setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
            return undefined;
        }
    };

    const runBackupKeyRequirementChecks = async () => {

        const cryptoIsEnabled = clientHasCryptoEnabled();
        const userPreviouslyDismissedPrompt = checkIfUserAlreadyRefusedToCreateBackupPassword();
        if (!cryptoIsEnabled || userPreviouslyDismissedPrompt || !IsFeatureReady.chatEncryption()) {
            setBackupKeyChecksAreComplete(true);
            return;
        }

        const backupKeysExist = await checkIfUserHasExistingBackupKeys();
        if (backupKeysExist === true) {
            setUserHasBackupKeys(true);
            const localStorageHasRestoredUserKey = localStorage.getItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED);
            if (!localStorageHasRestoredUserKey) {
                setShowBackupKeyForm(true);
                setBackupFormToShow(BACKUP_KEY_FORMS.RESTORE);
            }
        } else if (backupKeysExist === false) {
            setShowBackupKeyForm(true);
            setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
        }

        setBackupKeyChecksAreComplete(true);
    };

    return {
        handleShowBackupKeyModal,
        closeBackupKeyForm,
        runBackupKeyRequirementChecks,
        setUserHasBackupKeys,
        setBackupFormToShow,
        backupKeyChecksAreComplete,
        showBackupKeyForm,
        backupFormToShow
    };
};