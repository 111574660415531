import React, { useContext } from "react";
import { FlexContainer, LottieAnimation, Text, Toast } from "src/components";
import { ConfirmButton, BackButton, Container, Content, TextLabel, StyledInput } from "./styled";
import RestoreBackupAnimation from "./assets/restore.json";

import { usePasswordValidator } from "src/scenes/Chat/hooks";
import { BackupKeyContext } from "../../BackupKeyContext";
import { BACKUP_KEY_FORMS } from "src/scenes/Chat/hooks/chat/useBackupForm";

const Restore = (props) => {
    const { password, setPassword } = usePasswordValidator();
    const { closeBackupKeyForm, updateBackupKeyFormToShow } = useContext(BackupKeyContext);

    const handleSubmit = () => {
        if (password.trim() === "") {
            return Toast.error("Password cannot be empty.");
        }

        props.handleRestoration(password);
    };

    const handleForgotPassword = () => {
        updateBackupKeyFormToShow(BACKUP_KEY_FORMS.RESET);
    };

    return (
        <Container>
            <Content>
                <FlexContainer justifyContent="center" alignItems="center">
                    <LottieAnimation animationJsonData={RestoreBackupAnimation} />
                    <Text size="1.25rem" weight="700">Restore Your Chat History</Text>
                    <Text size="1rem" margin="0" style={{ maxWidth: "34.5rem" }}>
                        You are using a new device. Would you like to restore your chat history?<br />
                        Please enter your chat backup password. 
                        If you’ve forgotten your password, you can generate a new one to secure future chats.
                    </Text>
                    <Text size="1rem" weight="700" margin="16px 0 50px 0">
                        Note: Previous chat history will be lost if creating a new password.
                    </Text>
                    <TextLabel>Enter Chat Backup Password</TextLabel>
                    <StyledInput placeholder="Enter your password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Text size="1rem" weight="700" margin="39px 0 0 0"
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={handleForgotPassword}
                    >
                        Forgot Password
                    </Text>
                </FlexContainer>
            </Content>
            <FlexContainer margin="21px 0 0 0" padding="0 0 55px 0"
                direction="row" tDirection="row" mDirection="row"
                justifyContent="flex-end"
            >
                <BackButton onClick={closeBackupKeyForm}>
                    Back
                </BackButton>
                <ConfirmButton onClick={handleSubmit} disabled={props.disableButtons}>
                    Confirm
                </ConfirmButton>
            </FlexContainer>
        </Container>
    );
};

export default Restore;