import React, { useState } from "react";
import moment from "moment";
// temporarily commented out due to functions using is WIP
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import {
    Button, PageContainer, mobile, tablet, desktop,
    LottieLoadingIndicator, ConfirmModal, 
    Toast } from "src/components";

//assets
import ArrowDown from "src/img/employeeList/SortUpArrow.svg";
import ArrowUp from "src/img/employeeList/SortDownArrow.svg";


import Checkbox from "./Checkbox";
import { SurveyStatusType, SortableColumnState, SortablePollColumns } from "src/constants/survey";
import SurveyAndPollsHeading from "./SurveyAndPollsHeading";
import PollResultsModal from "./PollResultsModal";

const SurveyButton = styled(Button)`
    padding: 10px 40px 10px;
    border-radius: 7px !important;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;
`;

const Table = styled.table`
    width: 100%;
    max-width: 1512px;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    border-bottom: none;
`;

const LoadingContainer = styled.table`
    width: 100%;
    height: 100%;
    max-width: 1512px;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    margin: 0 0 2.5rem 0;
`;

const Th = styled.th`    
    padding: 20px 10px;
    font-size: 13px;
    font-weight: 500;
    width: ${p => p.width || "5rem"};
    border-left: 0px solid #FFFFFF;
`;

const Td = styled.td`
    text-align: ${p => p.textAlign || "left"};
    font-size: 14px;
    padding: ${p => p.padding || "0px"};
    border: 0px solid #8291B2;
    white-space: nowrap;
`;

const ColumnHeader = (({ heading, sortDirection, styleProps }) => {
    const Container = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        text-align: center;
        user-select: none;
        cursor: pointer;
    `;

    const SortIndicator = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 8px;
    `;

    return (
        <Container style={{ ...styleProps }}>
            {heading}
            <SortIndicator hidden={sortDirection === SortableColumnState.INACTIVE}>
                {(sortDirection === SortableColumnState.DESCENDING) &&
                    <img width="10px" src={ArrowDown} alt="SortArrow" />}
                {(sortDirection === SortableColumnState.ASCENDING) &&
                    <img width="10px" src={ArrowUp} alt="SortArrow" />}
            </SortIndicator>
        </Container>
    );
});

const ViewButton = styled(Button)`
    min-width: 100px;
    width: 100px;
    padding: 8px 36px;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    border-color: #006CFF;
    background-color: #EAF3FF;
    cursor: pointer;
`;

const EditButton = styled(Button)`
    min-width: 100px;
    width: ${p => p.width || "100%"};
    padding: 8px 36px;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    cursor: pointer;
`;

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 1512px;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    justify-content: ${ p => p.justifyContent || "space-between"};
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        padding: 0;
        flex-direction: row;
    `};
`;

const InputSelector = styled.select`
    background: #51315D;
    border:0;
    text-decoration: none;
    color: white;
    padding: auto 23px;
`;


const PollsTable = (props) => {
    const {
        sortColumn,
        pollsArray,
        showPollForm,
        fetchPoll,
        fetching,
        setSurveyStatusType,
        selectedSurveyOrPollStatusType,
        handleChangeTableType,
        selectedTableType,
        sortableColumns,
        showEditPollForm,
        user,
        setFetchingStatus
    } = props;
    const [selectedPolls, setSelectedPolls] = useState([]);
    const [selectAllPolls, setSelectAllPolls] = useState(false);
    const [showPollResults, setShowPollResults] = useState(false);
    const [viewPollUuid, setViewPollUuid] = useState("");

    /**
     * @param {number} length
     */

    const emitSortColumn = (string) => {
        string = string.replace(/ /g, "_").toUpperCase();
        return sortColumn(string);
    };

    const handleCheckbox = (e) => {
        const isChecked = e.target.checked;
        if (!isChecked) {
            setSelectedPolls([]);
        } else {
            let arr = [];
            pollsArray.map(survey => arr.push({
                uuid: survey.uuid,
                name: survey.name,
                surveyType: survey.surveyType,
                userGroup: survey.userGroup,
                startDate: survey.startDate,
                finishDate: survey.finishDate,
                status: survey.status
            }));
            setSelectedPolls(arr);
        }
        setSelectAllPolls(!selectAllPolls);
    };

    const handleChangeStatusFilter = (pollTypeStatus) => {
        setSurveyStatusType(pollTypeStatus);
        fetchPoll(pollTypeStatus);
    };

    const addRemovePollFromSelected = (event, survey) => {
        const isChecked = event.target.checked;

        const data = {
            uuid: survey.uuid,
            name: survey.name,
            surveyType: survey.surveyType,
            userGroup: survey.userGroup,
            startDate: survey.startDate,
            finishDate: survey.finishDate,
            status: survey.status
        };
        return isChecked ? setSelectedPolls([...selectedPolls, data])
            : setSelectedPolls(selectedPolls.filter((obj) => obj["uuid"] !== survey.uuid));
    };

    const checkIfUuidIsSelected = (uuid) => selectedPolls.some(obj => obj["uuid"] === uuid);

    const confirmDelete = (pollUuids) => {
        return ConfirmModal.open(
            "Permanently Remove Poll",
            <div>
                <p>Are you sure you want to remove this poll? All data from poll 
                    insights for this poll will be lost</p><br></br>
                <hr/>
            </div>,
            () => { deletePolls(pollUuids); },
            "Remove",
            false
        );
    };

    const hidePollResultsModal = () => {
        setShowPollResults(false);
        setViewPollUuid("");
    };

    const showPollResultsModal = (selectedPollUuid) => {
        setViewPollUuid(selectedPollUuid);
        setShowPollResults(true);
    };

    const deletePolls = (pollUuids) => {
        ConfirmModal.close();
        setFetchingStatus(true);
        const pollUuidsArray = pollUuids.map(item => item.uuid);
        axios.delete(`${API_URL}/polls/deletePolls`, {
            data: { pollUuids: pollUuidsArray },
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            Toast.info("Selected Polls deleted successfully");
            fetchPoll(selectedSurveyOrPollStatusType);
        }).catch(error => {
            if (error.response) {
                return Toast.error(error.response.data.error);
            } else {
                return Toast.error(error.message);
            }
        }).finally(res => {
            setSelectedPolls([]);
            setSelectAllPolls(false);
        });
    };

    //generate empty lines when survey/client list is less than 10 records
    const emptyList = () => {
        const emptyRows = 10 - pollsArray.length;

        if (emptyRows < 1)
            return;

        return Array.from({ length: emptyRows }, (_, i) => (
            <tr key={"empty_" + i}>
                <td className="change-color-on-select">&nbsp;</td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
                <td className="change-color-on-select"></td>
            </tr>
        ));
    };

    const pollList = (pollItems) => pollItems.map(poll => {
        return (
            <tr key={poll.uuid}
                className={checkIfUuidIsSelected(poll.uuid) ? "selected" : ""}
            >
                <td style={{ textAlign: "center" }}>
                    <label>
                        <Checkbox
                            readOnly
                            checked={checkIfUuidIsSelected(poll.uuid) || selectAllPolls}
                            value={poll.uuid}
                            onChange={(e) => addRemovePollFromSelected(e, poll)}
                        />
                    </label>
                </td>
                <Td padding="0px 20px 0px 20px" width={"12%"}>
                    {poll["question"]}
                </Td>
                <Td textAlign="center">
                    {poll["numberOfOptions"]}
                </Td>

                <Td>
                    <div style={{ marginBottom: "9px" }}>{poll["pollVisibility"]["allClients"]}</div>
                    <div>{poll["pollVisibility"]["allEmployees"]}</div>
                </Td>
                <Td>
                    {moment(poll["startDate"], "YYYY-MM-DD hh:mm:ss").format("D MMMM YYYY")}
                </Td>

                <Td>
                    {moment(poll["endDate"], "YYYY-MM-DD hh:mm:ss").format("D MMMM YYYY")}
                </Td>
                <Td textAlign="center" padding="15px">
                    { selectedSurveyOrPollStatusType === SurveyStatusType.COMPLETE ?
                        "-"
                        :
                        <EditButton onClick={() => showEditPollForm(poll.uuid) } width="100px" height="32px" className="survey-list-table-btn" backgroundColor="#FFFFFF"
                            color="#000" style={{ border: "1px solid #000" }}>
                            <span>Edit</span>
                        </EditButton>
                    }
                </Td>
                <Td textAlign="center" padding="15px">
                    {
                        (poll["showPollResults"] && poll["status"] !== "Scheduled") &&
                            <ViewButton onClick={() => showPollResultsModal(poll["uuid"]) } width="100px" height="32px" className="survey-list-table-btn" color="#006CFF" style={{ border: "1px solid #006CFF" }}>
                                <span>View</span>
                            </ViewButton>
                    }

                </Td>
                <Td textAlign="center">
                    {
                        poll["status"] === "Scheduled"
                            ?
                            <div style={{ fontWeight: "500", color: "#006CFF" }}>{poll["status"]}</div>
                            :
                            <div style={{ fontWeight: "500", color: "#77B500" }}>{poll["status"]}</div>
                    }
                </Td>

            </tr>
        );
    });

    return (
        <PageContainer>
            <PollResultsModal
                showPollResults={showPollResults}
                onToggle={hidePollResultsModal}
                pollUuid={viewPollUuid}
            />
            <SurveyAndPollsHeading 
                handleChangeTableType={handleChangeTableType}
                selectedTableType={selectedTableType}
            />
            <UpperControls justifyContent="space-between !important" >
                {/* hide for now as mentioned by John at Sept 09, 2024 */}
                {/* <SurveyButton marginLeft="2.5rem" backgroundColor="#000000" onClick={() => { showHowItWorks(true); }}>
                    <span>How Does It Work?</span>
                </SurveyButton> */}
                <div className="row" style={{ margin: "20px 0 20px 23px" }}>
                    <div className="field" style={{ display: "flex" }}>
                        <label style={{ marginTop: "auto", marginBottom: "auto" }}>Filter:</label>
                        <InputSelector style={{ height: "38px", width: "200px", paddingLeft: "23px", fontSize: "14px" }} value={selectedSurveyOrPollStatusType} onChange={(e) => handleChangeStatusFilter(e.target.value)} >
                            <option value={SurveyStatusType.ACTIVE} style={{ fontSize: "1rem" }}>
                                Active
                            </option>
                            <option value={SurveyStatusType.COMPLETE} style={{ fontSize: "1rem" }}>
                                Complete
                            </option>
                            <option value={SurveyStatusType.SCHEDULED} style={{ fontSize: "1rem" }}>
                                Scheduled
                            </option>
                        </InputSelector>
                    </div>
                </div>
                { selectedPolls.length > 0 && <SurveyButton marginRight="2.5rem" style={{ fontWeight: "700" }} backgroundColor="#FF7171" onClick={(e) => confirmDelete(selectedPolls)}>
                    <span>Delete Selected Polls</span>
                </SurveyButton>}
                <SurveyButton marginRight="2.5rem" backgroundColor="#006CFF" onClick={(e) => showPollForm(true)}>
                    <span>Create New Polls</span>
                </SurveyButton>
            </UpperControls>

            { fetching && 
                <LoadingContainer>
                    <LottieLoadingIndicator/>
                </LoadingContainer>
            }

            { !fetching &&
                <div className="scrollable-table">
                    <Table>
                        <thead>
                            <tr>
                                <Th width={"5%"}>
                                    {/* Select all, does not highlight all the selected rows for the time being */}
                                    <label>
                                        <Checkbox readOnly checked={selectAllPolls} onChange={(e) => handleCheckbox(e)} key="0" />
                                    </label>
                                </Th>
                                {/* column sorting does not show any icons at the moment, will add a fix to that later on */}
                                <Th width={"25%"} onClick={(e) => emitSortColumn("POLL_QUESTION")} >
                                    <ColumnHeader
                                        heading="Poll Question"
                                        sortDirection={sortableColumns[SortablePollColumns.POLL_QUESTION]}
                                        styleProps={{ justifyContent: "left" }}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("NUMBER_OF_OPTIONS")} >
                                    <ColumnHeader
                                        heading="Number of Options"
                                        sortDirection={sortableColumns[SortablePollColumns.NUMBER_OF_OPTIONS]}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("POLL_VISIBILITY")} >
                                    <ColumnHeader
                                        heading="Poll Visibility"
                                        sortDirection={sortableColumns[SortablePollColumns.POLL_VISIBILITY]}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("START_DATE")} >
                                    <ColumnHeader
                                        heading="Start Date"
                                        sortDirection={sortableColumns[SortablePollColumns.START_DATE]}
                                        styleProps={{ justifyContent: "left" }}
                                    />
                                </Th>

                                <Th width={"10%"} onClick={() => emitSortColumn("FINISH_DATE")} >
                                    <ColumnHeader
                                        heading="Finish Date"
                                        sortDirection={sortableColumns[SortablePollColumns.FINISH_DATE]}
                                        styleProps={{ justifyContent: "left" }}
                                    />
                                </Th>

                                <Th width={"10%"} >
                                    <ColumnHeader
                                        heading="Edit"
                                    />
                                </Th>
                                <Th width={"10%"} >
                                    <ColumnHeader
                                        heading="Poll Results"
                                    />
                                </Th>
                                <Th width={"10%"} onClick={() => emitSortColumn("STATUS")} >
                                    <ColumnHeader
                                        heading="Status"
                                        sortDirection={sortableColumns[SortablePollColumns.STATUS]}
                                    />
                                </Th>
                            </tr>
                        </thead>

                        <tbody>
                            {pollList(pollsArray)}

                            {emptyList()}
                        </tbody>
                    </Table>
                </div>
            }
        </PageContainer >
    );
};


export default PollsTable;