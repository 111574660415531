import { useState, useContext } from "react";
import axios from "axios";

import { API_URL } from "src/scenes/App";
import { Toast } from "src/components";
import { UserContext } from "src/scenes/App/UserContext";

export const useEditChat = (propagateUpdatedChat) => {

    const [showEditGroupChatModal, setShowEditGroupChatModal] = useState(false);
    const [isUpdatingChat, setIsUpdatingChat] = useState(false);

    const currentUser = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };

    const updateGroupChat = async (currentChatUuid, formData) => {
        setIsUpdatingChat(true);
        try {
            const url = `${API_URL}/chats/${currentChatUuid}`;
            const response = await axios.put(url, formData, headers);
            setShowEditGroupChatModal(false);
            propagateUpdatedChat(response.data.chat);
        } catch (error) {
            Toast.error(error || "Unable to update chat.");
        } finally {
            setIsUpdatingChat(false);
        }
    };

    return {
        showEditGroupChatModal,
        setShowEditGroupChatModal,
        updateGroupChat,
        isUpdatingChat
    };
};
