import React, { useContext, useState } from "react";
import { Dialog } from "@material-ui/core/";
import { Header } from "../CreateChatModal";

import { FlexContainer, Toast } from "src/components";
import Create from "./Create";
import Restore from "./Restore";
import Reset from "./Reset";
import { BACKUP_KEY_FORMS, BACKUP_STORAGE_KEYS } from "src/scenes/Chat/hooks/chat/useBackupForm";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { BackupKeyContext } from "src/scenes/Chat/BackupKeyContext";
import { MatrixError } from "matrix-js-sdk";

const BackupKeyModal = (props) => {
    const { showDialog } = props;
    const { matrixClient } = useContext(ChatContext);
    const { backupFormToShow, closeBackupKeyForm, setUserHasBackupKeys } = useContext(BackupKeyContext);
    const [isProcessingBackup, setIsProcessingBackup] = useState(false);

    const handleBackupKeyPasswordCreation = async (password) => {
        setIsProcessingBackup(true);
        try {
            const prepared = await matrixClient.prepareKeyBackupVersion(password);
            await matrixClient.createKeyBackupVersion(prepared);
            localStorage.removeItem(BACKUP_STORAGE_KEYS.CREATE_BACKUP_REFUSED);

            setUserHasBackupKeys(true);
            closeBackupKeyForm();
        } catch (error) {
            Toast.error("Failed to create a back up password.");
        } finally {
            setIsProcessingBackup(false);
        }
    };

    const handleRestoration = async (password) => {
        setIsProcessingBackup(true);
        try {
            const backupMetadata = await matrixClient.getKeyBackupVersion();
            await matrixClient.restoreKeyBackupWithPassword(password, undefined, undefined, backupMetadata);
            localStorage.setItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED, true);
            window.location.reload();
        } catch (error) {
            const matrixError = new MatrixError(error);
            const message = matrixError.errcode === "RESTORE_BACKUP_ERROR_BAD_KEY" ?
                "Incorrect back up key password provided." : "Cannot restore chat history.";
            Toast.error(message);
            if (localStorage.getItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED)) {
                localStorage.setItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED, 0);
            }
        } finally {
            setIsProcessingBackup(false);
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open={showDialog}
                onClose={closeBackupKeyForm}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "95%",
                        maxWidth: "59.375rem",
                        height: "839px",
                        borderRadius: "20px",
                        overflow: "hidden",
                        background: "#F9FAFC",
                    },
                }}
            >
                <Header>Me Business Chat</Header>
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    marginTop="35px"
                >
                    { backupFormToShow === BACKUP_KEY_FORMS.CREATE && (
                        <Create
                            disableButtons={isProcessingBackup}
                            handleBackupKeyPasswordCreation={handleBackupKeyPasswordCreation}
                        />
                    )}
                    { backupFormToShow === BACKUP_KEY_FORMS.RESTORE && (
                        <Restore
                            disableButtons={isProcessingBackup}
                            handleRestoration={handleRestoration}
                        />
                    )}
                    { backupFormToShow === BACKUP_KEY_FORMS.RESET && (
                        <Reset
                            disableButtons={isProcessingBackup}
                            handleBackupKeyPasswordCreation={handleBackupKeyPasswordCreation}
                        />
                    )}
                </FlexContainer>

            </Dialog>
        </React.Fragment>
    );
};

export default BackupKeyModal;